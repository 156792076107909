import {
  CLEAR_STRUCTURE_SELECTED_PARAMS,
  TOGGLE_STRUCTURE_SELECTED_PARAM,
} from 'store/constants/compareParams'

export const toggleStructureSelectedParam = (name, category_title, header) => {
  return {
    type: TOGGLE_STRUCTURE_SELECTED_PARAM,
    name,
    category_title,
    header,
  }
}

export const clearStructureSelectedParams = () => {
  return {
    type: CLEAR_STRUCTURE_SELECTED_PARAMS,
  }
}
