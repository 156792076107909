import Icon from 'components/Icon'

import { CustomInputLabel, InputLabelContainer } from './index.style'

const CustomInputLabelBlock = ({
  id,
  type,
  label,
  error,
  onFocus,
  warning,
  labelDesc,
  isValidated,
  isValidating,
  showTooltip,
  needTooltip,
  setShowTooltip,
  withPasswordTooltip,
}) => {
  const getTooltipId = () => {
    if (labelDesc && !(warning || error)) return `${type}-${id}-info`
    if (withPasswordTooltip) return 'password-req-tooltip'
    if (error) return `${type}-${id}-error`
    return `${type}-${id}-warning`
  }

  const tooltipId = getTooltipId()

  return (
    <InputLabelContainer
      data-tooltip-id={tooltipId}
      onMouseEnter={() => needTooltip && !showTooltip && setShowTooltip(true)}
      onMouseLeave={() => showTooltip && setShowTooltip(false)}
    >
      <>
        <CustomInputLabel htmlFor={id} onFocus={onFocus}>
          {label}
        </CustomInputLabel>
        {isValidating && (
          <Icon
            size="1rem"
            iconType="loader"
            className="icon-login-validating"
          />
        )}
        {(!!error || !!warning) && (
          <>
            <Icon
              size="1rem"
              iconType={warning ? 'warning2' : 'description'}
              className={warning ? 'icon-login-warning' : 'icon-login-error'}
            />
          </>
        )}
        {isValidated && (
          <Icon size="1rem" iconType="tick" className="icon-login-validated" />
        )}
        {labelDesc && !(error || warning) && (
          <Icon size="1rem" iconType="info" className="icon-info" />
        )}
      </>
    </InputLabelContainer>
  )
}

export default CustomInputLabelBlock
