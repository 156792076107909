import styled from 'styled-components'

export const StyledTable = styled.table`
  border-spacing: 0.75rem 0rem;

  td {
    min-width: 14.875rem;
    width: 14.875rem;
    padding: 0;
  }
`
export const CategoriesWrapper = styled.div`
  gap: ${({ isSelectedParamsTable }) => (isSelectedParamsTable ? 0 : '0.5rem')};
  display: flex;
  flex-direction: column;
`
