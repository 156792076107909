import styled, { css } from 'styled-components'

export const PropertyRow = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const PropertyValue = styled.div`
  display: ${({ name }) => (name === 'smiles' ? 'flex' : 'inline-block')};
  vertical-align: middle;
  align-items: center;
  text-align: right;
  max-width: 85%;

  & > svg:not(.loader-icon) {
    color: ${({ theme }) => theme.colors.icons.tertiary};
    transition: 0.2s;
    cursor: pointer;
    vertical-align: middle;
    min-width: 1rem;

    &:hover {
      color: #42c1bf;
    }
  }

  .loader-icon {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    color: ${({ theme }) => theme.colors.statuses.success};
  }

  & > span {
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media screen and (width < 1100px) {
    max-width: 75%;
  }

  @media print {
    & > svg {
      display: none;
    }
  }
`

export const PropertySource = styled.div`
  margin-left: 4px;
  display: inline-flex;
  padding: 6px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #12b76a;
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.12px;

  ${({ withTooltip }) =>
    withTooltip &&
    css`
      cursor: pointer;
      text-decoration-line: underline;
      &:hover {
        background: #a6f4c5;
      }
    `}
`
export const KeyValueProperty = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;

  & .property-name {
    white-space: wrap;
    max-width: 13.75rem;
  }

  & .property-value {
    width: 100%;
    position: relative;
    width: fit-content;
    display: flex;
    // align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media print {
    break-inside: avoid;
    visibility: visible;
    & .property-name {
      white-space: nowrap !important;
    }
  }
`
