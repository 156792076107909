export const ION_MODE_OPTIONS = [
  { value: 'positive', label: 'Positive' },
  { value: 'negative', label: 'Negative' },
]

export const ADDUCT_TYPE_OPTIONS = {
  positive: [{ value: '[M+H]+', label: '[M+H]+' }],
  negative: [{ value: '[M-H]-', label: '[M-H]-' }],
}

export const ENERGY_TYPES = [
  'isotopic_distribution',
  'low_energy',
  'medium_energy',
  'high_energy',
]

export const CALCULATED_RESULTS_OPTIONS = {
  isotopic_distribution: {
    title: 'isotopic_distribution',
    value: 'isotopic_distribution',
  },
  low_energy: { title: 'LE MsMs Spectrum (10eV)', value: 'low_energy' },
  medium_energy: { title: 'ME MsMs Spectrum (20eV)', value: 'medium_energy' },
  high_energy: { title: 'HE MsMs Spectrum (40eV)', value: 'high_energy' },
}

export const NMR_METHODS = [
  { label: '1H', value: 'hydrogen' },
  { label: '13C', value: 'carbon' },
  { label: '15N', value: 'nitrogen' },
  { label: '19F', value: 'flourine' },
]
export const ROUNDINGS = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
]
export const INFRARED_SHOOTING = [
  { value: 'gas', label: 'gas' },
  { value: 'liquid', label: 'liquid' },
  { value: 'CCl4', label: 'CCl4' },
  { value: 'KBr', label: 'KBr' },
  { value: 'nujolmull', label: 'nujolmull' },
]

export const MULTIPLETS = {
  1: 'S',
  2: 'D',
  3: 'T',
  4: 'Q',
}

export const TABLE_WIDTH = 288

export const SPECTRA_TYPES_SWITCH = [
  { title: 'spectra.buttons.nmr', type: 'nmr' },
  { title: 'spectra.buttons.ms', type: 'ms' },
  {
    title: 'spectra.buttons.infraredSpectrometry',
    type: 'infraredSpectrometry',
  },
]

export const SPECTRA_NMR_TABLE = 'nmr-table-data'
export const SPECTRA_NMR_CHART = 'nmr-spectra-chart'
export const SPECTRA_INFRARED_CHART = 'infrared-spectra-chart'
export const SPECTRA_STR_CANVAS_ID = 'spectra-str-canvas'
export const SPECTRA_MS_CHART_PREFIX = 'bar-spectra-chart-container-'
