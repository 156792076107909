import styled from 'styled-components'
import {
  DEFAULT_MOLECULE_HEIGHT,
  STICKY_MOLECULE_HEIGHT,
} from '../StructuresList/config/config'

export const Container = styled.div`
  align-self: flex-start;

  border-radius: 0.75rem;

  padding: ${({ isSticky }) => (isSticky ? '0.75rem' : 0)};

  background-color: ${({ theme, isSticky }) =>
    isSticky && theme.colors.backgrounds.secondary};
`

export const Molecule = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;
  transition: all 0.3s;
  background-color: ${({ theme, isSticky }) =>
    isSticky && theme.colors.backgrounds.secondary};
`

export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const SmilesViewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${({ isSticky }) => !isSticky && 'center'};
  align-items: center;

  .canvas-molecule-structure {
    height: ${({ isSticky }) =>
      isSticky
        ? `${STICKY_MOLECULE_HEIGHT}px`
        : `${DEFAULT_MOLECULE_HEIGHT}px`};
  }
`
export const TlightWrapper = styled.div`
  margin-left: 1rem;
  margin-bottom: 1rem;
`
export const InfoRow = styled.div`
  display: flex;
  gap: 0.75rem;
  justify-content: space-between;
  align-items: center;
`

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '0.875rem'};
`
