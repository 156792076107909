import styled from 'styled-components'
import Icon from 'components/Icon'

export const PlusIcon = styled(Icon)`
  margin-left: ${({ marginleft }) => marginleft ?? '0'};
  min-width: 16px;
`

export const FilterLitConditionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : '0.5rem')};
  border-radius: 0.75rem;
  padding: 0.25rem;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
`
export const FilterLitConditionHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 0.375rem 0 0.375rem 0.75rem;
  align-items: center;
  justify-content: space-between;
`
export const FilterLitConditionText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const FilterLitConditionBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: ${({ isOpened }) => (isOpened ? '100%' : '0px')};
  overflow: ${({ isOpened }) => (isOpened ? 'unset' : 'hidden')};
  min-height: ${({ isOpened }) => (isOpened ? '2.5rem' : '0px')};
  opacity: ${({ isOpened }) => (isOpened ? '1' : '0')};
`

export const FilterCondition = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  border-radius: 0.75rem;
  // margin-top: 0.25rem;
`

export const FilterLitConditionBtns = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.25rem;
`

export const ConditionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  & > svg {
    color: ${({ theme }) => theme.colors.icons.tertiary};
  }
`

export const Condition = styled.div`
  display: flex;
  align-items: center;
  padding: 0.375rem 0.5rem;
  border-radius: 0.375rem;
  background ${({ theme }) => theme.colors.backgrounds.secondary};
  height: 1.5rem;
  min-width: 7.75rem;
  max-width: 12.75rem;
  width: fit-content;
  user-select: none;
`

export const ConditionWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`

export const StyledTrashButton = styled.button`
  color: ${({ theme }) => theme.colors.icons.tertiary};
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  &:hover {
    color: ${({ theme }) => theme.colors.statuses.error};
  }
`
