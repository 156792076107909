import {
  SET_LIT_DIALOG_CONFIG,
  SET_LIT_FILTER_CONFIG,
  SET_OPEN_LIT_DIALOG,
  SET_OPEN_LIT_FILTER,
  SET_LIT_FILTER_DOC_TYPE,
  SET_PREV_SEARCH_DATA_ID,
  SET_LITERATURE_LIST_VIEW,
  SET_LIT_PAGE_OPEN,
  SET_LIT_SYNTELLY_ID,
  SET_SHOULD_BLOCKS_INDEXES,
  UPDATE_LAST_SEARCH_VALUES,
} from 'store/constants/literature'

export const setOpenLitFilter = (isOpen) => {
  return {
    type: SET_OPEN_LIT_FILTER,
    isOpen,
  }
}

export const setLitFilterConfig = (config) => {
  return {
    type: SET_LIT_FILTER_CONFIG,
    config,
  }
}

export const setLitFilterDocType = (docType) => {
  return { type: SET_LIT_FILTER_DOC_TYPE, docType }
}

export const setOpenLitDialog = (isOpen) => {
  return {
    type: SET_OPEN_LIT_DIALOG,
    isOpen,
  }
}

export const setLitDialogConfig = (config) => {
  return {
    type: SET_LIT_DIALOG_CONFIG,
    config,
  }
}

export const setPrevSearchDataId = (bool) => {
  return {
    type: SET_PREV_SEARCH_DATA_ID,
    prevSearchDataId: bool,
  }
}

export const setLiteratureListView = (value) => {
  return {
    type: SET_LITERATURE_LIST_VIEW,
    value,
  }
}

export const setLitPageOpen = (open, config) => {
  return {
    type: SET_LIT_PAGE_OPEN,
    open,
    config,
  }
}

export const setLiteratureSyntellyId = (id) => {
  return {
    type: SET_LIT_SYNTELLY_ID,
    syntellyId: id,
  }
}

export const setShouldBlocksIndexes = (arr) => {
  return {
    type: SET_SHOULD_BLOCKS_INDEXES,
    data: arr,
  }
}

export const updateLastSearchValues = (text, prevId = null) => {
  return {
    type: UPDATE_LAST_SEARCH_VALUES,
    text,
    prevId,
  }
}
