import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  CategorySort,
  CategorySortItem,
} from 'components/Molecule/category/index.style'
import CustomScrollbar from 'components/CustomScrollbar'
import { toxySortConfig } from 'components/Molecule/category/config'
import { toggleStructureSelectedParam } from 'store/actions/compareParams'

import {
  EXTRA_HEIGHT,
  LARGE_CELL_HEIGHT,
  DEFAULT_CELL_HEIGHT,
  DRUG_LIKENESS_HEIGHTS,
} from '../config'
import { Divider } from './index.style'
import PropertiesTable from '../PropertiesTable'
import HeadersTypeTable from '../HeadersTypeTable'

const StructuresCompareCategory = memo(
  ({
    inner,
    isSticky,
    scrollLeft,
    calculation,
    category_name,
    selectedParams,
    category_title,
    isFirstCategory,
    blurNonApplicable,
    isSelectedParamsTable,
  }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const scrollRef = useRef(null)

    const [toxySortType, setToxySortType] = useState('all')

    const innerFiltered = useMemo(() => {
      if (category_name === 'toxy') {
        if (toxySortType === 'all') return inner
        return inner.filter((el) =>
          toxySortType === 'lethal_dose_models'
            ? el.name.includes('LD50') || el.name.includes('LDLo')
            : !el.name.includes('LD50') && !el.name.includes('LDLo')
        )
      }

      return inner
    }, [category_name, inner, toxySortType])

    useEffect(() => {
      scrollRef?.current?.scrollTo(scrollLeft, 0)
    }, [scrollLeft])

    const propertiesHeight = useMemo(() => {
      return innerFiltered.reduce(
        (height, { name, category_title: inner_category_title }) => {
          const isExpOrApplicable = calculation.some(
            ({ sources, applicability: applicabilityData }) =>
              sources[name]?.type === 'exp' ||
              applicabilityData?.[inner_category_title]
          )
          return (
            height +
            (isExpOrApplicable ? LARGE_CELL_HEIGHT : DEFAULT_CELL_HEIGHT)
          )
        },
        0
      )
    }, [innerFiltered, calculation])

    const height = useMemo(() => {
      if (category_title === 'Drug-likeness') {
        const arr = isSelectedParamsTable
          ? DRUG_LIKENESS_HEIGHTS.filter((el) =>
              selectedParams?.some((s) => s?.header === el?.name)
            )
          : DRUG_LIKENESS_HEIGHTS

        return `${arr.reduce((acc, el) => acc + el.height, 0)}rem`
      }

      return `${
        propertiesHeight + (innerFiltered.length - 1) * EXTRA_HEIGHT
      }rem`
    }, [
      innerFiltered,
      selectedParams,
      category_title,
      propertiesHeight,
      isSelectedParamsTable,
    ])

    const handleToggleParam = useCallback(
      (name, category_title, header) => {
        dispatch(toggleStructureSelectedParam(name, category_title, header))
      },
      [dispatch]
    )

    const handleChangeSortType = (value) => setToxySortType(value)

    return (
      <div>
        {!isFirstCategory && isSelectedParamsTable && (
          <Divider withRightPadding />
        )}

        {category_name === 'toxy' && !isSelectedParamsTable && (
          <CategorySort
            isExpanded={true}
            className="hidden-for-print structures-compare-category-sort"
          >
            {toxySortConfig.map(({ name, value }, key) => {
              return (
                <CategorySortItem
                  active={toxySortType === value}
                  key={key}
                  isFullMode={true}
                  onClick={() => handleChangeSortType(value)}
                >
                  {t(name)}
                </CategorySortItem>
              )
            })}
          </CategorySort>
        )}

        <CustomScrollbar
          className="hidden-scroll-x"
          noScrollX={true}
          noScrollY
          style={{
            height,
            width: '100%',
          }}
          innerRef={scrollRef}
        >
          <div>
            {innerFiltered.map(
              (
                {
                  name,
                  type,
                  category_id,
                  category_title: inner_category_title,
                },
                i,
                arr
              ) => {
                const isSelected = selectedParams.some((el) => el.name === name)
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    key={`${category_id}-${i}`}
                  >
                    <div>
                      {type === 'headers' ? (
                        <HeadersTypeTable
                          {...{
                            name,
                            isSticky,
                            calculation,
                            selectedParams,
                            category_title,
                            isSelectedParamsTable,
                          }}
                          onToggle={handleToggleParam}
                        />
                      ) : (
                        <PropertiesTable
                          {...{
                            name,
                            isSticky,
                            isSelected,
                            calculation,
                            category_id,
                            category_title,
                            selectedParams,
                            blurNonApplicable,
                            inner_category_title,
                            isSelectedParamsTable,
                          }}
                          onToggle={handleToggleParam}
                          isNotLast={i !== arr.length - 1}
                        />
                      )}
                    </div>
                  </div>
                )
              }
            )}{' '}
          </div>
        </CustomScrollbar>
      </div>
    )
  }
)

StructuresCompareCategory.displayName = 'StructuresCompareCategory'

export default StructuresCompareCategory
