import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactResizeDetector from 'react-resize-detector'
import React, { memo, useRef, useMemo, useEffect, useCallback } from 'react'
import ReactFlow, {
  addEdge,
  useReactFlow,
  useNodesState,
  useEdgesState,
  getNodesBounds,
  ReactFlowProvider,
  getViewportForBounds,
} from 'reactflow'
import 'reactflow/dist/style.css'
import debounce from 'lodash.debounce'

import {
  toggleMoleculeDialog,
  toggleAddMoleculeDialog,
} from 'store/actions/retrosynthesis'
import { addMolecule } from 'store/actions/crud'
import MoleculeDialog from 'components/Molecule/dialog'
import BasketsListModal from 'components/BasketsListModal'

import {
  NODE_WIDTH,
  NODE_HEIGHT,
  IMAGE_WIDTH,
  IMAGE_HEIGHT,
} from './config/config'
import { SchemeContainer } from './index.style'
import CustomNode from './components/CustomNode'
import { downloadImage, generateNodesAndEdges } from './utils/utils'
import { domToPng } from 'modern-screenshot'

const Flow = ({
  tree,
  smilesToAdd,
  addMolecule,
  selectedStage,
  isDownloadClicked,
  fastViewMoleculeId,
  toggleMoleculeDialog,
  setIsDownloadClicked,
  toggleAddMoleculeDialog,
}) => {
  const { t } = useTranslation()
  const nodeTypes = useMemo(
    () => ({
      custom: CustomNode,
    }),
    []
  )
  const SCALE_FACTOR = 8
  const reactFlowInstance = useReactFlow()

  const ref = useRef(null)

  const calculate = (width, height) => {
    if (width && height) {
      const { nodes: initialNodes, edges: initialEdges } =
        generateNodesAndEdges(
          tree,
          width,
          height,
          NODE_WIDTH,
          NODE_HEIGHT,
          reactFlowInstance.getZoom()
        )

      setNodes(initialNodes)
      setEdges(initialEdges)
    }
  }

  useEffect(() => {
    calculate(
      ref?.current?.getBoundingClientRect().width,
      ref?.current?.getBoundingClientRect().height
    )
    reactFlowInstance.setViewport({
      x: reactFlowInstance.getViewport().x,
      y: reactFlowInstance.getViewport().y,
      zoom: reactFlowInstance.getZoom(),
    })
    const fit = () => {
      reactFlowInstance.fitView({ padding: 0 })
    }

    const debounceFit = debounce(fit, 10)
    debounceFit()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStage])

  const onResize = () => {
    reactFlowInstance && reactFlowInstance.fitView({ padding: 0 })
  }

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds))
    },
    [setEdges]
  )

  const handleAddMoleculeToBasket = (ids, newBasketName) => {
    const smilesArray = smilesToAdd.map((smiles) => ({
      smiles,
    }))

    if (ids?.length) {
      ids.forEach((el) => addMolecule(el.id, smilesArray, false, el.name, t))
    } else {
      addMolecule(-1, smilesArray, false, newBasketName, t)
    }
  }

  const { fitView } = useReactFlow()

  const handleDownload = () => {
    fitView()

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    const reactFlowContainer = document.querySelector('.react-flow')
    const solutionTagElement = document.querySelector(
      '#retrosynth-solution-tag'
    )
    const scoreTagElement = document.querySelector('#retrosynth-score-tag')
    canvas.width = reactFlowContainer.offsetWidth * SCALE_FACTOR
    canvas.height = reactFlowContainer.offsetHeight * SCALE_FACTOR
    const renderElement = (element, x, y) => {
      return domToPng(element, {
        backgroundColor: 'white',
        scale: SCALE_FACTOR,
        style: {
          ...(element?.id === 'retrosynth-solution-tag' ||
          element?.id === 'retrosynth-score-tag'
            ? {
                padding: '0.375rem 0.5rem',
                background: '#F5F7F9',
                borderRadius: '0.375rem',
                display: 'flex',
                alignItems: 'center',
                width: 'max-content',
              }
            : {}),
        },
      }).then((dataUrl) => {
        return new Promise((resolve) => {
          const img = new Image()
          img.src = dataUrl
          img.onload = () => {
            context.drawImage(img, x, y)
            resolve()
          }
        })
      })
    }

    renderElement(reactFlowContainer, 0, 0)
      .then(() =>
        renderElement(scoreTagElement, 16 * SCALE_FACTOR, 16 * SCALE_FACTOR)
      )
      .then(() =>
        renderElement(solutionTagElement, 100 * SCALE_FACTOR, 16 * SCALE_FACTOR)
      )
      .then(() => {
        canvas.toBlob((blob) => {
          const dataUrl = URL.createObjectURL(blob)
          downloadImage(dataUrl)
          setIsDownloadClicked(false)
        })
      })
  }

  useEffect(() => {
    if (isDownloadClicked) handleDownload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadClicked])

  return (
    <ReactResizeDetector handleWidth handleHeight onResize={onResize}>
      <SchemeContainer ref={ref}>
        <ReactFlow
          key={tree}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView={true}
          maxZoom={1.2}
          minZoom={0.1}
          fitViewOptions={{ padding: 0 }}
        />
        {!!fastViewMoleculeId && (
          <MoleculeDialog
            onClose={() => toggleMoleculeDialog(null)}
            id={fastViewMoleculeId}
          />
        )}
        {!!smilesToAdd && (
          <BasketsListModal
            onAgree={handleAddMoleculeToBasket}
            onClose={() => toggleAddMoleculeDialog(null)}
            withPublic={false}
            withNew={true}
            actionText={t('molecule_viewer.add_to_dataset')}
            type="molecules"
            onlyOne
          />
        )}{' '}
      </SchemeContainer>
    </ReactResizeDetector>
  )
}

const RetrosynthTree = (props) => {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
    </ReactFlowProvider>
  )
}

const mapStateToProps = (state) => ({
  smilesToAdd: state.retrosynthesis.smilesToAdd,
  fastViewMoleculeId: state.retrosynthesis.fastViewMoleculeId,
})

const mapDispatchToProps = {
  addMolecule,
  toggleAddMoleculeDialog,
  toggleMoleculeDialog,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(RetrosynthTree))
