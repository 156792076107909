import React, { useRef, useState } from 'react'
import Icon from 'components/Icon'
import { CustomDialog } from '../../common/customDialog'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  MethodDialogHeader,
  MethodDialogContent,
  ReactionCardTitle,
  SwitchBtns,
  ReactionProtocol,
  DialogConditions,
} from '../index.style'
import { Headline, TitleSecondary } from 'components/common/text/index.style'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { setOpenReactionsDialog } from 'store/actions/reactionSearch'
import { useTheme } from 'styled-components'
import CustomButton from 'components/common/customButton'
import ReactionMethodInfo from '../components/ReactionMethodInfo'
import LiteratureSource from '../components/LiteratureSource'
import { ReactionSchema } from '../components/ReactionSchema'
import { ConditionsBlock } from '../components/ConditionsBlock'
import { CustomCloseIcon } from 'components/common/customCloseIcon'
import CustomScrollbar from 'components/CustomScrollbar'

const ReactionSearchDialog = ({ data }) => {
  const { id, conditions, products, reactants } = data || {}
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [methodIdx, setMethodIdx] = useState(0)
  const [scrolled, setScrolled] = useState(false)
  const scrollRef = useRef(null)

  const handleScroll = () => {
    const e = scrollRef?.current
    e && setScrolled(e.scrollTop > 0)
  }

  const switchBtnsConfig = [
    {
      iconType: 'arrowLeft',
      onClick: () => setMethodIdx(methodIdx - 1),
      disabled: methodIdx === 0,
    },
    {
      iconType: 'arrowRight',
      onClick: () => setMethodIdx(methodIdx + 1),
      disabled: methodIdx + 1 === conditions?.length,
    },
  ]

  return (
    <CustomDialog
      width={'59.5rem'}
      onClose={() => dispatch(setOpenReactionsDialog(false))}
      maxHeight={'38rem'}
      gap={'0'}
      overflowY={'hidden'}
      withPortal={true}
      padding={'0'}
      showCloseIcon={false}
    >
      <CustomScrollbar
        disableTrackYWidthCompensation={true}
        // innerRef={scrollRef}
        // onScroll={handleScroll}
        className="margin--12-right"
        noScrollX
      >
        <MethodDialogHeader /*withShadow={scrolled}*/ withShadow={false}>
          <ReactionMethodInfo
            yield_percents={conditions?.[methodIdx]?.yield_percents}
            idx={'method-dialog-source'}
            backgroundColor={theme.colors.buttons.secondary}
          />
          <ReactionCardTitle>
            <TitleSecondary>{`${t('filter.reaction.method')} ${
              methodIdx + 1
            }`}</TitleSecondary>
            <SwitchBtns>
              {switchBtnsConfig.map(({ iconType, onClick, disabled }, i) => (
                <CustomButton
                  key={i}
                  width="1.25rem"
                  height="1.25rem"
                  type="secondary"
                  defaultPadding="0.25rem"
                  onClick={onClick}
                  disabled={disabled}
                  borderRadius={'0.375rem'}
                >
                  <Icon iconType={iconType} size="0.75rem" />
                </CustomButton>
              ))}
            </SwitchBtns>
          </ReactionCardTitle>
          <LiteratureSource source={conditions?.[methodIdx]?.source} />
          <CustomCloseIcon
            onClick={() => dispatch(setOpenReactionsDialog(false))}
          />
        </MethodDialogHeader>
        <MethodDialogContent>
          <ReactionSchema reactants={reactants} products={products} />
          {conditions?.[methodIdx]?.protocol_text && (
            <ReactionProtocol fontWeight={theme.fontWeight.medium}>
              {conditions?.[methodIdx]?.protocol_text}
            </ReactionProtocol>
          )}
          <DialogConditions>
            <Headline fontWeight={theme.fontWeight.semibold}>
              {t('filter.reaction.reaction_conditions')}
            </Headline>
            <ConditionsBlock condition={conditions?.[methodIdx]} id={id} />
          </DialogConditions>
        </MethodDialogContent>
      </CustomScrollbar>
    </CustomDialog>
  )
}

const mapStateToProps = (state) => {
  return {
    data: state.reactionsSearch.dialog.config,
  }
}

export default connect(mapStateToProps)(ReactionSearchDialog)
