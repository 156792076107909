import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import './index.css'

import {
  Container,
  ExternalLink,
  Group,
  GroupedProperties,
  CategorySort,
  CategorySortItem,
  SynonymsHint,
  Wrapper,
  Synonyms,
  SynonymsTypeSelector,
  SynonymsList,
} from './index.style'
import {
  CategoryHeader,
  CategoryContent,
  KeyValueProperty,
} from './index.style'
import {
  Label,
  Subheadline,
  TextBody,
  TextMedium,
} from 'components/common/text/index.style'
import { getCategoryId } from '../config/config'
import Icon from 'components/Icon'
import '../../common/tooltip/style.css'

import { SYNONYMS_TYPES, toxySortConfig } from './config'

import { setBlurNonApplicable, togglePropsWindow } from 'store/actions/molecule'
import { css, useTheme } from 'styled-components'
import CustomButton from 'components/common/customButton'
import CustomScrollbar from 'components/CustomScrollbar'
import CustomBtnsScroller from 'components/common/customBtnsScroller'
import CustomTab from 'components/common/customTab'

import {
  getCategoryKey,
  getDataByCategoryName,
  HEADERS_EXCEPTIONS,
} from 'config/molecule/molecule'
import HeadersProperty from 'components/HeadersProperty'
import Property from 'components/Property'
import CustomWindow from 'components/CustomWindow'
import PersonalProperties from '../personalProperties'
import { isLink } from 'pages/SynthCost/utils'

export const Category = ({
  category_id,
  category_name,
  inner,
  data,
  sources,
  extended,
  setExtended,
  applicabilityData,
  isApplicabilityShow,
  isFullMode,
  isGeneratedMol,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isPersonalPropsCategory = category_id === 'personal_properties'
  const personalProps =
    useSelector((state) => state.molecule.personal_properties.data) ?? []
  const isEmptyPersonal = isPersonalPropsCategory && !personalProps?.length
  const isExpanded = useMemo(
    () =>
      isPersonalPropsCategory
        ? !isEmptyPersonal && extended?.includes(category_id)
        : extended.includes(category_id),
    [category_id, extended, isEmptyPersonal, isPersonalPropsCategory]
  )

  const [toxySortType, setToxySortType] = useState('all')
  const [synonymsType, setSynonymsType] = useState('names')
  const [customWindowVisible, setWindowVisible] = useState(false)
  const containerRef = useRef(null)

  const dispatch = useDispatch()

  const toggleIsExpanded = () => {
    if (isEmptyPersonal) return
    setExtended(category_id)
  }
  const blurNonApplicable = useSelector(
    (store) => store.molecule.categories.blurNonApplicable
  )
  const customWindowOpen = useSelector(
    (store) => store.molecule.personal_properties.show_properties_window
  )

  useEffect(() => {
    return () => dispatch(setBlurNonApplicable(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeSortType = (value) => setToxySortType(value)

  const togglePersonalPropsWindow = () => {
    setWindowVisible(!customWindowVisible)
  }
  useEffect(() => {
    setTimeout(() => dispatch(togglePropsWindow(customWindowVisible)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customWindowVisible])

  const innerFiltered = useMemo(() => {
    if (category_name === 'toxy') {
      if (toxySortType === 'all') return inner
      return inner.filter((el) =>
        toxySortType === 'lethal_dose_models'
          ? el.name.includes('LD50') || el.name.includes('LDLo')
          : !el.name.includes('LD50') && !el.name.includes('LDLo')
      )
    }

    return inner
  }, [category_name, inner, toxySortType])

  if (
    category_name === 'synonyms' &&
    (!data.synonyms ||
      (!data.synonyms?.databases_ids?.length && !data.synonyms?.names?.length))
  ) {
    return (
      <Container
        id={getCategoryId(category_id)}
        isFullMode={isFullMode}
        style={{ position: 'relative' }}
        padding={isEmptyPersonal ? '0.5rem 1rem' : '1rem'}
      >
        <CategoryHeader
          className="molecule-category-header"
          isExpanded={isExpanded}
          onClick={toggleIsExpanded}
          noHoverStyles={true}
          isFullMode={isFullMode}
        >
          <TextBody fontWeight={500}>
            {t(getCategoryKey(category_name))}
          </TextBody>
        </CategoryHeader>
        <Subheadline
          color={theme.colors.text.secondary}
          fontWeight={400}
          className={'property-name'}
          style={{ marginTop: '0.25rem' }}
        >
          {t('molviewer.no_synonyms')}
        </Subheadline>
        <SynonymsHint
          data-tooltip-html={t('molviewer.why_that_tip')}
          data-tooltip-id="no-synonyms-tooltip"
          isFullMode={isFullMode}
        >
          <p>{t('molviewer.why_that')}</p>
          <Icon iconType="info" />
          <ReactTooltip
            id="no-synonyms-tooltip"
            className="c-tooltip c-tooltip-base c-tooltip-category-syn c-tooltip-z-max"
            classNameArrow="c-tooltip-arrow"
            place="top-end"
            positionStrategy={'fixed'}
          />
        </SynonymsHint>
      </Container>
    )
  }

  return (
    <>
      <Wrapper className="molecule-category-wrapper">
        <CustomBtnsScroller
          innerRef={containerRef}
          extraTopStyles={css`
            border-radius: 0.75rem 0.75rem 0 0;
          `}
          extraBottomStyles={css`
            border-radius: 0 0 0.75rem 0.75rem;
            bottom: 0;
          `}
        >
          <Container
            id={getCategoryId(category_id)}
            isFullMode={isFullMode}
            ref={containerRef}
            className="molecule-category-container"
            padding={isEmptyPersonal ? '0.5rem 1rem' : '1rem'}
          >
            <CategoryHeader
              className="molecule-category-header"
              isExpanded={isExpanded}
              onClick={toggleIsExpanded}
              isFullMode={isFullMode}
              noHoverStyles={isEmptyPersonal}
            >
              <TextBody
                fontSize={'0.75rem'}
                lineHeight={'1rem'}
                fontWeight={500}
              >
                {t(getCategoryKey(category_name))}
              </TextBody>
              {isEmptyPersonal ? (
                <CustomButton
                  onClick={togglePersonalPropsWindow}
                  type={isFullMode ? 'secondary' : 'primary'}
                  bgColor={
                    isFullMode
                      ? theme.colors.backgrounds.secondary
                      : theme.colors.backgrounds.primary
                  }
                >
                  <Label>{t('moleculeslist.topblock.add')}</Label>
                </CustomButton>
              ) : (
                <CustomButton
                  type={isFullMode ? 'icon_secondary' : 'icon_primary'}
                  defaultPadding="0.25rem"
                  defaultColor={
                    isExpanded
                      ? theme.colors.icons.accentPrimary
                      : theme.colors.icons.tertiary
                  }
                  borderRadius="0.375rem"
                >
                  <Icon
                    iconType={isExpanded ? 'arrowTop' : 'arrowDown'}
                    size="0.75rem"
                  />
                </CustomButton>
              )}
            </CategoryHeader>
            {category_name === 'toxy' && isExpanded && (
              <CustomScrollbar
                noScrollY={true}
                noScrollX={false}
                style={{
                  height: '2.75rem',
                }}
                disableTrackXWidthCompensation={true}
                className="margin--12-bottom"
              >
                <CategorySort
                  isExpanded={isExpanded}
                  className="hidden-for-print"
                >
                  {toxySortConfig.map(({ name, value }, key) => {
                    return (
                      <CategorySortItem
                        active={toxySortType === value}
                        key={key}
                        isFullMode={isFullMode}
                        onClick={() => handleChangeSortType(value)}
                      >
                        {t(name)}
                      </CategorySortItem>
                    )
                  })}
                </CategorySort>{' '}
              </CustomScrollbar>
            )}
            <CategoryContent
              isExpanded={isExpanded}
              className="molecule-category-content"
            >
              {innerFiltered.map(
                ({ category_id, type, name, value, category_title }) => {
                  if (name === 'synonyms') {
                    return (
                      <Synonyms key={category_id}>
                        <SynonymsTypeSelector>
                          {SYNONYMS_TYPES.map((st) => (
                            <CustomTab
                              key={st}
                              onClick={() => setSynonymsType(st)}
                              type={isFullMode ? 'grey' : 'white'}
                              size={'small'}
                              isActive={synonymsType === st}
                            >
                              {t(`molviewer.synonyms_types.${st}`)}
                            </CustomTab>
                          ))}
                        </SynonymsTypeSelector>
                        {!!data[name]?.[synonymsType]?.length && (
                          <SynonymsList>
                            {data[name][synonymsType].map((synonym, i) => (
                              <TextMedium
                                key={i}
                                fontWeight={theme.fontWeight.medium}
                              >
                                {synonym}
                              </TextMedium>
                            ))}
                          </SynonymsList>
                        )}
                      </Synonyms>
                    )
                  }
                  if (category_name === 'personal_properties') {
                    const isHttpLink = isLink(value)
                    return (
                      <Fragment key={category_id}>
                        <KeyValueProperty
                          className="molecule-key-value-property"
                          key={name}
                        >
                          <Subheadline
                            color={theme.colors.text.secondary}
                            fontWeight={theme.fontWeight.medium}
                          >
                            {name}
                          </Subheadline>

                          {isHttpLink ? (
                            <ExternalLink
                              href={value}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Subheadline fontWeight={400}>
                                {value}
                              </Subheadline>
                              <Icon iconType={'link'} />
                            </ExternalLink>
                          ) : (
                            <Subheadline fontWeight={400}>{value}</Subheadline>
                          )}
                        </KeyValueProperty>
                      </Fragment>
                    )
                  }
                  if (name === 'external') {
                    return (
                      <Fragment key={category_id}>
                        {data[name].map(({ value, view, link }, i) => (
                          <KeyValueProperty
                            className="molecule-key-value-property"
                            key={i}
                          >
                            <Subheadline
                              color={theme.colors.text.secondary}
                              fontWeight={400}
                            >
                              {view}
                            </Subheadline>
                            <ExternalLink
                              href={link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Subheadline fontWeight={400}>
                                {value}
                              </Subheadline>
                              <Icon iconType={'link'} />
                            </ExternalLink>
                          </KeyValueProperty>
                        ))}
                      </Fragment>
                    )
                  }

                  if (type === 'headers') {
                    const headers = getDataByCategoryName(name, data).filter(
                      ({ value }) =>
                        value !== 'error' &&
                        !value?.find(
                          ({ type, text }) =>
                            type === 'header' &&
                            HEADERS_EXCEPTIONS.headers.includes(text)
                        )
                    )

                    if (headers?.length) {
                      return (
                        <GroupedProperties key={category_id}>
                          {headers.map(({ value }, i) => {
                            const header = value.find(
                              ({ type }) => type === 'header'
                            )
                            const lines = value.filter(
                              ({ type }) => type !== 'header'
                            )

                            return (
                              <Group key={i}>
                                {(header ? [header, ...lines] : lines).map(
                                  ({ type, text, units, value, color }, i) => (
                                    <HeadersProperty
                                      key={i}
                                      {...{ text, type, color, value, units }}
                                    />
                                  )
                                )}
                              </Group>
                            )
                          })}
                        </GroupedProperties>
                      )
                    }
                  }

                  if (!type) {
                    const value = getDataByCategoryName(
                      name,
                      data,
                      isGeneratedMol
                    )
                    return (
                      <Property
                        key={category_id}
                        {...{
                          data,
                          name,
                          value,
                          sources,
                          category_id,
                          isGeneratedMol,
                          category_title,
                          blurNonApplicable,
                          applicabilityData,
                          isApplicabilityShow,
                        }}
                      />
                    )
                  }

                  return null
                }
              )}
              {category_name === 'personal_properties' &&
                !!personalProps?.length && (
                  <CustomButton
                    onClick={togglePersonalPropsWindow}
                    type={isFullMode ? 'secondary' : 'primary'}
                    borderRadius="0.375rem"
                    className="edit-personal-props-btn"
                  >
                    <Label>{t('moleculeslist.topblock.edit')}</Label>
                  </CustomButton>
                )}
            </CategoryContent>
          </Container>
        </CustomBtnsScroller>
      </Wrapper>
      {customWindowVisible && (
        <CustomWindow
          window_id={`personal-props-window-${category_id}`}
          handleClose={togglePersonalPropsWindow}
          config={{
            width: '42.5rem',
            header: 'molviewer.personal_props_header',
            headerPadding: '0 0 1.5rem 0',
            zIndex: 2000,
          }}
          isOpen={customWindowOpen}
        >
          <PersonalProperties initData={personalProps} />
        </CustomWindow>
      )}
    </>
  )
}
