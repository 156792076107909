import html2canvas from 'html2canvas'
import { store, update } from '../../services/rest'
import jsPDF from 'jspdf'
import { removeFromStorage } from 'utils/storage/storage'
import sanitizeHtml from 'sanitize-html'

export const sdfToSmi = async (file) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  }

  let formData = new FormData()
  formData.append('file', file)

  try {
    const { data } = await store('/upload', formData, config)
    return data
  } catch (err) {
    console.log(err)
    return []
  }
}

export const copyToClipboard = (text) =>
  navigator.clipboard.writeText(text?.toString()?.trim())

export const getRandomColor = ({ withHash = false }) => {
  const letters = '0123456789ABCDEF'
  let color = withHash ? '#' : ''
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

export const validateEnglishInput = (inputText) => {
  const regexp = /^[a-zA-Z0-9\s!@#$%^&*()\\_+{}[\]:;"='<>,.?/~`|-]+$/
  return regexp.test(inputText)
}

export const validateNotStarSymbol = (inputText) => {
  const regexp = /^(?!.*\*).*$/
  return regexp.test(inputText)
}

export const validateEnglishCyrillicInput = (inputText) => {
  const regexp = /^[a-zA-Z0-9\s!@#$%^&*()\\_+{}[\]:;"='<>,.?/~`|-А-Яа-яЁё]+$/
  return regexp.test(inputText)
}

export const emailReg = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
export const validatePasswordLength = (password) => {
  const passwordLengthReg = new RegExp(/.{8,}/)

  return passwordLengthReg.test(password)
}

export const validatePasswordLetters = (password) =>
  /[a-z]/.test(password) && /[A-Z]/.test(password)

export const validatePasswordSymb = (password) =>
  /[0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)

export const validateDelimiterSymbols = (str) =>
  /^[^a-zA-Zа-яА-Я0-9]+$/.test(str)

export const validatePasswordTotal = (password, email) =>
  !!password &&
  (!validatePasswordLength(password) ||
    !validatePasswordLetters(password) ||
    !validatePasswordSymb(password) ||
    (!!email && password.includes(email)))

export const isValidSMILES = async (inputValue) => {
  let isValid = false
  try {
    const { status } = await store(`/validate_smiles`, { smiles: inputValue })
    if (status === 200) isValid = true
  } catch (e) {
    isValid = false
  }
  return isValid
}

export const generatePdf = async ({
  id,
  fileName = 'Syntelly',
  dataCanvas, //аттрибут для отрисовки <canvas> изображений внутри страницы
  classForPrint, //доп. класс, добавляемый при генерации PDF для изменения вида страницы
  shouldClone = false, //требуется ли клонировать генерируемый в PDF DOM элемент, используется,
  //если мы хотим сгенерировать страницу и классом поменять ее отображение перед генерацией,
  //клонирование позволит избежать визуальной перерисовки для пользователя и сделает это
  //"под капотом" с клоном генерируемой страницы
  shouldScale = false, //true улучшает качество картинки, иногда убирает/добавляет косяки отображения
}) => {
  if (!id) return
  try {
    const page = document.getElementById(id)

    if (!page) return
    const clonedPage = page.cloneNode(true)

    const pageToUse = shouldClone ? clonedPage : page

    if (dataCanvas && shouldClone) {
      //cloneNode метод не поддерживает копирование содержимого <canvas>, поэтому приходится копировать вручную картинки
      //собираем все <canvas> элементы с data-canvas аттрибутом в клоне и вставляем оригинальное содержимое в них
      const originalMoleculeCanvases = document.querySelectorAll(
        `[data-canvas="${dataCanvas}"]`
      )
      const clonedMoleculeCanvases = clonedPage.querySelectorAll(
        `[data-canvas="${dataCanvas}"]`
      )
      if (originalMoleculeCanvases?.length && clonedMoleculeCanvases?.length) {
        clonedMoleculeCanvases.forEach((canvas, index) => {
          canvas
            ?.getContext('2d')
            ?.drawImage(originalMoleculeCanvases[index], 0, 0)
        })
      }
    }
    classForPrint && pageToUse.classList.add(classForPrint) //изменение стилей страницы при генерации PDF

    shouldClone && document.body.appendChild(clonedPage)

    html2canvas(pageToUse, {
      logging: true, //логирование генерации canvas в консоль
      letterRendering: 1,
      // allowTaint: true,
      // useCORS: true,
      // removeContainer: false,
      scale: shouldScale ? 2 : 1,
    }).then((canvas) => {
      const doc = new jsPDF('p', 'mm', 'a4')
      const imgWidth = 208
      const pageHeight = 295
      const imgHeight = (canvas.height * imgWidth) / canvas.width

      let heightLeft = imgHeight
      let position = 0
      heightLeft -= pageHeight
      const imgData = canvas.toDataURL('image/png', 1) //0.6- качество генерации, влияет на размер файла
      doc.addImage(
        imgData,
        'PNG',
        0,
        position,
        imgWidth,
        imgHeight,
        '',
        'MEDIUM' //качество сжатия, влияет на размер файла
      )
      while (heightLeft > 0) {
        position = heightLeft - imgHeight
        doc.addPage()
        doc.addImage(
          imgData,
          'PNG',
          0,
          position,
          imgWidth,
          imgHeight,
          '',
          'MEDIUM'
        )
        heightLeft -= pageHeight
      }
      doc.save(`${fileName}.pdf`)
      shouldClone && document.body.removeChild(clonedPage)
    })
  } catch (error) {
    console.error('Error generating PDF: ', error)
  }
}

export const isMoleculesPath = (path) => {
  return /^\/molecules\/.*/.test(path)
}
export const isMoleculePath = (path) => {
  return /^\/molecule\/.*/.test(path)
}

export const handleDeleteKeys = (keys) => {
  keys.map((key) => removeFromStorage(key))
}

export const handleDownloadPNG = async ({ smiles, saveHandler }) => {
  try {
    const mol = window.RDKit?.get_mol(smiles || 'invalid')
    const canvas = document.createElement('canvas')
    canvas.width = 1000
    canvas.height = 1000
    mol?.draw_to_canvas(canvas, -1, -1)
    const pngDataUrl = canvas.toDataURL('image/png').split(';base64,')[1]
    const url = `data:image/png;base64,${pngDataUrl}`

    fetch(url)
      .then((b) => b?.blob())
      .then((blob) => saveHandler(blob, 'syntelly.png'))
  } catch (e) {
    console.log(e)
  }
}

export const updateLanguage = async (language) => {
  try {
    await update('/user/language', {
      language,
    })
  } catch (e) {
    console.log(e)
  }
}

export const cleanHtml = (dirtyHtml) => {
  return sanitizeHtml(dirtyHtml, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'div', 'span'],
    allowedAttributes: {
      a: ['href'],
      '*': ['class', 'style'],
    },
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
  })
}
