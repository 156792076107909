import { useTheme } from 'styled-components'

import { Caption } from 'components/common/text/index.style'
import RadioCheckbox from 'components/common/customRadioCheckbox'

import { Container } from './index.style'

const CheckboxWithTitle = ({
  id,
  value,
  title,
  onChange,
  children,
  withCustomTitle,
  withCustomLink = true,
}) => {
  const theme = useTheme()
  return (
    <Container withCustomLink={withCustomLink}>
      <RadioCheckbox
        checkbox
        value={value}
        handler={onChange}
        id={id || 'checkbox-toggle'}
      />
      {withCustomTitle ? (
        children
      ) : (
        <Caption
          fontWeight={theme.fontWeight.medium}
          color={theme.colors.text.secondary}
        >
          {title}
        </Caption>
      )}
    </Container>
  )
}

export default CheckboxWithTitle
