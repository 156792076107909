import {
  CLEAR_STRUCTURE_SELECTED_PARAMS,
  TOGGLE_STRUCTURE_SELECTED_PARAM,
} from 'store/constants/compareParams'

export const initialState = {
  structuresParams: [],
}

const compareParamsReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case TOGGLE_STRUCTURE_SELECTED_PARAM: {
      return {
        ...state,
        structuresParams: state.structuresParams.some(
          (el) => el.name === payload.name
        )
          ? state.structuresParams.filter(({ name }) => name !== payload.name)
          : [
              ...state.structuresParams,
              {
                name: payload.name,
                category_title: payload.category_title,
                header: payload.header,
              },
            ],
      }
    }

    case CLEAR_STRUCTURE_SELECTED_PARAMS: {
      return {
        ...state,
        structuresParams: [],
      }
    }

    default:
      return state
  }
}

export default compareParamsReducer
