export const MMAP_CLEAR = 'MMAP_CLEAR'
export const MMAP_ADD = 'MMAP_ADD'
export const MMAP_ADD_FAIL = 'MMAP_ADD_FAIL'

export const MMAP_LOAD_INIT = 'MMAP_LOAD_INIT'
export const MMAP_LOAD_SUCCESS = 'MMAP_LOAD_SUCCESS'
export const MMAP_ADD_OPTIONS = 'MMAP_ADD_OPTIONS'

export const MMAP_CHANGE_OPTIONS = 'MMAP_CHANGE_OPTIONS'
export const MMAP_CREATE_GENERATOR_LAYER = 'MMAP_CREATE_GENERATOR_LAYER'
export const MMAP_GENERATOR_DATA_INCOME = 'MMAP_GENERATOR_DATA_INCOME'
export const MMAP_UPDATE_GENERATOR = 'MMAP_UPDATE_GENERATOR'

export const MMAP_CHANGE_GENERATOR_STATUS = 'MMAP_CHANGE_GENERATOR_STATUS'

export const MMAP_ADD_GEN = 'MMAP_ADD_GEN'
export const MMAP_DELETE_GEN = 'MMAP_DELETE_GEN'
export const MMAP_DELETE_LAYER = 'MMAP_DELETE_LAYER'

export const MMAP_ADD_DATASET = 'MMAP_ADD_DATASET'
export const MMAP_DELETE_DATASET = 'MMAP_DELETE_DATASET'
export const MMAP_CLEAR_DATASET = 'MMAP_CLEAR_DATASET'

export const MMAP_TOGGLE_IS_3D_MAP_MODE = 'MMAP_TOGGLE_IS_3D_MAP_MODE'
export const MMAP_3D_LOAD_INIT = 'MMAP_3D_LOAD_INIT'
export const MMAP_3D_LOAD_INIT_FETCHING = 'MMAP_3D_LOAD_INIT_FETCHING'
export const MMAP_3D_LOAD_SUCCESS = 'MMAP_3D_LOAD_SUCCESS'
export const MMAP_3D_LOAD_FAIL = 'MMAP_3D_LOAD_FAIL'
export const MMAP_3D_DELETE_LAYER = 'MMAP_3D_DELETE_LAYER'
export const MMAP_3D_RESET_LAYER = 'MMAP_3D_RESET_LAYER'

export const MMAP_SET_SELECTED = 'MMAP_SET_SELECTED'

export const MMAP_SET_SELECTED_AREA = 'MMAP_SET_SELECTED_AREA'
export const MMAP_CLEAR_SELECTED_AREA = 'MMAP_CLEAR_SELECTED_AREA'

export const SET_GEN_PROPS = 'SET_GEN_PROPS'
export const SET_OPT_PROPS = 'SET_OPT_PROPS'
export const CLEAR_GEN_PROPS = 'CLEAR_GEN_PROPS'
export const CLEAR_OPT_PROPS = 'CLEAR_OPT_PROPS'

export const GENERATOR_CALC_INIT = 'GENERATOR_CALC_INIT'

export const CHECK_GENERATOR_STATUS = 'CHECK_GENERATOR_STATUS'
