import {
  MMAP_ADD,
  MMAP_ADD_DATASET,
  MMAP_ADD_GEN,
  MMAP_CHANGE_OPTIONS,
  MMAP_CLEAR_DATASET,
  MMAP_CLEAR,
  MMAP_CREATE_GENERATOR_LAYER,
  MMAP_DELETE_DATASET,
  MMAP_DELETE_GEN,
  MMAP_DELETE_LAYER,
  MMAP_TOGGLE_IS_3D_MAP_MODE,
  MMAP_3D_DELETE_LAYER,
  MMAP_3D_LOAD_SUCCESS,
  MMAP_SET_SELECTED,
  MMAP_CHANGE_GENERATOR_STATUS,
  MMAP_CLEAR_SELECTED_AREA,
  MMAP_SET_SELECTED_AREA,
  GENERATOR_CALC_INIT,
  CHECK_GENERATOR_STATUS,
  MMAP_3D_RESET_LAYER,
} from '../constants/mmap'

export const clearData = () => {
  return {
    type: MMAP_CLEAR,
  }
}

export const addToMMap = ({ set }, name = 'Set') => {
  return {
    type: MMAP_ADD,
    set,
    name,
  }
}

export const addGen = (generator) => {
  return {
    type: MMAP_ADD_GEN,
    generator,
  }
}

export const deleteGen = (token) => {
  return {
    type: MMAP_DELETE_GEN,
    token,
  }
}
export const changeGenStatus = (token, status) => {
  return {
    type: MMAP_CHANGE_GENERATOR_STATUS,
    token,
    status,
  }
}

export const createGeneratorLayer = (options) => {
  return {
    type: MMAP_CREATE_GENERATOR_LAYER,
    options,
  }
}

export const deleteLayer = (id) => {
  return {
    type: MMAP_DELETE_LAYER,
    id,
  }
}

export const changeMMapOptions = (opt) => {
  return {
    type: MMAP_CHANGE_OPTIONS,
    opt,
  }
}

export const addDataset = (id) => {
  return {
    type: MMAP_ADD_DATASET,
    id,
  }
}
export const deleteDataset = (id) => {
  return {
    type: MMAP_DELETE_DATASET,
    id,
  }
}

export const clearDataset = () => {
  return {
    type: MMAP_CLEAR_DATASET,
  }
}

export const toggleIs3dMode = () => {
  return {
    type: MMAP_TOGGLE_IS_3D_MAP_MODE,
  }
}

export const load3dLayer = (task_uuid, data) => {
  return {
    type: MMAP_3D_LOAD_SUCCESS,
    task_uuid,
    data,
  }
}

export const delete3dLayer = (task_uuid) => {
  return {
    type: MMAP_3D_DELETE_LAYER,
    task_uuid,
  }
}

export const reset3dLayer = (task_uuid) => {
  return {
    type: MMAP_3D_RESET_LAYER,
    task_uuid,
  }
}

export const setSelected = (arr) => {
  return {
    type: MMAP_SET_SELECTED,
    arr,
  }
}

export const setSelectedArea = (data) => {
  return {
    type: MMAP_SET_SELECTED_AREA,
    data,
  }
}

export const clearSelectedArea = () => {
  return {
    type: MMAP_CLEAR_SELECTED_AREA,
  }
}

export const startGeneratorAction = (props) => {
  return {
    type: GENERATOR_CALC_INIT,
    props,
  }
}

export const checkGeneratorStatusAction = (props) => {
  return {
    type: CHECK_GENERATOR_STATUS,
    props,
  }
}
