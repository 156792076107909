import i18n from 'i18n'
import { memo } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import CustomTag from 'components/common/customTag'
import CustomButton from 'components/common/customButton'
import SkeletonComponent from 'components/common/skeleton'
import { formatDateWithLocale } from 'utils/dateTime/formatDateAndTime'
import { Label, Caption, TextLarge } from 'components/common/text/index.style'

import { Line } from '../index.style'
import { DATASET_LOGS_PREFIX } from '../config/config'
import { Block, DatasetContainer, DatasetInfoBlock } from './index.style'

const DatasetInfo = memo(
  ({
    name,
    info,
    isCopy,
    created,
    onExpand,
    isExpanded,
    moleculesCount,
    logsLoadingStatus,
    isDatasetCalculated,
    isCompanyLogsLoading,
    isCompanyDatasetsLogging,
  }) => {
    const theme = useTheme()
    const { t } = useTranslation()

    if (
      isCompanyLogsLoading ||
      (!isCompanyDatasetsLogging && logsLoadingStatus === 'loading')
    )
      return (
        <SkeletonComponent width="100%" height="10.125rem" variant="grey" />
      )

    const { date, time } = formatDateWithLocale(created, i18n.language)

    const handleClick = (e) => {
      e?.stopPropagation()
      if (!isCompanyDatasetsLogging) return
      onExpand()
    }

    return (
      <DatasetContainer
        isCompanyDatasetsLogging={isCompanyDatasetsLogging}
        onClick={handleClick}
      >
        <DatasetInfoBlock>
          {!isCompanyDatasetsLogging && (
            <Line gap="0.5rem">
              {isCopy && (
                <CustomTag type="info">
                  <Caption
                    color={theme.colors.text.white}
                    fontWeight={theme.fontWeight.medium}
                  >
                    {t(`${DATASET_LOGS_PREFIX}copy`)}
                  </Caption>
                </CustomTag>
              )}

              <CustomTag type="white">
                <Caption
                  color={
                    isDatasetCalculated
                      ? theme.colors.statuses.success
                      : theme.colors.statuses.attention
                  }
                  fontWeight={theme.fontWeight.medium}
                >
                  {isDatasetCalculated
                    ? t(`${DATASET_LOGS_PREFIX}dataset_is_calculated`)
                    : t(`${DATASET_LOGS_PREFIX}dataset_is_not_calculated`)}
                </Caption>
              </CustomTag>
            </Line>
          )}
          <Block gap="0.25rem">
            <TextLarge
              ellipsis
              fontWeight={theme.fontWeight.semibold}
              color={
                isCompanyDatasetsLogging && isExpanded
                  ? theme.colors.text.accentPrimary
                  : theme.colors.text.primary
              }
            >
              {`«${name}»`}
            </TextLarge>
            {!!info && (
              <Label
                ellipsis
                fontWeight={theme.fontWeight.light}
                color={theme.colors.text.secondary}
              >
                {info}
              </Label>
            )}
          </Block>
          <Block>
            <Line>
              <Label>
                {t(`${DATASET_LOGS_PREFIX}structures_count`)}
                {':'}
              </Label>
              <Label>{moleculesCount}</Label>
            </Line>
            <Line>
              <Label>
                {t(`${DATASET_LOGS_PREFIX}creation_date`)}
                {':'}
              </Label>
              <Label>
                <Label>{`${date} ${time}`}</Label>
              </Label>
            </Line>
          </Block>
        </DatasetInfoBlock>
        {isCompanyDatasetsLogging && (
          <CustomButton
            type="primary"
            defaultPadding="0.5rem"
            defaultColor={
              isExpanded
                ? theme.colors.icons.accentPrimary
                : theme.colors.icons.primary
            }
            width="2.25rem"
            borderRadius="0.5rem"
          >
            <Icon
              iconType={isExpanded ? 'arrowTop' : 'arrowDown'}
              size="1.25rem"
            />
          </CustomButton>
        )}
      </DatasetContainer>
    )
  }
)

DatasetInfo.displayName = 'DatasetInfo'

export default DatasetInfo
