import { useEffect, useRef, useState } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Icon from 'components/Icon'
import CustomScrollbar from 'components/CustomScrollbar'
import CustomButton from 'components/common/customButton'
import LoaderRestyling from 'components/LoaderRestyling'
import { ScrollTopBtn } from 'pages/MoleculesList/index.style'
import { LoaderContainer } from 'pages/StructuresCompare/index.style'
import { Headline, TitleSecondary } from 'components/common/text/index.style'
import { clearStructuresComparisonList } from 'store/actions/structuresCompare'

import Categories from './Categories'
import StructuresList from './StructuresList'
import SelectedParameters from './SelectedParameters'
import { ComparisonListWrapper, Content, Header } from './index.style'

const StructuresCompare = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const scrollRef = useRef(null)

  const { calculation, isCalculationLoading } = useSelector(
    (store) => store.structuresCompare
  )

  const tasks = useSelector((store) => store.tasks)
  const notifications = useSelector((store) => store.notifications)

  const [scrollLeft, setScrollLeft] = useState(0)
  const [isSticky, setIsSticky] = useState(false)
  const [isScrollUpPossible, setIsScrollUpPosible] = useState(false)

  const { notificationsList, isHidden } = notifications

  const isNotificationsButtonShow =
    isHidden && notificationsList.length + tasks.length > 0

  const handleScroll = (v) => {
    if (!isSticky && v?.scrollTop > 132) setIsSticky(true)
    else if (isSticky && v?.scrollTop < 100) setIsSticky(false)
    setIsScrollUpPosible(v?.scrollTop > 0)
    scrollRef.current.scrollTop = v?.scrollTop
  }
  const handleScrollTop = () => scrollRef?.current?.scrollTo(0, 0)

  return (
    <>
      <CustomScrollbar
        className="margin-4-right"
        disableTrackYWidthCompensation={true}
        onScroll={handleScroll}
        innerRef={scrollRef}
        contentProps={{
          style: {
            paddingLeft: '1.125rem',
            paddingRight: '1.125rem',
          },
        }}
      >
        <ComparisonListWrapper>
          <Content {...{ isSticky }}>
            <Header>
              {isSticky ? (
                <>
                  <Headline fontWeight={theme.fontWeight.semibold}>
                    {t('structures_compare.comparison_list')}
                  </Headline>
                  <CustomButton
                    gap="0.25rem"
                    type="text_delete"
                    onClick={() => dispatch(clearStructuresComparisonList())}
                  >
                    <Icon iconType="deleted" size="1rem" gap="0.25rem" />
                    {t('structures_compare.clear_the_list')}
                  </CustomButton>
                </>
              ) : (
                <>
                  <TitleSecondary>
                    {t('structures_compare.comparison_list')}
                  </TitleSecondary>
                  <CustomButton
                    gap="0.25rem"
                    type="text_delete"
                    onClick={() => dispatch(clearStructuresComparisonList())}
                  >
                    <Icon iconType="deleted" size="1rem" gap="0.25rem" />
                    {t('structures_compare.clear_the_list')}
                  </CustomButton>
                </>
              )}
            </Header>

            <StructuresList {...{ isSticky, setScrollLeft }} />
          </Content>

          {isCalculationLoading ? (
            <LoaderContainer>
              <LoaderRestyling />
            </LoaderContainer>
          ) : (
            calculation.length > 0 && (
              <>
                <SelectedParameters {...{ isSticky, scrollLeft }} />
                <Categories {...{ isSticky, scrollLeft }} />
              </>
            )
          )}
        </ComparisonListWrapper>
        <ScrollTopBtn
          isStructuresComparePage
          onClick={handleScrollTop}
          visible={isScrollUpPossible}
          isNotificationsButtonShow={isNotificationsButtonShow}
        >
          <Icon iconType="arrowTop" size="1.25rem" />
        </ScrollTopBtn>
      </CustomScrollbar>
    </>
  )
}

export default StructuresCompare
