export const COMPANY_DATASETS_LOGS_PREFIX =
  'baskets.company_datasets_logs_dialog.'

export const LOGS_SORT_CONFIG = [
  {
    label: `${COMPANY_DATASETS_LOGS_PREFIX}date`,
    value: 'created',
  },
  {
    label: `${COMPANY_DATASETS_LOGS_PREFIX}name`,
    value: 'name',
  },
  {
    label: `${COMPANY_DATASETS_LOGS_PREFIX}structures_count`,
    value: 'molecules_count',
  },
  {
    label: `${COMPANY_DATASETS_LOGS_PREFIX}last_change`,
    value: 'last_change',
  },
]
