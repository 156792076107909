import { useTranslation } from 'react-i18next'

import SkeletonComponent from 'components/common/skeleton'
import CustomButton from 'components/common/customButton'
import { showCustomerDatasets } from 'utils/datasets/showCustomerDatasets'

import { Buttons } from './index.style'
import { DATASET_LOGS_PREFIX } from '../config/config'

const FooterButtons = ({
  basketType,
  onTransfer,
  onCalculate,
  hasRunningTask,
  molecules_count,
  logsLoadingStatus,
  is_basket_calculated,
  isDatasetCalculating,
}) => {
  const { t } = useTranslation()

  const isCalcDisabled = isDatasetCalculating || hasRunningTask
  const isTransferDisabled = isCalcDisabled || !showCustomerDatasets()

  return (
    <Buttons>
      {logsLoadingStatus !== 'success' ? (
        <>
          <SkeletonComponent width="50%" height="2.75rem" variant="grey" />
          <SkeletonComponent width="50%" height="2.75rem" variant="grey" />
        </>
      ) : (
        <>
          {basketType !== 'companies' && (
            <CustomButton
              width={is_basket_calculated ? '100%' : '50%'}
              type="secondary"
              onClick={onTransfer}
              disabled={isTransferDisabled}
            >
              {t(`${DATASET_LOGS_PREFIX}transfer_dataset`)}
            </CustomButton>
          )}
          {!is_basket_calculated && molecules_count > 0 && (
            <CustomButton
              width={basketType === 'companies' ? '100%' : '50%'}
              type="accent"
              onClick={onCalculate}
              disabled={isCalcDisabled}
            >
              {t(`${DATASET_LOGS_PREFIX}calculate_dataset`)}
            </CustomButton>
          )}
        </>
      )}
    </Buttons>
  )
}

export default FooterButtons
