import { useDispatch, useSelector } from 'react-redux'
import {
  addNotification,
  addSimpleNotification,
} from 'store/actions/notifications'
import { copyToClipboard, handleDownloadPNG } from 'utils/common/common'
import { v4 as uuidv4 } from 'uuid'
import {
  openAddMoleculeDialog,
  setEditorIndividualId,
} from 'store/actions/molecule'
import { downloadMol } from 'components/Molecule/molecule/utils/utils'
import { push } from 'connected-react-router'
import { store } from 'services/rest'
import * as FileSaver from 'file-saver'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'utils/auth/auth'
import { resetMe, toggleUserProfile } from '../store/actions/auth'
import { PDF2SMILES_DELETE_DOCUMENT_URL } from 'pages/Pdf2Smiles/config/config'
import {
  setNeedUpdateDocuments,
  toggleShowDialog,
} from 'store/actions/pdf2smiles'
import { confirm } from 'store/actions/confirm'
import { deleteBasket } from 'store/actions/crud'
import { deleteLayer } from 'store/actions/mmap'
import {
  setBasketDesc,
  setBasketName,
  setEditId,
  setEditMode,
  setSelected,
  setShowAddDialog,
  setShowLogDialog,
} from 'store/actions/basket'
import {
  REDIRECT_SPECTRA_TYPE,
  openTab,
  REDIRECT_REACTIONS_TYPE,
} from 'components/MoleculeViewer/config/config'
import { hideContextMenu } from 'store/actions/contextMenu'
import { sleep } from 'utils/common/common'
import { setLeftFilterLitDocType } from 'store/actions/filter'
import { licenseToggleAccess } from 'store/actions/adminPanel'
import {
  addStructureToCompare,
  uncompareStructure,
} from 'store/actions/structuresCompare'

export const useContextMenuAction = (item) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const { logout } = useAuth()
  const selected = useSelector((store) => store.basket.selected)

  /** METHODS - START */
  const handleMoleculeInfo = () => {
    const { num, onShowProps } = item || {}
    if (!num || !onShowProps) return
    onShowProps(num)
  }

  const handleCopySmiles = () => {
    const { smiles } = item
    if (smiles) {
      copyToClipboard(smiles)

      dispatch(addSimpleNotification('notification.smiles_copied'))
    }
  }

  const handeCopyBaseId = () => {
    const { num } = item
    if (num) {
      copyToClipboard(num)

      dispatch(addSimpleNotification('notification.baseid_copy'))
    }
  }

  const handleDownloadMol = () => {
    const { smiles } = item
    smiles && downloadMol(smiles)
  }
  const handleCopyLink = () => {
    const { num } = item
    copyToClipboard(`${window.location.origin}/molecule/${num}`)

    dispatch(addSimpleNotification('notification.link_copied'))
  }
  const handleDownloadPDF = async () => {
    dispatch(addSimpleNotification(t('notification.pdf_download_will_start')))
    const { downloadPDFHandler } = item
    dispatch(hideContextMenu())
    await sleep(500) //даем время скрыться менюшке
    downloadPDFHandler && downloadPDFHandler()
  }
  const handleOpenIndividual = () => {
    const { smiles } = item || {}
    smiles && dispatch(push(`/moleditor/${encodeURIComponent(smiles)}`))
  }
  const handleAddMoleculeToBasket = () => {
    const { num, smiles } = item || {}
    const numSmiles = num || smiles
    numSmiles && dispatch(openAddMoleculeDialog(numSmiles))
  }
  const handleSwitchEn = () => {
    i18n?.changeLanguage('en')
  }
  const handleSwitchRu = () => {
    i18n?.changeLanguage('ru')
  }
  const handleLink = (url) => {
    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.href = url
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  const goToProfile = () => {
    dispatch(toggleUserProfile(true))
  }
  const goToStructuresCompare = () => {
    dispatch(push('/molecules-compare'))
  }

  const addToComparison = () => {
    dispatch(addStructureToCompare(item.num, item.smiles))
  }

  const uncompare = () => {
    dispatch(uncompareStructure(item.num))
  }

  const handleExit = async () => {
    await logout()
    dispatch(push('/'))
    dispatch(resetMe())
  }
  const handleEditPdfName = () => {
    const { id } = item || {}
    dispatch(toggleShowDialog(id))
  }
  const handleDeletePdf = async () => {
    const { id } = item || {}
    if (!id) return
    dispatch(
      confirm(t('confirmation.delete_this_document'), async () => {
        try {
          await store(PDF2SMILES_DELETE_DOCUMENT_URL, {
            params: {
              filter: {
                id,
              },
            },
          })
          dispatch(setNeedUpdateDocuments(true))
        } catch (e) {
          const id = uuidv4()
          const notify = {
            id,
            name: 'notification.error',
            notification_type: 'error',
            text: 'pdf2smiles.viewer.failed_to_delete_document',
            autoRemove: true,
            timeout: 5000,
          }
          dispatch(addNotification(notify))
        }
      })
    )
  }
  const handleSelectDataset = () => {
    const { bask } = item || {}
    const { id } = bask || {}
    if (!id) return
    let data
    if (!selected.includes(id)) {
      data = [...selected, id]
    } else {
      data = selected.filter((el) => el !== id)
    }
    dispatch(setSelected(data))
  }
  const handleEditdataset = () => {
    const { bask } = item || {}
    const { info, name, id } = bask || {}
    dispatch(setBasketDesc(info))
    dispatch(setBasketName(name))
    dispatch(setEditId(id))
    dispatch(setEditMode(true))
    dispatch(setShowAddDialog(true))
  }
  const handleViewDatasetLog = () => {
    const { bask } = item || {}
    if (!bask?.id) return
    dispatch(setEditId(bask.id))
    dispatch(setShowLogDialog(true))
  }
  const handleDeleteDataset = () => {
    const { bask } = item || {}
    if (!bask?.id) return
    dispatch(
      confirm(t('confirmation.areYouSure'), () => {
        dispatch(deleteBasket([bask.id]))
        dispatch(deleteLayer(bask.id))
      })
    )
  }
  const handleCopyLiteratureLink = () => {
    const { path } = item || {}
    navigator.clipboard.writeText(`${window.location.origin}${path}`)
    dispatch(addSimpleNotification(`notification.link_copied`))
  }
  const handleCopyPatentNumber = () => {
    const { documentCode, dataType } = item || {}
    if (!documentCode) return
    const id = uuidv4()
    navigator.clipboard.writeText(documentCode)

    dispatch(
      addSimpleNotification(
        `notification.${
          dataType === 'article' ? 'DOI_copied' : 'patent_number_copied'
        }`
      )
    )
  }

  const handleGoTo = (url) => {
    const { smiles: redirect_smiles, num: molecule_id } = item

    if (!redirect_smiles) return
    switch (url) {
      case 'molecular_editor':
        dispatch(setEditorIndividualId(molecule_id))
        openTab(`/moleditor/${encodeURIComponent(redirect_smiles)}`)
        return
      case 'synthesis':
        openTab('/reactions', {
          redirect_smiles,
          [REDIRECT_REACTIONS_TYPE]: 'forward',
        })
        return
      case 'retrosynthesis':
        openTab('/reactions', {
          redirect_smiles,
          [REDIRECT_REACTIONS_TYPE]: 'reverse',
        })
        return
      case 'nmr_spectra':
        openTab('/spectra', {
          redirect_smiles,
          [REDIRECT_SPECTRA_TYPE]: 'nmr',
        })
        return
      case 'mass_spectra':
        openTab('/spectra', {
          redirect_smiles,
          [REDIRECT_SPECTRA_TYPE]: 'ms',
        })
        return
      case 'ir_spectra':
        openTab('/spectra', {
          redirect_smiles,
          [REDIRECT_SPECTRA_TYPE]: 'infraredSpectrometry',
        })
        return
      case 'synthesis_cost':
        openTab('/synthcost', {
          redirect_smiles,
        })
        return
      default:
        return
    }
  }
  const handleAddCondition = (option, docType) => {
    const { handler } = item
    if (!handler) return
    handler(option)

    if (docType === 'article') {
      dispatch(setLeftFilterLitDocType(['article']))
    }
    if (docType === 'patent') {
      dispatch(setLeftFilterLitDocType(['patent']))
    }
  }

  const handleHighlightsDownload = (type) => {
    const { handler } = item
    if (!handler) return
    handler(type)
  }

  const schemesDownload = async (type) => {
    const { handler, selectedScheme } = item || {}
    if (!handler) return
    if (type === 'pdf') {
      dispatch(addSimpleNotification(t('notification.pdf_download_will_start')))
      dispatch(hideContextMenu())
      await sleep(500) //даем время скрыться менюшке
    }
    handler(type, selectedScheme)
  }

  const handleSelectGenProps = (prop) => {
    const { handler, paramsType } = item
    handler(prop, paramsType)
  }

  const handleEditMolecule = () => {
    const { toggleShowKetcher } = item
    toggleShowKetcher()
  }

  const handleToggleAccess = ({ restore }) => {
    const {
      company: { license_id: id },
    } = item
    dispatch(
      licenseToggleAccess({
        isCurrentCustomerPage: false,
        id,
        is_active: restore,
      })
    )
  }

  const handleOpenCompanyEditDialog = () => {
    const { handler } = item
    if (!handler) return
    handler()
  }

  const handleOpenNoteDialog = () => {
    const { toggleShowNoteDialog } = item
    toggleShowNoteDialog()
  }

  const handleCopyReactionPageLink = () => {
    const { handler } = item
    if (!handler) return
    handler()
  }
  /** METHODS - END */

  const actionsConfig = {
    edit_name_or_note: handleOpenNoteDialog,
    write_name_or_note: handleOpenNoteDialog,
    molecule_information: handleMoleculeInfo,
    copy_smiles: handleCopySmiles,
    copy_baseid: handeCopyBaseId,
    download_mol: handleDownloadMol,
    copy_link: handleCopyLink,
    download_png: () =>
      handleDownloadPNG({
        smiles: item.smiles,
        saveHandler: (props) => FileSaver.saveAs(props),
      }),
    download_pdf: handleDownloadPDF,
    open_individual: handleOpenIndividual,
    add_to_dataset: handleAddMoleculeToBasket,
    edit_molecule: handleEditMolecule,
    switch_en: handleSwitchEn,
    switch_ru: handleSwitchRu,
    about_us: () =>
      handleLink(
        i18n.language === 'en' ? 'https://syntelly.com' : 'https://syntelly.ru'
      ),
    user_guide: () =>
      handleLink(
        i18n.language === 'en'
          ? 'https://syntelly.com/userguide'
          : 'https://syntelly.ru/manual'
      ),
    open_telegram: () => handleLink('https://t.me/syntellyplatform'),
    user_profile: goToProfile,
    comparison_list: goToStructuresCompare,
    add_to_comparison: addToComparison,
    uncompare: uncompare,
    exit_app: handleExit,
    edit_name: handleEditPdfName,
    delete_pdf: handleDeletePdf,
    select_dataset: handleSelectDataset,
    deselect_dataset: handleSelectDataset,
    edit_dataset: handleEditdataset,
    view_log: handleViewDatasetLog,
    delete_dataset: handleDeleteDataset,
    copy_literature_link: handleCopyLiteratureLink,
    copy_patent_number: handleCopyPatentNumber,
    copy_doi_number: handleCopyPatentNumber,
    go_molecular_editor: () => handleGoTo('molecular_editor'),
    go_retrosynthesis: () => handleGoTo('retrosynthesis'),
    go_nmr_spectra: () => handleGoTo('nmr_spectra'),
    go_mass_spectra: () => handleGoTo('mass_spectra'),
    go_ir_spectra: () => handleGoTo('ir_spectra'),
    go_synthesis_cost: () => handleGoTo('synthesis_cost'),
    go_synthesis: () => handleGoTo('synthesis'),
    add_condition_title: () => handleAddCondition('title'),
    add_condition_doi: () => handleAddCondition('doi', 'article'),
    add_condition_patent_number: () =>
      handleAddCondition('patent_number', 'patent'),
    add_condition_authors: () => handleAddCondition('authors', 'common'),
    add_condition_abstract: () => handleAddCondition('abstract', 'common'),
    add_condition_language: () => handleAddCondition('language', 'common'),
    add_condition_published_date: () =>
      handleAddCondition('published_date', 'common'),
    add_condition_journal: () => handleAddCondition('journal', 'article'),
    add_condition_publisher: () => handleAddCondition('publisher', 'article'),
    add_condition_company: () => handleAddCondition('company', 'patent'),
    add_condition_applicant: () => handleAddCondition('applicant', 'patent'),
    add_condition_text: () => handleAddCondition('text', 'patent'),
    add_condition_claims: () => handleAddCondition('claims', 'patent'),
    highlights_download_png: () => handleHighlightsDownload('png_zip'),
    highlights_download_csv: () => handleHighlightsDownload('csv'),
    download_schemes_excel: () => schemesDownload('excel'),
    download_schemes_pdf: () => schemesDownload('pdf'),
    download_schemes_csv: () => schemesDownload('csv'),
    add_qed: () => {
      handleSelectGenProps('qed')
    },
    add_cats: () => {
      handleSelectGenProps('cats')
    },
    add_boiling_point: () => {
      handleSelectGenProps('qsar_bp')
    },
    add_melting_point: () => {
      handleSelectGenProps('qsar_mp')
    },
    add_mouse_oral_ld50: () => {
      handleSelectGenProps('qsar_toxicity')
    },
    add_logp: () => {
      handleSelectGenProps('qsar_logp')
    },
    add_logs: () => {
      handleSelectGenProps('qsar_logs')
    },
    add_dmso_solubility: () => {
      handleSelectGenProps('qsar_dmso')
    },
    add_complexity: () => {
      handleSelectGenProps('complexity')
    },
    add_syba: () => {
      handleSelectGenProps('syba')
    },
    add_brutto: () => {
      handleSelectGenProps('brutto')
    },
    disable_access: () => {
      handleToggleAccess({ restore: false })
    },
    restore_access: () => {
      handleToggleAccess({ restore: true })
    },
    edit_company: () => {
      handleOpenCompanyEditDialog()
    },
    copy_reaction_page_link: () => {
      handleCopyReactionPageLink()
    },
  }

  const handleAction = async (action) => {
    if (typeof actionsConfig[action] === 'function') {
      await actionsConfig[action]()
    }
  }

  return handleAction
}
