import { useTranslation } from 'react-i18next'

import SortPanel from 'components/SortPanel'
import CustomInput from 'components/common/customInput'

import { Panel } from './index.style'
import { COMPANY_DATASETS_LOGS_PREFIX } from '../config/config'

const LogsSortPanel = ({
  searchValue,
  onChangeSearchValue,
  sortType,
  onChangeSorting,
  sortOptions,
  switcherID,
}) => {
  const { t } = useTranslation()
  return (
    <Panel>
      <CustomInput
        value={searchValue}
        onChange={onChangeSearchValue}
        withClearButton
        placeholder={t(`${COMPANY_DATASETS_LOGS_PREFIX}enter_dataset_name`)}
      />
      <SortPanel
        {...{ sortType, switcherID }}
        onChangeSorting={onChangeSorting}
        sortOptions={sortOptions}
        type="grey"
      />{' '}
    </Panel>
  )
}

export default LogsSortPanel
