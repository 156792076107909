import styled from 'styled-components'

import { SelectContainer } from 'components/CustomSelect/index.style'

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ noGap }) => (noGap ? 0 : '0.75rem')};
  flex-direction: column;
  padding: ${({ noPadding }) => (noPadding ? 0 : '0rem 1.5rem')};

  .select-container {
    width: 13.75rem;
  }
  ${SelectContainer} {
    min-width: 13.75rem;
  }
`
