import React, { memo } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  getDataByCategoryName,
  HEADERS_EXCEPTIONS,
} from 'config/molecule/molecule'
import { HEADERS_CONFIG } from 'config/config'
import PropertyKey from 'components/PropertyKey'
import CheckboxWithTitle from 'components/CheckboxWithTitle'
import { ColoredSubheadline } from 'components/HeadersProperty/index.style'

import EmptyCells from '../EmptyCells'
import { HEADERS_WITH_VALUE, MAX_STRUCTURES_COUNT } from '../config'
import { Divider, MainHeaderKey, StyledTable } from '../Category/index.style'

const HeadersTypeTable = memo(
  ({
    name,
    isSticky,
    onToggle,
    calculation,
    selectedParams,
    category_title,
    isSelectedParamsTable,
  }) => {
    const theme = useTheme()
    const { t } = useTranslation()
    if (isSelectedParamsTable && !selectedParams.some((s) => s?.header))
      return null

    let headersArr = calculation.map(({ data }) => ({
      id: data.baseID,
      values: getDataByCategoryName(name, data)?.filter(
        ({ value }) =>
          value !== 'error' &&
          !value?.find(
            ({ type, text }) =>
              type === 'header' && HEADERS_EXCEPTIONS.headers.includes(text)
          )
      ),
    }))

    let keysArr = Object.keys(HEADERS_CONFIG)

    if (isSelectedParamsTable) {
      headersArr = headersArr.map((el) => ({
        ...el,
        values: el.values.filter((element) =>
          selectedParams.some((s) => s?.header === element?.index)
        ),
      }))

      keysArr = keysArr.filter((el) =>
        selectedParams.some((s) => s?.header === el)
      )
    }

    return (
      <StyledTable isSticky={isSticky}>
        <thead></thead>
        <tbody>
          {keysArr.map((el, ind) => {
            const valuesArr = HEADERS_CONFIG[el]
            return (
              <React.Fragment key={`key-${el}-${isSelectedParamsTable}`}>
                <tr>
                  {headersArr.map(({ id, values: h }, i) => {
                    const { value } = h.find(({ index }) => index === el)

                    const header = value.find((el) => el.type === 'header')
                    const { text, color } = header

                    const isSelected = selectedParams.some(
                      (el) => el.name === text
                    )

                    if (HEADERS_WITH_VALUE.includes(el) && i > 0) return <></>

                    if (!HEADERS_WITH_VALUE.includes(el))
                      return (
                        <MainHeaderKey
                          key={`${text}-${id}-${isSelectedParamsTable}`}
                          isFirstRow={!ind}
                          className="main-header-key"
                          isFirstColumn={!i}
                        >
                          <CheckboxWithTitle
                            id={`${text}-toggle-${id}-${isSelectedParamsTable}`}
                            value={isSelected}
                            withCustomTitle={true}
                            withCustomLink={false}
                            onChange={() => onToggle(text, category_title, el)}
                          >
                            <PropertyKey
                              id={`${id}-${isSelectedParamsTable}`}
                              {...{ text }}
                              textColor={
                                color === 'success'
                                  ? theme.colors.statuses.success
                                  : theme.colors.statuses.error
                              }
                              textForRender={t(`molviewer.${text}`)}
                              className="main-property"
                            />
                          </CheckboxWithTitle>
                        </MainHeaderKey>
                      )

                    return (
                      <>
                        <MainHeaderKey
                          key={`${text}-${id}-${isSelectedParamsTable}`}
                          isSticky={HEADERS_WITH_VALUE.includes(el)}
                          isFirstRow={!ind}
                          isFirstColumn={true}
                          colSpan={MAX_STRUCTURES_COUNT}
                        >
                          <CheckboxWithTitle
                            id={`${text}-toggle-${id}-${isSelectedParamsTable}`}
                            value={isSelected}
                            withCustomTitle={true}
                            withCustomLink={false}
                            onChange={() => onToggle(text, category_title, el)}
                          >
                            <PropertyKey
                              id={`${id}-${isSelectedParamsTable}`}
                              {...{ text }}
                              textColor={
                                isSelected
                                  ? theme.colors.text.primary
                                  : theme.colors.text.secondary
                              }
                              textForRender={t(`molviewer.${text}`)}
                            />
                          </CheckboxWithTitle>
                        </MainHeaderKey>
                        <EmptyCells
                          name={text}
                          ind={id}
                          isSelected={isSelectedParamsTable}
                        />
                      </>
                    )
                  })}
                </tr>

                {valuesArr.length ? (
                  valuesArr.map((title, ind) => {
                    const isSelected = selectedParams.some(
                      (el) => el.name === title
                    )
                    return (
                      <React.Fragment
                        key={`${title}-${ind}-${isSelectedParamsTable}`}
                      >
                        <tr>
                          <td
                            style={{
                              paddingBottom: '0.75rem',
                            }}
                            className="sticky-cell"
                            colSpan={MAX_STRUCTURES_COUNT}
                          >
                            <PropertyKey
                              text={title}
                              textForRender={t(`molviewer.${title}`)}
                              textColor={
                                isSelected
                                  ? theme.colors.text.primary
                                  : theme.colors.text.secondary
                              }
                              id={`${ind + 1}-${isSelectedParamsTable}`}
                            />
                          </td>
                          <EmptyCells
                            name={title}
                            ind={ind}
                            isSelected={isSelectedParamsTable}
                          />
                        </tr>
                        <tr>
                          {headersArr.map(({ id, values: h }) => {
                            const { value } = h.find(
                              ({ index }) => index === el
                            )

                            const currentValue = value.find(
                              (el) => el.type === 'line' && el.text === title
                            )

                            return (
                              <td key={`${h}-${id}-${isSelectedParamsTable}`}>
                                <ColoredSubheadline
                                  color={theme.colors.text.primary}
                                  status={currentValue?.color}
                                >
                                  {currentValue?.value === 'not included'
                                    ? t(`molviewer.not_included`)
                                    : currentValue?.value}
                                  {currentValue?.units &&
                                    !HEADERS_EXCEPTIONS.units.includes(
                                      currentValue.units
                                    ) &&
                                    ` ${t(`molviewer.${currentValue.units}`)}`}
                                </ColoredSubheadline>
                              </td>
                            )
                          })}
                        </tr>

                        {ind !== valuesArr.length - 1 && (
                          <tr>
                            <td>
                              <Divider
                                width={`${MAX_STRUCTURES_COUNT * 100}%`}
                              />
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    )
                  })
                ) : (
                  <React.Fragment>
                    <tr>
                      {headersArr.map(({ id, values: h }) => {
                        const { value } = h.find(({ index }) => index === el)

                        const currentValue = value.find(
                          (element) =>
                            element.type === 'header' &&
                            element.text.toLowerCase().includes(el)
                        )

                        return (
                          <td key={`${h}-${id}-${isSelectedParamsTable}`}>
                            <ColoredSubheadline
                              color={theme.colors.text.primary}
                              status={currentValue?.color}
                            >
                              {currentValue?.value === 'not included'
                                ? t(`molviewer.not_included`)
                                : currentValue?.value || '-'}
                              {currentValue?.units &&
                                !HEADERS_EXCEPTIONS.units.includes(
                                  currentValue.units
                                ) &&
                                ` ${t(`molviewer.${currentValue.units}`)}`}
                            </ColoredSubheadline>
                          </td>
                        )
                      })}
                    </tr>
                  </React.Fragment>
                )}
              </React.Fragment>
            )
          })}
        </tbody>{' '}
      </StyledTable>
    )
  }
)

HeadersTypeTable.displayName = 'DisplayName'
export default HeadersTypeTable
