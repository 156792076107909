import React, { Fragment, useEffect, useState, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ButtonsWrapper,
  CalculationHeader,
  Content,
  HeaderWrapper,
} from './index.style'
import { DIALOG_PREFIX } from './config/config'
import CustomButton from 'components/common/customButton'
import { ProgressBar } from 'components/Basket/index.style'
import {
  CustomDialog,
  CustomDialogHeader,
} from 'components/common/customDialog'
import Icon from 'components/Icon'
import { fetch } from 'services/rest'

const ExportBasketDialog = ({
  basketName,
  handleDownload,
  handleClose,
  calculateForExport,
  bask,
  tasksStatus,
  handleShowDatasetTable,
  type = 'export',
}) => {
  const { t } = useTranslation()
  const [isReady, setIsReady] = useState(false) //now init value is always false, cause we have no isCalculated value
  const [progress, setProgress] = useState(0)
  const [isDisabledBtns, setIsDisabledBtns] = useState(false)
  const [isCalculatedChecked, setCalculatedChecked] = useState(false)
  const [isCsvReady, setIsCsvReady] = useState(false)
  const [needUpdateMolecules, setNeedUpdateMolecules] = useState(false)

  const isBasketLoading = bask?.status === 'pending'

  const prepareButtonsConfig = [
    {
      text: 'calculate',
      width: type === 'export' ? '50%' : '100%',
      onClick: async () => {
        setIsDisabledBtns(true)
        if (!(await calculateForExport())) setIsDisabledBtns(false)
      },
    },
    {
      text: 'export',
      width: '50%',
      hidden: type === 'analysis',
      onClick: () => {
        if (!isReady) {
          setIsReady(true)
        }
      },
    },
  ]

  const exportButtonsConfig = [
    {
      text: 'export_in_CSV',
      onClick: () => setIsCsvReady(true),
    },
    {
      text: 'export_in_SDF',
      onClick: () => handleDownload('sdf'),
    },
    {
      text: 'export_in_XLSX',
      onClick: () => handleDownload('xlsx'),
    },
  ]

  const handleDownloadCsv = () => {
    handleDownload('csv')
    setIsCsvReady(false)
  }

  useEffect(() => {
    if (!isBasketLoading && isDisabledBtns) setIsDisabledBtns(false)
  }, [isBasketLoading])

  useEffect(() => {
    if (!bask) return
    const { task_id } = bask
    if (task_id) {
      const taskStatus = tasksStatus.filter(
        (taskStatus) => task_id === taskStatus.id.toString()
      )

      if (taskStatus.length !== 0) {
        setProgress(taskStatus[0].progress)
      }
    }
  }, [tasksStatus, bask])

  useEffect(() => {
    if (progress === 100) setIsReady(true)
  }, [progress])

  useEffect(() => {
    handleCheckBasketCalculation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCheckBasketCalculation = async () => {
    try {
      const res = await fetch(`is_basket_calculated?basket_id=${bask.id}`)
      if (res?.data?.result?.is_calculated) {
        setIsReady(true)
      } else if (type === 'analysis') setNeedUpdateMolecules(true)
    } catch (err) {
      console.log(err)
    } finally {
      setCalculatedChecked(true)
    }
  }

  if (!isCalculatedChecked) return null
  if (isReady && type === 'analysis') {
    handleClose()
    handleShowDatasetTable(needUpdateMolecules)
  }

  return (
    <CustomDialog
      onClose={handleClose}
      width={'424px'}
      outboundExceptions={['custom-snackbar-outbound-exception']}
    >
      {isReady ? (
        <Fragment>
          <CustomDialogHeader>
            {isCsvReady
              ? t(`${DIALOG_PREFIX}exporting_set_in_csv_format`)
              : t(`${DIALOG_PREFIX}set_export`)}
          </CustomDialogHeader>
          <Content>
            {isCsvReady
              ? t(`${DIALOG_PREFIX}csv_warning`)
              : t(`${DIALOG_PREFIX}select_the_desired_download_file_format`)}
          </Content>
          <ButtonsWrapper>
            {isCsvReady ? (
              <CustomButton
                onClick={handleDownloadCsv}
                type="secondary"
                width={'100%'}
              >
                {t(`${DIALOG_PREFIX}ok`)}
              </CustomButton>
            ) : (
              exportButtonsConfig.map(({ text, onClick }, i) => (
                <CustomButton
                  key={i}
                  onClick={onClick}
                  type="secondary"
                  width={'50%'}
                >
                  {t(`${DIALOG_PREFIX}${text}`)}
                </CustomButton>
              ))
            )}
          </ButtonsWrapper>
        </Fragment>
      ) : (
        <Fragment>
          <HeaderWrapper>
            <Icon iconType={'warning'} />
            <CustomDialogHeader>{`${t(`${DIALOG_PREFIX}set`)} ${basketName} ${t(
              `${DIALOG_PREFIX}has_uncalculated_molecules`
            )}`}</CustomDialogHeader>
          </HeaderWrapper>
          <Content>{t(`${DIALOG_PREFIX}uncalculated_warning_${type}`)}</Content>
          <ButtonsWrapper isCalculation={isBasketLoading}>
            {isBasketLoading ? (
              <Fragment>
                <CalculationHeader>
                  {t(`${DIALOG_PREFIX}calculation`)}
                </CalculationHeader>
                <ProgressBar>
                  <span
                    style={{ width: `${Math.round(progress || 0)}%` }}
                  ></span>
                </ProgressBar>
              </Fragment>
            ) : (
              prepareButtonsConfig.map(
                ({ text, onClick, hidden, width }, i) =>
                  !hidden && (
                    <CustomButton
                      key={i}
                      width={width}
                      onClick={onClick}
                      type="secondary"
                      disabled={isDisabledBtns}
                    >
                      {t(`${DIALOG_PREFIX}${text}`)}
                    </CustomButton>
                  )
              )
            )}
          </ButtonsWrapper>
        </Fragment>
      )}
    </CustomDialog>
  )
}

export default ExportBasketDialog
