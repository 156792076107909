import { useEffect } from 'react'
import MoleculeViewer from '../../MoleculeViewer'
import { BORDER_DELTA } from '../config'
import { getCardType } from '../utils/utils'
import { CarouselDiv, CarouselWrapper } from './index.style'
import { withResizeDetector } from 'react-resize-detector'
import { DEFAULT_TLIGHT } from 'components/TlightParams/config/config'

const Carousel = ({
  carouselCounter,
  structures,
  width,
  isDialog,
  litSearchSmiles,
  setCanScrollRight,
  setIsScrolledRight,
  isAllDataShow,
  setViewCount,
  viewCount,
}) => {
  const paddingDelta = isDialog ? 8 : 0

  const preparedData =
    structures?.length && structures.slice(0, carouselCounter + viewCount + 2)

  const lastEl = document?.querySelector('.last-element-carousel')

  const container = document?.querySelector('#carouselWrapper')

  useEffect(() => {
    const newViewCount = width < 692 ? 2 : width < 1400 ? 3 : 4
    const realCount = structures?.length
    const countForCalc = realCount < newViewCount ? realCount : newViewCount
    if (isAllDataShow) {
      const canScroll =
        container?.clientWidth <
        lastEl?.getBoundingClientRect()?.width * countForCalc +
          12 * (countForCalc - 1)
      if (canScroll) setIsScrolledRight(false)

      setCanScrollRight(canScroll)
    }
    setViewCount(newViewCount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, isAllDataShow])

  const getTranslate = () => {
    return `translate(-${
      carouselCounter *
      (Math.floor((width - paddingDelta - (viewCount - 1) * 12) / viewCount) +
        12)
    }px)`
  }

  return (
    <CarouselWrapper id="carouselWrapper">
      <CarouselDiv transformValue={getTranslate} isDialog={isDialog}>
        {preparedData &&
          preparedData.map((el, ind, arr) => (
            <MoleculeViewer
              key={el.id}
              molid={el.id}
              withColors={true}
              num={el.id}
              noEdit
              noDelete
              noMenu
              showIdWithoutMenu
              onShowProps={() => {}}
              smiles={el.smiles}
              type={getCardType(
                isDialog,
                ind === 0 && el.smiles === litSearchSmiles
              )}
              width={
                Math.floor(
                  (width - paddingDelta - (viewCount - 1) * 12) / viewCount
                ) - BORDER_DELTA
              }
              height={248}
              noPadding={true}
              cursor="pointer"
              withData
              params={JSON.stringify(el.tlight ? el.tlight : DEFAULT_TLIGHT)}
              litSearchSmiles={litSearchSmiles}
              referencesCount={el?.references_in_documents}
              className={ind === arr.length - 1 ? 'last-element-carousel' : ''}
              fixedCanvas={true}
            />
          ))}
      </CarouselDiv>
    </CarouselWrapper>
  )
}

export default withResizeDetector(Carousel)
