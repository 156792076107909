export const DEFAULT_CELL_HEIGHT = 3
export const LARGE_CELL_HEIGHT = 3.5
export const EXTRA_HEIGHT = 1.5625

export const HEADERS_WITH_VALUE = ['qed', 'pains']

export const DRUG_LIKENESS_HEIGHTS = [
  { name: 'lipinski', height: 19.5 },
  { name: 'ghose', height: 19.5 },
  { name: 'oprea', height: 19.5 },
  { name: 'veber', height: 10.875 },
  { name: 'pains', height: 4 },
  { name: 'qed', height: 4 },
]

export const MAX_STRUCTURES_COUNT = 20
