import styled from 'styled-components'

import { TitleTertiary } from 'components/common/text/index.style'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f5f7f7;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding-right: 0.75rem;
`

export const HeaderWithToolbar = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0.75rem 1.5rem 0.75rem;
`
export const SortWithLogging = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  white-space: nowrap;
`

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DatasetTypeSelectors = styled.div`
  height: max-content;
  display: flex;
  gap: 0.5rem;
`

export const BasketList = styled.div`
  display: grid;
  row-gap: 0.75rem;
  column-gap: 0.5rem;
  flex-wrap: wrap;
  max-height: 100%;
  padding-bottom: 0.75rem;
  padding-left: 0.75rem;
  grid-template-columns: repeat(auto-fill, minmax(min(16rem, 100%), 1fr));
  align-content: flex-start;
`

export const BasketListWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(100% - 11rem);
  overflow-y: hidden;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  background-color: ${({ theme }) => theme.colors.buttons.secondary};
  width: fit-content;
  border-radius: 0.75rem;
`

export const EmptyBasket = styled.div`
  gap: 0.75rem;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  width: 16rem;
  height: 11.5rem;
  user-select: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.75rem;
  margin-left: 0.75rem;

  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  cursor: pointer;
  user-select: none;

  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: ${({ isSelected }) => (isSelected ? '#42C1BF' : 'transparent')};
  transition: 0.15s all ease-in-out;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};

  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.default};
    color: ${({ theme }) => theme.colors.text.accentPrimary};
  }
`
export const NothingFoundText = styled(TitleTertiary)`
  padding-left: 0.75rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`
