import Icon from 'components/Icon'
import { useTheme } from 'styled-components'
import CustomButton from 'components/common/customButton'
import { Headline, TitleTertiary } from 'components/common/text/index.style'

import {
  IconWrapper,
  PagePreviewWrapper,
  PagePreviewContainer,
  PagePreviewTextWrapper,
} from './index.style'

export const PagePreview = ({
  title,
  onClick,
  iconType = 'folderClosed',
  withButton,
  buttonText,
  description,
}) => {
  const theme = useTheme()
  return (
    <PagePreviewWrapper>
      <PagePreviewContainer>
        <IconWrapper>
          <Icon iconType={iconType} />
        </IconWrapper>
        <PagePreviewTextWrapper>
          <TitleTertiary fontWeight={theme.fontWeight.semibold}>
            {title}
          </TitleTertiary>
          <Headline
            color={theme.colors.text.secondary}
            fontWeight={theme.fontWeight.medium}
            textAlign="center"
          >
            {description}
          </Headline>
        </PagePreviewTextWrapper>
        {withButton ? (
          <CustomButton
            type="primary"
            size="big"
            className="view-history-btn"
            onClick={onClick}
            minWidth="6rem"
          >
            {buttonText}
          </CustomButton>
        ) : (
          <></>
        )}
      </PagePreviewContainer>
    </PagePreviewWrapper>
  )
}

export default PagePreview
