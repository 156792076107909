import styled, { css } from 'styled-components'

export const ComparisonListWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  position: relative;
`
export const Header = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ParametersContainer = styled.div`
  height: 100%;
  display: flex;
  padding: ${({ isSelectedParamsTable }) =>
    isSelectedParamsTable ? 0 : '1rem'};
  gap: ${({ isSelectedParamsTable }) =>
    isSelectedParamsTable ? 0 : '0.75rem'};
  flex-direction: column;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.primary};

  .structures-compare-category-sort {
    margin-top: 0.25rem;
    margin-bottom: 1.25rem;
  }
`

export const Content = styled.div`
  gap: 1.5rem;
  display: flex;
  padding-top: 1.5rem;
  flex-direction: column;
  position: ${({ isSticky }) => isSticky && 'sticky'};
  top: 0;
  ${({ theme, isSticky }) =>
    isSticky &&
    css`
      padding: 1rem;
      gap: 1rem;
      background-color: ${theme.colors.backgrounds.primary};
      border-radius: 0 0 0.75rem 0.75rem;
      box-shadow: 0px 4px 4px 0px #2d37450a, 0px 0px 4px 0px #2d37450a;
      z-index: 1000;
    `}
`
