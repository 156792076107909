import axios from 'axios'
import { useEffect, useRef, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector, connect } from 'react-redux'
import './molecules/variant-block.css'

import {
  setSearchV2,
  resetSearch,
  setBingoSearch,
  resetLitSearch,
  setLiteratureSearch,
  setReactionsSearch,
  resetReactionsSearch,
} from 'store/actions/crud'
import {
  SEARCH_PATH,
  REACTIONS_PATH,
  SYNTHCOST_PATH,
  MOLECULES_LIST_PATH,
} from 'pages/MoleculesList/config/config'
import Icon from 'components/Icon'
import {
  setDrawnSmiles,
  resetPagination,
  resetSearchSortingConfig,
} from 'store/actions/search'
import {
  Subheadline,
  TextButtonMedium,
} from 'components/common/text/index.style'
import {
  resetFilter,
  setOpenFilter,
  setLeftFilterLitDocType,
} from 'store/actions/filter'
import { setSearchType, setSearchText } from 'store/actions/search'

import {
  Search,
  SearchBtns,
  SearchType,
  SearchTypes,
  SearchBlock,
  SearchPanel,
  SearchButton,
  LongTaskBlock,
  SearchTypeTooltip,
  DrawnMoleculeContainer,
  DrawnMoleculeContainerLarge,
  InputBlockWrapper,
  TooltipText,
  SmallBtns,
  SmallEditBtn,
  SmallDeleteBtn,
  SmallBtnsContainer,
} from './style/index.style'
import { storeV2 } from '../../services/rest'
import history from '../../services/history'
import Ketcher from '../../components/Ketcher'
import { VariantBlock } from './molecules/variant-block'
import {
  getSmileSize,
  getSmileSizeLarge,
  isNumeric,
  searchTypes,
  smileSizeRight,
} from './config'
import {
  validateEnglishCyrillicInput,
  validateEnglishInput,
  validateNotStarSymbol,
} from 'utils/common/common'
import { addNotification } from 'store/actions/notifications'
import { useDebounce } from 'hooks/useDebounce'
import CustomTab from 'components/common/customTab'
import CustomButton from 'components/common/customButton'
import {
  setLiteratureSyntellyId,
  setLitFilterConfig,
} from 'store/actions/literature'
import { setReactionsCompoundId } from 'store/actions/reactionSearch'
import CustomInput from 'components/common/customInput'
import MoleculeStructure from 'components/MoleculeStructure'
import { INNER_LANG_OPERATOR } from 'store/sagas/utils/config'
import { convertNewOperatorToOld, getField } from 'store/sagas/utils/utils'

const MainSearchBar = ({
  text,
  resetFilter,
  similarity,
  filterConfig,
  filtersOpen,
  searchType,
  setSearchType,
  setSearchText,
  bingoSearch,
  litSearch,
  resetSearch,
  resetLitSearch,
  onResetSearch,
  onSelectOption,
  onSearchClick,
  resetPagination,
  withWhiteBackground = true,
  onInputChange,
  inputValue,
  litFilterConfig,
  addNotification,
  pagination,
  drawnSmiles,
  setDrawnSmiles,
  sortingConfig,
  resetSearchSortingConfig,
  notificationsList,
  setLitFilterConfig,
  searchHistory,
  isSearchHidden,
  basket,
  isPublic,
  reactionsSearch,
  resetReactionsSearch,
  setReactionsCompoundId,
  setLiteratureSyntellyId,
  literatureSyntellyId,
}) => {
  /** INIT - START */
  const { t } = useTranslation()

  const location = useLocation()

  const dispatch = useDispatch()
  const searchV2 = useSelector((state) => state.crud.searchV2)
  const reactionsLoading = useSelector((state) => state.reactions.loading)

  const [ketcher, setKetcher] = useState(false)
  const [quickSearchOptions, setQuickSearchOptions] = useState([])
  const [showOptions, setShowOptions] = useState(false)
  const [inputChanges, setInputChanges] = useState(false)
  const [cursorX, setCursorX] = useState(0)
  const [cursorY, setCursorY] = useState(0)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [notificationIds, setNotificationIds] = useState([])
  const [isSmilesDrawn, setIsSmilesDrawn] = useState(false)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)

  const quickSearchSelectedRef = useRef('')
  const inputRef = useRef()

  const { match_type: filtersMatchType, disabled: filtersDisabled } =
    filterConfig

  const isSearchPage = location.pathname.split('/').includes(SEARCH_PATH)
  const isReactionsPage = location.pathname.split('/').includes(REACTIONS_PATH)
  const isSynthcostPage = location.pathname.split('/').includes(SYNTHCOST_PATH)
  const isMarkushSearch = filtersMatchType === 'markush structures'
  const isMoleculesPage = location.pathname
    .split('/')
    .includes(MOLECULES_LIST_PATH)

  const isInputDisabled =
    isSearchPage && filtersMatchType === 'markush structures'

  const isStandartSearchBar = !(
    location.pathname.split('/').includes(MOLECULES_LIST_PATH) ||
    isSynthcostPage
  )
  const smileSize = getSmileSize(isStandartSearchBar)
  const smileSizeLarge = getSmileSizeLarge(isStandartSearchBar)

  const debouncedInputValue = useDebounce(
    isSynthcostPage || isReactionsPage ? inputValue : text,
    200
  )
  const { availableOptionsResult: prevSearchResults } = useMemo(() => {
    if (!Array.isArray(searchHistory) || !searchHistory?.length) return []

    const isStructureSearch = searchType === 'structure'
    const isLiteratureSearch = searchType === 'literature'
    const isReactionsSearch = searchType === 'reaction'
    const availableOptionsRaw = searchHistory

      ?.filter((option) => {
        if (isLiteratureSearch) {
          return (
            option?.query?.type === 'full_text_search' ||
            option?.query?.type === 'advanced_text_search'
          )
        }
        if (isStructureSearch) {
          return (
            option?.query?.type === 'molecules_search' ||
            option?.query?.type === 'bingo_search'
          )
        }
        if (isReactionsSearch) {
          return (
            option?.query?.type === 'molecules_search' || //оч предварительно эти опции Паша предположил в чате, может и нет
            option?.query?.type === 'global_search'
          )
        }
        return option
      })
      ?.slice(0, 20)

    const availableOptionsMapped = availableOptionsRaw?.map((option) => {
      if (isStructureSearch) {
        const { smiles, text } = option?.query?.params?.searchV2 || {}
        const smilesToUse = smiles || option?.query?.params?.smiles || ''

        return {
          id: option?.id,
          smiles: smilesToUse,
          name: text ?? '',
          label: smilesToUse || text,
        }
      }
      if (isLiteratureSearch) {
        let smiles = ''
        let titleToUse = ''
        let abstractToUse = ''

        // old lit search
        if (option?.query?.type === 'full_text_search') {
          const { title, abstract } = option?.query?.params || {}
          titleToUse = title?.values?.[0] ?? ''
          abstractToUse = abstract?.values?.[0] ?? ''
        } else {
          // new lit search
          const params = option?.query?.params

          if (params?.structures_ids) {
            smiles = params.structures_ids.smiles_list[0]
          } else {
            let convertedParams = {}
            const arr = params?.query?.and_operator || []

            arr.forEach((param) => {
              const key = Object.keys(param)[0]
              const values = []

              const operator = convertNewOperatorToOld(key)
              const conditions = param[key]

              const { fields } = getField(conditions)
              fields.forEach((field, index) => {
                if (field === 'title' || field === 'abstract') {
                  convertedParams[field] = {
                    operator,
                    exact: false,
                    values,
                  }
                }
              })

              conditions.forEach((condition) => {
                values.push(
                  condition[INNER_LANG_OPERATOR]
                    ? condition[INNER_LANG_OPERATOR][0]?.value
                    : condition?.value
                )
              })
            })
            const { title, abstract } = convertedParams || {}
            titleToUse = title?.values?.[0] ?? ''
            abstractToUse = abstract?.values?.[0] ?? ''
          }
        }

        return {
          id: option?.id,
          smiles: '',
          name: smiles || titleToUse || abstractToUse || '',
          label: smiles || titleToUse || abstractToUse || '',
        }
      }
      if (isReactionsSearch) {
        //тут пока не ясно что это за опции и что прокидываем
        const { title, abstract } = option?.query?.params || {}
        const titleToUse = title?.values?.[0] ?? ''
        const abstractToUse = abstract?.values?.[0] ?? ''
        return {
          id: option?.id,
          smiles: '',
          name: titleToUse || abstractToUse || '',
          label: titleToUse || abstractToUse || '',
        }
      }
    })
    const uniqueLabels = new Set()
    const availableOptionsResult = availableOptionsMapped.filter((item) => {
      const isDuplicate = uniqueLabels.has(item?.label)
      uniqueLabels.add(item?.label)
      return !!item.label && !isDuplicate
    })
    return { availableOptionsResult }
  }, [searchHistory, searchType])

  const searchedMolecule = useMemo(() => {
    const searchResultToUse = searchV2.result[0] || bingoSearch.result[0]
    if (
      filtersMatchType === 'substructural search' ||
      filtersMatchType === 'similar structures'
    )
      return { smiles: text } ?? searchResultToUse

    return searchResultToUse
  }, [bingoSearch.result, filtersMatchType, searchV2.result, text])

  const smilesForKetcher = useMemo(() => {
    if (drawnSmiles) return drawnSmiles
    if (searchedMolecule && filtersMatchType !== 'markush structures')
      return searchedMolecule.smiles
    return text || inputValue
  }, [drawnSmiles, searchedMolecule, text, inputValue, filtersMatchType])
  /** INIT - END */
  /** METHODS - START */
  const handleChange = (msg) => {
    !inputChanges && setInputChanges(true)

    // сброс id в лит поиске при изменении текста
    if (searchType === 'literature' && literatureSyntellyId)
      setLiteratureSyntellyId(null)

    if (
      searchType === 'literature'
        ? validateEnglishCyrillicInput(msg) || !msg
        : // для приватных датасетов даем вводить русские буквы, поскольку alias может быть на русском
          (basket && !isPublic
            ? validateNotStarSymbol(msg)
            : validateEnglishInput(msg) && validateNotStarSymbol(msg)) || !msg
    ) {
      isSynthcostPage || isReactionsPage
        ? onInputChange(msg)
        : setSearchText(msg)
    }
    if (
      searchType !== 'literature' &&
      !(basket && !isPublic) &&
      !validateEnglishInput(msg) &&
      msg
    ) {
      if (notificationsList.find((el) => notificationIds.includes(el.id)))
        return
      const id = uuidv4()

      const notify = {
        id,
        name: 'notification.not_possible_search',
        text: 'notification.switch_language',
        notification_type: 'warning',
        autoRemove: true,
      }

      setNotificationIds((prev) => [...prev, id])
      addNotification(notify)
      setTimeout(
        () => setNotificationIds((prev) => [...prev.filter((el) => el !== id)]),
        5000
      )
    }

    if (
      searchType !== 'literature' &&
      filtersMatchType !== 'markush structures' &&
      !validateNotStarSymbol(msg)
    ) {
      if (notificationsList.find((el) => notificationIds.includes(el.id)))
        return
      const id = uuidv4()

      const notify = {
        id,
        name: 'notification.not_possible_star',
        text: 'notification.remove_star',
        notification_type: 'warning',
        autoRemove: true,
      }

      setNotificationIds((prev) => [...prev, id])
      addNotification(notify)
      setTimeout(
        () => setNotificationIds((prev) => [...prev.filter((el) => el !== id)]),
        5000
      )
    }
  }

  const handlePointerIn = () => setShowOptions(true) //on focus
  const handlePointerOut = () => setShowOptions(false)

  const loadQuickSearch = async (text) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel()
    }
    const source = axios.CancelToken.source()
    setCancelTokenSource(source)
    const url =
      !!basket && !isPublic
        ? `molecule/alias_search/${basket}`
        : 'molecule/global_search'
    try {
      const { data } = await storeV2(
        url,
        {
          params: {
            quick: true,
            query: text,
          },
        },
        {},
        { cancelToken: source.token }
      )
      const quick_search_data = data?.result?.items || []
      if (Array.isArray(quick_search_data)) {
        if (!text) return setQuickSearchOptions([])
        setQuickSearchOptions(quick_search_data)
        !filtersDisabled && inputRef?.current?.focus()
      }
    } catch (e) {
      if (axios.isCancel(e)) return
      setQuickSearchOptions([])
      console.log(e)
    } finally {
      setCancelTokenSource(null)
    }
  }

  const loadOptions = async (text) => {
    loadQuickSearch(text)
  }

  const handleDraw = async (smiles) => {
    if (smiles) {
      if (literatureSyntellyId) setLiteratureSyntellyId(null)
      setDrawnSmiles(smiles)
      isReactionsPage ? onInputChange(smiles) : setSearchText(smiles)
      loadOptions(smiles)
      !inputChanges && setInputChanges(true)
    }
  }

  const handleReset = () => {
    if (filtersDisabled) return

    setQuickSearchOptions([])
    if (drawnSmiles) setDrawnSmiles('')
    if (!(isReactionsPage || isSynthcostPage)) {
      setSearchText('')
      resetSearch()
      resetLitSearch()
      resetReactionsSearch()
      setReactionsCompoundId(null)
      setLiteratureSyntellyId(null)
      dispatch(resetFilter())
      searchType === 'literature' && resetSearchSortingConfig()
    } else {
      onResetSearch()
    }
  }

  const selectFromQuickSearch = ({ id, smiles, name, label }) => {
    if (isSynthcostPage || isReactionsPage) {
      onSelectOption(smiles)
    } else {
      setSearchText(name)
      quickSearchSelectedRef.current = name
      if (searchType === 'structure') {
        if (id !== 0) {
          dispatch(setSearchV2({ ids: [id], label, name }))
        } else {
          dispatch(setSearchV2({ smiles, label, name }))
          searchType === 'literature' && setInputChanges(true)
        }
        dispatch(setOpenFilter(true))
        setShowOptions(false)
      }
      if (searchType === 'literature') {
        id !== 0 && setLiteratureSyntellyId(id)
        dispatch(setLiteratureSearch({}))
        dispatch(setOpenFilter(true))
      }
      if (searchType === 'reaction') {
        dispatch(setOpenFilter(true))
        dispatch(setReactionsCompoundId(id))
        dispatch(setReactionsSearch({ compound_id: id }))
      }
    }
  }

  const handleSearch = async (propText) => {
    if (!(text || inputValue || propText)) return
    const textToUse = text || propText
    inputChanges && setInputChanges(false)

    if (isSearchPage) {
      resetSearch()
      resetPagination()
    }
    setShowOptions(false)
    if (!isReactionsPage) {
      if (searchType === 'structure') {
        // 2.07.24 commented out until the inputs are prepared
        // if (filtersMatchType === 'exact match' && !isMoleculesPage) {
        if (filtersMatchType === 'exact match') {
          isNumeric(textToUse)
            ? dispatch(setSearchV2({ ids: [textToUse] }))
            : dispatch(setSearchV2({ text: textToUse, label: textToUse }))
        }
        if (
          filtersMatchType === 'substructural search' ||
          filtersMatchType === 'similar structures' ||
          filtersMatchType === 'markush structures'
        ) {
          dispatch(
            setBingoSearch({
              filtersMatchType,
              text: textToUse,
              label: textToUse,
              similarity,
            })
          )
        }
        // 2.07.24 commented out until the inputs are prepared
        // if (filtersMatchType === 'exact match' && isMoleculesPage) {
        //   const validateSmiles = await isValidSMILES(text?.toString())
        //   if (!validateSmiles) {
        //     const id = uuidv4()
        //     const notify = {
        //       id,
        //       name: 'notification.error',
        //       notification_type: 'error',
        //       text: 'pdf2smiles.viewer.invalid_smiles',
        //       autoRemove: true,
        //       timeout: 5000,
        //     }
        //     addNotification(notify)
        //     return
        //   }
        //   dispatch(setSearchV2({ text, label: text }))
        // }
      }
      if (searchType === 'literature' && litSearch.status !== 'loading') {
        dispatch(setLiteratureSearch({}))
      }
      if (searchType === 'reaction' && reactionsSearch.status !== 'loading') {
        dispatch(setReactionsSearch({ text: textToUse }))
      }
      !filtersOpen && isSearchPage && dispatch(setOpenFilter(true))
    } else {
      onSearchClick && onSearchClick()
    }
  }

  const handleKeyDown = (key) => {
    if (key === 'Enter' && !filtersDisabled && !isSynthcostPage) handleSearch()
  }

  const handleSelectSearchType = (value) => {
    handleReset()
    setSearchType(value)
    if (value === 'literature')
      dispatch(setLeftFilterLitDocType(['article', 'patent']))
    if (value === 'reaction')
      dispatch(setLeftFilterLitDocType(['article', 'patent']))
  }

  const handleMouseMove = (e) => {
    e.stopPropagation()
    setCursorX(e.nativeEvent.offsetX)
    setCursorY(e.nativeEvent.offsetY)
  }

  const handleAddNotificationAfterClear = () => {
    if (!isSearchPage) return

    const notify = {
      id: uuidv4(),
      name: 'notification.search_query_removed',
      notification_type: 'cancel',
      autoRemove: true,
      params: {
        actionType: 'searchQuery',
        text,
        drawnSmiles,
        filterConfig,
        searchType,
        litFilterConfig,
        searchV2,
        bingoSearch,
        litSearch,
        pagination,
        sortingConfig,
      },
    }

    addNotification(notify)
  }

  const getSearchPlaceholder = () => {
    if (isSynthcostPage) return 'search.placeholder_synth'
    if (isReactionsPage) return 'search.placeholder_short'
    if (searchType === 'literature') return 'search.placeholder_lit'
    return 'search.placeholder'
  }

  const getSearchButtonText = () => {
    if (isReactionsPage) return 'search.predict'
    return 'search.search'
  }

  const handleRepeatSearch = ({ label }) => {
    setSearchText(label)
    handleSearch(label)
  }

  const textToParse = isReactionsPage
    ? inputValue
    : isMarkushSearch
    ? text.slice(0, text.indexOf('|')).trim()
    : text
  /** METHODS - END */

  /** EFFECTS - START */
  useEffect(() => {
    return () => {
      if (
        history.action === 'PUSH' &&
        history.location.pathname === '/search' &&
        searchType === 'literature'
      ) {
        return
      }
      isReactionsPage ? setDrawnSmiles('') : handleReset()
      setSearchText('')
      if (
        !isReactionsPage &&
        !isSynthcostPage &&
        !history?.location?.state?.prevPath?.includes('molecule/')
      ) {
        setSearchType('structure')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      quickSearchSelectedRef.current === debouncedInputValue ||
      debouncedInputValue?.includes('|') ||
      !inputChanges ||
      ((isSynthcostPage || isReactionsPage) &&
        quickSearchOptions.some(({ smiles }) => smiles === debouncedInputValue))
    )
      return //от повторного открытия опций/срабатывания эффекта после выбора из списка синонимов

    if (
      (basket && !isPublic) ||
      validateEnglishInput(debouncedInputValue) ||
      !debouncedInputValue
    ) {
      loadOptions(debouncedInputValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue])

  useEffect(() => {
    if (
      !text &&
      !isReactionsPage &&
      !isSynthcostPage &&
      // need to deprecate!!
      !isMarkushSearch &&
      !filtersMatchType === 'markush structures'
    ) {
      resetSearch()
      resetLitSearch()
      resetReactionsSearch()
      setReactionsCompoundId(null)
      setLiteratureSyntellyId(null)
      !!drawnSmiles && setDrawnSmiles('')
      dispatch(setOpenFilter(false))
    }
    if (isMarkushSearch && filtersMatchType === 'markush structures') {
      !!drawnSmiles && setDrawnSmiles('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, text, isReactionsPage, isSynthcostPage, drawnSmiles])
  /** EFFECTS - END */

  const handleClear = () => {
    inputRef?.current?.focus()
    setShowOptions(false)
    handleAddNotificationAfterClear()
    handleReset()
    resetLitSearch()
    setLitFilterConfig({})
  }

  return (
    <Search id="search">
      {isSearchPage && (
        <SearchTypes>
          {searchTypes?.map(({ name, value }) => {
            const id = `search-tab-${value}`
            return (
              <SearchType key={id}>
                <CustomTab
                  isActive={searchType === value}
                  onClick={() => {
                    handleSelectSearchType(value)
                  }}
                  disabled={bingoSearch.isLongTask}
                  type={'search'}
                  size={'xs'}
                  data-tooltip-id={id}
                >
                  {t(name)}
                </CustomTab>
                <ReactTooltip
                  id={id}
                  className="c-tooltip c-tooltip-base"
                  classNameArrow="c-tooltip-arrow"
                  place={'bottom-start'}
                  hidden={!bingoSearch.isLongTask}
                  offset={14}
                >
                  <SearchTypeTooltip>
                    {t('search.structure_search_in_progress')}
                  </SearchTypeTooltip>
                </ReactTooltip>
              </SearchType>
            )
          })}
        </SearchTypes>
      )}
      <SearchPanel>
        <SearchBlock
          focused={showOptions || text || inputValue}
          isStandartSearchBar={isStandartSearchBar}
          withWhiteBackground={withWhiteBackground}
          hiddenDraw={isSynthcostPage}
          isInputDisabled={isInputDisabled}
        >
          <SearchBtns
            exists={isSmilesDrawn}
            isStandartSearchBar={isStandartSearchBar}
            disabled={filtersDisabled}
            hidden={isSynthcostPage}
            onClick={setKetcher}
          >
            <SearchButton isStandartSearchBar={isStandartSearchBar}>
              <Icon
                iconType={isSmilesDrawn ? 'edit' : 'molecularEditor'}
                size={'1rem'}
              />
              <TextButtonMedium>
                {isSmilesDrawn ? t('search.edit') : t('search.draw')}
              </TextButtonMedium>
            </SearchButton>
            <DrawnMoleculeContainer
              exists={isSmilesDrawn}
              isStandartSearchBar={isStandartSearchBar}
              id="draw-molecule-container-small"
            >
              <MoleculeStructure
                structure={textToParse}
                width={smileSize?.width}
                height={smileSize?.height}
                hideEmpty
                onRender={() => setIsSmilesDrawn(true)}
                onClear={() => setIsSmilesDrawn(false)}
                className="drawn-molecule-structure-small"
              />
            </DrawnMoleculeContainer>
            <DrawnMoleculeContainerLarge className="drawn-molecule-large">
              <div>
                <div>
                  <MoleculeStructure
                    structure={textToParse}
                    width={smileSizeLarge?.width}
                    height={smileSizeLarge?.height}
                    hideEmpty
                    fixedContainerSize={false}
                    className="drawn-molecule-structure-large"
                    fixedCanvasHeight={false}
                  />
                </div>
              </div>
            </DrawnMoleculeContainerLarge>
          </SearchBtns>

          <InputBlockWrapper
            onMouseMove={(e) => isInputDisabled && handleMouseMove(e)}
            onMouseEnter={() => isInputDisabled && setIsTooltipVisible(true)}
            onMouseLeave={() => isInputDisabled && setIsTooltipVisible(false)}
            isStandartSearchBar={isStandartSearchBar}
          >
            <CustomInput
              value={!(isSynthcostPage || isReactionsPage) ? text : inputValue}
              onChange={handleChange}
              onFocus={handlePointerIn}
              onBlur={handlePointerOut}
              ref={inputRef}
              onKeyDown={({ key }) => handleKeyDown(key)}
              placeholder={t(getSearchPlaceholder())}
              withWhiteBackground={withWhiteBackground}
              disabled={isInputDisabled}
              spellCheck={false}
              withClearButton={
                !bingoSearch.isLongTask &&
                (text || inputValue) &&
                !filtersDisabled &&
                litSearch.status !== 'loading'
              }
              onClear={() => {
                inputRef?.current?.focus()
                setShowOptions(false)
                handleAddNotificationAfterClear()
                handleReset()
                resetLitSearch()
                setLitFilterConfig({})
              }}
              clearBtnProps={{
                onClear: handleClear,
                id: 'main-search-bar-cross-button',
              }}
              withLargeText={isStandartSearchBar}
              padding="0.25rem 0.25rem 0.25rem 0"
            />
            {isInputDisabled &&
              isTooltipVisible &&
              cursorX !== 0 &&
              cursorY !== 0 && (
                <TooltipText cursorX={cursorX} cursorY={cursorY}>
                  {t('search.draw_markush')}
                </TooltipText>
              )}
          </InputBlockWrapper>

          {bingoSearch.isLongTask && (
            <LongTaskBlock>
              <Icon
                iconType="spinner"
                color={'#42C1BF'}
                className={'spinner-icon'}
              />
              <Subheadline color={'#42C1BF'}>
                {t('search.search_may_take_some_time')}
              </Subheadline>
            </LongTaskBlock>
          )}

          {showOptions &&
            (text || inputValue) &&
            quickSearchOptions.length > 0 && (
              <VariantBlock
                data={quickSearchOptions}
                handleSelect={selectFromQuickSearch}
                withIcon={true}
                iconType="logo"
              />
            )}

          {showOptions &&
            !text &&
            !inputValue &&
            prevSearchResults?.length > 0 &&
            isSearchPage && (
              <VariantBlock
                data={prevSearchResults}
                handleSelect={handleRepeatSearch}
                withIcon={true}
                iconType="repeat"
                withMaxHeight={true}
                className="history-variant-block"
              />
            )}
        </SearchBlock>
        {!bingoSearch.isLongTask &&
          !isSynthcostPage &&
          !isMoleculesPage &&
          !isReactionsPage && (
            <CustomButton
              type="accent"
              onClick={handleSearch}
              id="main-search-bar-search-button"
              size="big"
            >
              {t('search.search')}
            </CustomButton>
          )}
        {(isReactionsPage || isMoleculesPage) && (
          <CustomButton
            type="accent"
            onClick={text || inputValue ? handleSearch : () => {}}
            id="main-search-bar-search-button"
            minWidth="4.75rem"
            size={isReactionsPage ? 'big' : 'small'}
            disabled={isReactionsPage && reactionsLoading}
          >
            {t(getSearchButtonText())}
          </CustomButton>
        )}
      </SearchPanel>
      <SmallBtnsContainer exists={isSmilesDrawn}>
        {isSearchHidden &&
          !!text &&
          !bingoSearch.isLongTask &&
          isSmilesDrawn && (
            <SmallBtns>
              <SmallEditBtn onClick={setKetcher} id="search-edit-btn">
                <Icon iconType="edit" size="1.25rem" />
              </SmallEditBtn>
              <SmallDeleteBtn
                onClick={() => {
                  handleAddNotificationAfterClear()
                  handleReset()
                }}
                id="search-trash-btn"
              >
                <Icon iconType="deleted" size="1.25rem" />
              </SmallDeleteBtn>
            </SmallBtns>
          )}
        <DrawnMoleculeContainerLarge className="drawn-molecule-right">
          <div>
            <div>
              <MoleculeStructure
                structure={textToParse}
                width={smileSizeRight?.width}
                height={smileSizeRight?.height}
                hideEmpty
                className="drawn-molecule-structure-large"
              />
            </div>
          </div>
        </DrawnMoleculeContainerLarge>
      </SmallBtnsContainer>
      {ketcher && !filtersDisabled && (
        <Ketcher
          handleData={handleDraw}
          closeKetcher={() => setKetcher(false)}
          smiles={smilesForKetcher}
        />
      )}
    </Search>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    filterOpen: state.filter.open,
    filterConfig: state.filter.config,
    searchType: state.search.searchType,
    searchHistory: state.searchHistory.history,
    isSearchHidden: ownProps.isSearchHidden,
    bingoSearch: state.crud.bingoSearch,
    similarity: state.filter.config.similarity,
    text: state.search.searchText,
    list: state.reactions.list,
    litSearch: state.crud.litSearch,
    litFilterConfig: state.literature.filter.config,
    pagination: state.search.pagination,
    drawnSmiles: state.search.drawnSmiles,
    sortingConfig: state.search.sorting,
    notificationsList: state.notifications.notificationsList,
    reactionsSearch: state.crud.reactionsSearch,
    literatureSyntellyId: state.literature.syntellyId,
  }
}

const mapDispatchToProps = {
  resetFilter,
  setSearchType,
  setSearchText,
  resetLitSearch,
  resetReactionsSearch,
  resetSearch,
  resetPagination,
  addNotification,
  setDrawnSmiles,
  resetSearchSortingConfig,
  setLitFilterConfig,
  setReactionsCompoundId,
  setLiteratureSyntellyId,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSearchBar)
