import { Fragment, memo, useState } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import AccSvg from 'assets/accountSvg'
import CompanySvg from 'assets/companySvg'
import CustomTag from 'components/common/customTag'
import { Headline, Label } from 'components/common/text/index.style'
import SkeletonComponent from 'components/common/skeleton'

import { Line } from '../index.style'
import { getLogConfig } from '../utils/utils'
import { DATASET_LOGS_PREFIX } from '../config/config'
import { Divider, Log, LogsListContainer, Tags } from './index.style'

const LogsList = memo(
  ({ logs, status, basketType, isCompanyDatasetsLogging }) => {
    const theme = useTheme()
    const { t } = useTranslation()

    const [expandedStructures, setExpandedStructures] = useState([])

    const toggleStructures = (id) =>
      expandedStructures?.includes(id)
        ? setExpandedStructures((prev) => prev.filter((el) => el !== id))
        : setExpandedStructures((prev) => [...prev, id])

    if (status === 'loading')
      return (
        <LogsListContainer isCompanyDatasetsLogging={isCompanyDatasetsLogging}>
          {[...new Array(3)].map((_, i) => (
            <Fragment key={i}>
              <SkeletonComponent width="100%" height="4rem" variant="grey" />
              {i < 2 && (
                <Divider type={isCompanyDatasetsLogging ? 'white' : 'grey'} />
              )}
            </Fragment>
          ))}
        </LogsListContainer>
      )

    if (status === 'success' && !logs.length)
      return (
        <Headline fontWeight={theme.fontWeight.semibold}>
          {t(`${DATASET_LOGS_PREFIX}history_is_empty`)}
        </Headline>
      )

    return (
      <LogsListContainer isCompanyDatasetsLogging>
        {logs?.map((log, i, arr) => {
          const { title, title2, config, failed_molecules } = getLogConfig(
            log,
            theme,
            toggleStructures,
            expandedStructures
          )
          return (
            <Fragment key={i}>
              {basketType === 'companies' && (
                <Tags>
                  {log?.user?.role === 'admin' ? (
                    <CustomTag
                      type={isCompanyDatasetsLogging ? 'white' : 'grey'}
                    >
                      <CompanySvg
                        size="1rem"
                        mainColor={theme.colors.statuses.link}
                        secondaryColor={theme.colors.statuses.linkHover}
                      />
                      {t(`${DATASET_LOGS_PREFIX}admin_short`)}
                    </CustomTag>
                  ) : (
                    <CustomTag
                      type={isCompanyDatasetsLogging ? 'white' : 'grey'}
                    >
                      <AccSvg
                        size="1rem"
                        mainColor={theme.colors.statuses.link}
                        secondaryColor={theme.colors.statuses.link}
                        headColor={theme.colors.icons.accentPrimary}
                        bodyColor={theme.colors.icons.accentSecondary}
                      />
                      {`${log?.user?.name || ''} ${log?.user?.surname || ''} `}
                    </CustomTag>
                  )}
                </Tags>
              )}
              <Log key={i}>
                <Label
                  ellipsis={log?.request_type !== 'join'}
                  fontWeight={theme.fontWeight.semibold}
                >
                  {title}{' '}
                </Label>
                {!!title2 && (
                  <Label ellipsis fontWeight={theme.fontWeight.semibold}>
                    {title2}{' '}
                  </Label>
                )}
                {config.map(
                  ({ name, value, show }, i) =>
                    show && (
                      <div key={`${title}-${name}-${i}`}>
                        <Line>
                          <Label>
                            {name}
                            {':'}
                          </Label>

                          {value}
                        </Line>
                        {expandedStructures.includes(log.id) &&
                          name ===
                            t(
                              `${DATASET_LOGS_PREFIX}rejected_structures_count`
                            ) && <Label>{failed_molecules.join(' , ')}</Label>}
                      </div>
                    )
                )}
              </Log>
              {i !== arr.length - 1 && (
                <Divider type={isCompanyDatasetsLogging ? 'white' : 'grey'} />
              )}
            </Fragment>
          )
        })}
      </LogsListContainer>
    )
  }
)

LogsList.displayName = 'LogList'

export default LogsList
