import { useMemo, useRef } from 'react'
import { scaleLinear } from '@visx/scale'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { GridRows, GridColumns } from '@visx/grid'
import { withResizeDetector } from 'react-resize-detector'
import { getX, getY, bisect, numZeroesAfterPoint } from '../helpers'
import { max, extent } from 'd3-array'
import { localPoint } from '@visx/event'
import { ChartSvg, InfraTooltipData } from '../index.style'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { AreaClosed, Line, Bar } from '@visx/shape'
import { LinearGradient } from '@visx/gradient'
import {
  defaultStyles,
  useTooltip,
  useTooltipInPortal,
  withTooltip,
} from '@visx/tooltip'
import i18n from 'i18n'
import { useTheme } from 'styled-components'
import { SPECTRA_INFRARED_CHART } from '../config'

const InfraredChart = ({ data, width = 84 }) => {
  const theme = useTheme()
  const ICONS_SEC = theme.colors.icons.secondary
  const TEXT_PRIMARY = theme.colors.text.primary
  const TEXT_ACCENT = theme.colors.text.accentPrimary
  const BACK_SEC = theme.colors.backgrounds.secondary
  const maxVals = max(data)
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    showTooltip,
    hideTooltip,
  } = useTooltip()
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  })

  const chartRef = useRef(null)
  const tooltipBaseLeftOffset = i18n.language === 'en' ? 78 : 100
  const getData = useMemo(() => {
    const preparedData = []
    data.forEach((el, index) =>
      preparedData.push({ x: index * 2 + 400, y: el })
    )

    return preparedData
  }, [data])

  const { t } = useTranslation()

  const height = 356
  const margin = { top: 48, right: 24, bottom: 40, left: 60 }

  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const xScale = scaleLinear({
    domain: extent(getData, (d) => d.x).reverse(),
    range: [margin.left, width - margin.right],
  })

  const yScale = scaleLinear({
    domain: [0, maxVals * 1.2],
    range: [height - margin.bottom, margin.top],
    nice: true,
  })

  const handleTooltip = (event) => {
    const svgRects = chartRef.current.getBoundingClientRect()
    const { x } = localPoint(event) || { x: 0 }
    const xValue = xScale.invert(x)
    const index = bisect(getData, xValue, 1)
    const newY = data[index]

    if (
      window.innerHeight >
      svgRects.top + svgRects.height - (height - yScale(newY))
    ) {
      showTooltip({
        tooltipData: {
          x: index * 2 + 400,
          y: newY,
          leftOffset: window.innerWidth - event.clientX - tooltipBaseLeftOffset,
        },
        tooltipLeft: x,
        tooltipTop: yScale(newY),
      })
    } else {
      hideTooltip()
    }
  }

  return (
    <div ref={containerRef}>
      <ChartSvg
        width={width}
        height={height}
        ref={chartRef}
        id={SPECTRA_INFRARED_CHART}
      >
        <GridRows
          scale={yScale}
          left={margin.left}
          width={width - margin.left - margin.right}
          numTicks={4}
          stroke={BACK_SEC}
          strokeWidth={1}
          strokeOpacity={1}
        />
        <GridColumns
          scale={xScale}
          top={height - margin.bottom}
          height={-height + margin.top + margin.bottom}
          numTicks={7}
          stroke={BACK_SEC}
          strokeWidth={1}
          strokeOpacity={1}
          tickValues={xScale.ticks()}
        />
        <rect
          x={width - margin.right}
          y={margin.top}
          width={1}
          height={height - margin.top - margin.bottom}
          fill={BACK_SEC}
        />
        <rect
          x={margin.left}
          y={margin.top}
          width={width - margin.left - margin.right}
          height={1}
          fill={BACK_SEC}
        />
        <LinearGradient
          id="area-gradient"
          from={TEXT_ACCENT}
          to={TEXT_ACCENT}
          fromOpacity={1}
          toOpacity={0.2}
        />
        <AreaClosed
          data={getData}
          x={(d) => xScale(getX(d))}
          y={(d) => yScale(getY(d))}
          yScale={yScale}
          strokeWidth={0}
          stroke="url(#area-gradient)"
          fill="url(#area-gradient)"
        />
        <Bar
          x={margin.left}
          y={margin.top}
          width={innerWidth}
          height={innerHeight}
          fill="transparent"
          rx={14}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />
        <AxisBottom
          scale={xScale}
          top={height - margin.bottom}
          numTicks={width < 900 ? 6 : 12}
          tickFormat={(value) => value}
          stroke={ICONS_SEC}
          tickStroke={ICONS_SEC}
          tickLabelProps={(el) => {
            return {
              fill: ICONS_SEC,
              fontSize: 11,
              textAnchor: el === 400 ? 'end' : 'start',
              fontWeight: 400,
              fontFamily: 'Geologica Cursive',
              lineHeight: 14,
              dy: 2,
            }
          }}
          label={t('spectra.chart.wavenumber')}
          labelProps={{
            fontSize: 11,
            lineHeight: 14,
            fontWeight: 400,
            fill: TEXT_PRIMARY,
            fontFamily: 'Geologica Cursive',
            textAnchor: 'middle',
            x: width / 2,
          }}
        />
        <AxisLeft
          scale={yScale}
          left={margin.left}
          numTicks={4}
          tickFormat={(value) =>
            value === 0 ? value : value.toFixed(numZeroesAfterPoint(value) + 2)
          }
          stroke={ICONS_SEC}
          strokeWidth={1}
          tickLabelProps={() => ({
            fill: ICONS_SEC,
            fontSize: 11,
            textAnchor: 'start',
            fontWeight: 400,
            fontFamily: 'Geologica Cursive',
            lineHeight: 14,
            angle: -90,
            dx: -4,
          })}
          label={t('spectra.chart.i_intensity')}
          labelOffset={20}
          labelProps={{
            fontSize: 11,
            lineHeight: 14,
            fontWeight: 400,
            fill: TEXT_PRIMARY,
            textAnchor: 'middle',
            x: -height / 2 + 24,
            dx: -4,
          }}
        />{' '}
        {tooltipOpen && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: margin.top }}
              to={{ x: tooltipLeft, y: innerHeight + margin.top }}
              stroke="#CBD2D9"
              strokeWidth={1}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
            <Line
              from={{ x: margin.left, y: tooltipTop }}
              to={{ x: width - margin.right, y: tooltipTop }}
              stroke="#CBD2D9"
              strokeWidth={1}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={4}
              fill={TEXT_ACCENT}
              pointerEvents="none"
            />
          </g>
        )}
        {tooltipOpen && (
          <TooltipInPortal
            top={tooltipTop - 100}
            left={
              tooltipData.leftOffset < 0
                ? tooltipLeft - (tooltipBaseLeftOffset - tooltipData.leftOffset)
                : tooltipLeft - tooltipBaseLeftOffset
            }
            key={Math.random()}
            className="custom-visx-tooltip"
            style={{
              ...defaultStyles,
              zIndex: '10000',
              padding: '0px 12px 0px 12px',
              width: i18n.language === 'en' ? '180px' : '200px',
              height: '60px',
              borderRadius: '12px',
              background: 'rgba(245, 247, 247, 0.60)',
              backdropFilter: 'blur(40px)',
              boxShadow:
                '0px 0px 3px 0px rgba(31, 41, 51, 0.04), 0px 0px 4px 0px rgba(31, 41, 51, 0.06), 0px 0px 10px 0px rgba(31, 41, 51, 0.14)',
            }}
          >
            {' '}
            <InfraTooltipData>
              <div>
                <p>{t('spectra.chart.intensity')}:</p>
                <p>{tooltipData.y.toFixed(5)}</p>
              </div>
              <div>
                <p>{t('spectra.chart.wavenumber')}:</p>
                <p>{tooltipData.x}</p>
              </div>
            </InfraTooltipData>
          </TooltipInPortal>
        )}
      </ChartSvg>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    data: state.spectra.infrared.data,
  }
}

export default connect(mapStateToProps)(
  withTooltip(withResizeDetector(InfraredChart))
)
