import { useMemo } from 'react'
import { NmrChartCard } from './index.style'
import { ChartCard, ChartHeader } from 'pages/SpectraPrediction/index.style'
import NmrTable from './nmrTable'
import NmrChart from './nmrChart'
import HydroChart from './HydroChart'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Headline } from 'components/common/text/index.style'
import { useTheme } from 'styled-components'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import { generatePdfForNMR } from 'pages/SpectraPrediction/helpers'
import {
  SPECTRA_NMR_CHART,
  SPECTRA_NMR_TABLE,
} from 'pages/SpectraPrediction/config'

const NmrChartsBlock = ({
  nmrData,
  nmrMethod,
  smiles,
  image,
  notifyDownloadStarted,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const shiftValues = nmrData.shifts

  // find all shift duplicates
  const duplicates = useMemo(() => {
    const dupl = {}
    shiftValues.forEach((el, index) => {
      if (dupl[el]) dupl[el] = [...dupl[el], index]
      else dupl[el] = [index]
    })
    return dupl
  }, [shiftValues])

  return (
    <ChartCard>
      <ChartHeader>
        <Headline color={theme.colors.text.primary}>
          {t('spectra.calculated_title')}
        </Headline>
        <CustomButton
          type="text"
          gap="0.25rem"
          onClick={() => {
            notifyDownloadStarted()
            generatePdfForNMR({
              chartId: SPECTRA_NMR_CHART,
              tableId: SPECTRA_NMR_TABLE,
              smiles,
              method: nmrMethod.label,
              image,
              tableRowQty: shiftValues?.length + 1 || 0,
            })
          }}
        >
          {t('spectra.buttons.download')}
          <Icon iconType="upload" size="1rem" />
        </CustomButton>
      </ChartHeader>

      {nmrMethod.value !== 'hydrogen' ? (
        <NmrChartCard>
          <NmrChart duplicates={duplicates} />
          <NmrTable duplicates={duplicates} />
        </NmrChartCard>
      ) : (
        <NmrChartCard>
          <HydroChart duplicates={duplicates} />
          <NmrTable duplicates={duplicates} />
        </NmrChartCard>
      )}
    </ChartCard>
  )
}

const mapStateToProps = (state) => {
  return {
    nmrMethod: state.spectra.nmr.method,
    nmrData: state.spectra.nmr.data,
  }
}

export default connect(mapStateToProps)(NmrChartsBlock)
