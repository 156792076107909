import { memo, useMemo } from 'react'
import { connect } from 'react-redux'

import {
  setBingoSearch,
  loadMoreMolecules,
  setLiteratureSearch,
  setReactionsSearch,
} from 'store/actions/crud'
import Icon from 'components/Icon'
import { setPaginationConfig } from 'store/actions/search'

import {
  IndicatorBtn,
  PaginationBtn,
  PaginationBtns,
  PaginationBlock,
} from './index.style'
import {
  MIDDLE_RANGE_COUNT,
  PAGINATION_SIBLINGS_COUNT,
  PAGINATION_VISIBLE_PAGES_COUNT,
} from '../config/config'
import { getPagesRange } from '../utils/utils'
import { useLocation } from 'react-router-dom'
import {
  getCurrentCustomerUsers,
  getCustomers,
  getUsers,
  setUsersPaginationConfig,
  setCurrentCustomerUsersPaginationConfig,
  setCustomersPaginationConfig,
  getApplications,
  setApplicationsPaginationConfig,
} from 'store/actions/adminPanel'
import { readStorage } from 'utils/storage/storage'
import {
  getFeedback,
  setFeedbackPaginationConfig,
} from 'store/actions/feedback'

const Pagination = memo(
  ({
    basket,
    litTaskId,
    pagination,
    pastResult,
    bingoTaskId,
    setBingoSearch,
    usersPagination,
    loadMoreMolecules,
    setLiteratureSearch,
    setPaginationConfig,
    customersPagination,
    applicationsPagination,
    setUsersPaginationConfig,
    setCustomersPaginationConfig,
    setCurrentCustomerUsersPaginationConfig,
    setApplicationsPaginationConfig,
    currentCustomerUsersPag,
    pageType = 'search',
    getUsers,
    getCustomers,
    getApplications,
    getCurrentCustomerUsers,
    searchType,
    setReactionsSearch,
    compound_id,
    feedbackPagination,
    getFeedback,
  }) => {
    const { pagesAmount, activePage, perPage } = (() => {
      if (pageType === 'search') return pagination
      if (pageType === 'adminAllUsers') return usersPagination
      if (pageType === 'adminAllCustomers') return customersPagination
      if (pageType === 'adminCurrentCompany') return currentCustomerUsersPag
      if (pageType === 'adminAllApplications') return applicationsPagination
      if (pageType === 'feedback') return feedbackPagination
    })()

    const location = useLocation()
    const isAdminPanel = location.pathname.includes('/admin-panel')

    const pages = useMemo(() => {
      const totalPageNumbers =
        PAGINATION_SIBLINGS_COUNT + PAGINATION_VISIBLE_PAGES_COUNT
      if (totalPageNumbers >= pagesAmount) getPagesRange(1, pagesAmount)

      const showLeftDots =
        activePage > PAGINATION_VISIBLE_PAGES_COUNT &&
        activePage - PAGINATION_SIBLINGS_COUNT > 3

      const showRightDots =
        pagesAmount > PAGINATION_VISIBLE_PAGES_COUNT &&
        (activePage <= PAGINATION_VISIBLE_PAGES_COUNT ||
          activePage + PAGINATION_SIBLINGS_COUNT < pagesAmount - 2)

      if (!showLeftDots && showRightDots) {
        let leftItemCount = 3 + 2 * PAGINATION_SIBLINGS_COUNT
        let leftRange = getPagesRange(1, leftItemCount)
        return [...leftRange, '...']
      }

      if (showLeftDots && !showRightDots) {
        let rightRange = getPagesRange(activePage, pagesAmount)
        return ['...', ...rightRange]
      }

      if (showLeftDots && showRightDots) {
        let middleRange = getPagesRange(
          activePage,
          activePage + PAGINATION_SIBLINGS_COUNT + 2
        )
        return ['...', ...middleRange, '...']
      }

      return getPagesRange(1, pagesAmount)
    }, [activePage, pagesAmount])

    const getPage = (activePage) => {
      if (
        activePage <
        MIDDLE_RANGE_COUNT +
          PAGINATION_VISIBLE_PAGES_COUNT +
          PAGINATION_SIBLINGS_COUNT
      ) {
        if (
          activePage ===
          PAGINATION_VISIBLE_PAGES_COUNT + PAGINATION_SIBLINGS_COUNT
        )
          return PAGINATION_VISIBLE_PAGES_COUNT
        if (
          activePage >
          PAGINATION_VISIBLE_PAGES_COUNT + PAGINATION_SIBLINGS_COUNT
        )
          return PAGINATION_VISIBLE_PAGES_COUNT + PAGINATION_SIBLINGS_COUNT
      } else {
        return activePage - MIDDLE_RANGE_COUNT
      }
    }

    const handleChangeActivePage = (value, ind) => {
      let page
      if (typeof value === 'string') {
        const pagesNumbers = pages.filter((el) => typeof el === 'number')
        page = ind === 0 ? getPage(activePage) : pagesNumbers.pop() + 1
      }
      let newValue = typeof value === 'string' ? page : value

      !isAdminPanel &&
        setPaginationConfig({
          ...pagination,
          activePage: newValue,
        })

      const handlePaginateAdminPanel = () => {
        const params = {
          limit: perPage,
          offset: (newValue - 1) * perPage,
        }
        const adminRole = readStorage('role')

        if (pageType === 'adminCurrentCompany') {
          getCurrentCustomerUsers({
            isSynAdmin: adminRole === 'syn_admin',
            ...params,
          })
          setCurrentCustomerUsersPaginationConfig({
            ...currentCustomerUsersPag,
            activePage: newValue,
          })
        }
        if (pageType === 'adminAllUsers') {
          getUsers(params)
          setUsersPaginationConfig({
            ...usersPagination,
            activePage: newValue,
          })
        }
        if (pageType === 'adminAllCustomers') {
          getCustomers(params)
          setCustomersPaginationConfig({
            ...customersPagination,
            activePage: newValue,
          })
        }
        if (pageType === 'adminAllApplications') {
          getApplications(params)
          setApplicationsPaginationConfig({
            ...applicationsPagination,
            activePage: newValue,
          })
        }
        if (pageType === 'feedback') {
          getFeedback({ limit: params.limit, offset: params.offset })

          setFeedbackPaginationConfig({
            ...feedbackPagination,
            activePage: newValue,
          })
        }
      }

      if (isAdminPanel) {
        handlePaginateAdminPanel()
      } else if (searchType === 'reaction') {
        setReactionsSearch({
          compound_id,
          offset: (newValue - 1) * perPage,
          limit: pagination.perPage,
          page: newValue,
          task_uuid: pastResult?.id,
        })
      } else if (!(bingoTaskId || litTaskId || pastResult)) {
        loadMoreMolecules({
          basket,
          limit: pagination.perPage,
          page: newValue,
        })
      } else if (bingoTaskId || pastResult?.query?.type === 'bingo_search') {
        setBingoSearch({
          task_uuid: bingoTaskId || pastResult.id,
          offset: (newValue - 1) * perPage,
        })
      } else if (
        litTaskId ||
        pastResult?.query?.type === 'full_text_search' ||
        pastResult?.query?.type === 'advanced_text_search'
      ) {
        setLiteratureSearch({
          task_uuid: litTaskId || pastResult.id,
          offset: (newValue - 1) * perPage,
        })
      }
    }

    return (
      <PaginationBlock>
        <IndicatorBtn
          disabled={activePage === 1}
          data-test="pagination-btn-prev"
          onClick={() =>
            activePage > 1 && handleChangeActivePage(activePage - 1)
          }
        >
          <Icon iconType="arrowLeft" size="1rem" />
        </IndicatorBtn>
        <PaginationBtns>
          {pages?.map((el, key) => {
            return (
              <PaginationBtn
                key={key}
                selected={el === activePage}
                data-test="pagination-btn"
                onClick={() => handleChangeActivePage(el, key)}
              >
                {el}
              </PaginationBtn>
            )
          })}
        </PaginationBtns>
        <IndicatorBtn
          disabled={activePage === pagesAmount}
          data-test="pagination-btn-next"
          onClick={() =>
            activePage < pagesAmount && handleChangeActivePage(activePage + 1)
          }
        >
          <Icon iconType="arrowRight" size={'1rem'} />
        </IndicatorBtn>
      </PaginationBlock>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    basket: state.crud.openedBasketID,
    pagination: state.search.pagination,
    usersPagination: state.adminPanel.users.pagination,
    applicationsPagination: state.adminPanel.applications.pagination,
    customersPagination: state.adminPanel.customers.pagination,
    currentCustomerUsersPag: state.adminPanel.currentCustomerUsers.pagination,
    litTaskId: state.crud.litSearch.taskId,
    bingoTaskId: state.crud.bingoSearch.taskId,
    pastResult: state.searchHistory.pastResult,
    searchType: state.search.searchType,
    compound_id: state.reactionsSearch.compound_id,
    feedbackPagination: state.feedback.pagination,
  }
}

const mapDispatchToProps = {
  setBingoSearch,
  loadMoreMolecules,
  setPaginationConfig,
  setUsersPaginationConfig,
  setCustomersPaginationConfig,
  setCurrentCustomerUsersPaginationConfig,
  setApplicationsPaginationConfig,
  setLiteratureSearch,
  getUsers,
  getCustomers,
  getCurrentCustomerUsers,
  getApplications,
  setReactionsSearch,
  getFeedback,
}

Pagination.displayName = 'Pagination'
export default connect(mapStateToProps, mapDispatchToProps)(Pagination)
