import NotificationAdmit from '../components/NotificationAdmit'
import NotificationCancel from '../components/NotificationCancel'
import NotificationWarning from '../components/NotificationWarning'
import ProgressBar from '../components/ProgressBar'

export const NOTIFICATION_INNER = {
  progress: (props) => <ProgressBar {...props} />,
  warning: (props) => <NotificationWarning {...props} />,
  error: (props) => <NotificationWarning {...props} />,
  admit: (props) => <NotificationAdmit {...props} />,
  success: (props) => <NotificationWarning {...props} />,
  cancel: (props) => <NotificationCancel {...props} />,
}

export const SYNMAP_3D_TASK_NAME = '3d-umap-backend'
export const PDF2SMILES_UPLOAD_TASK_PREFIX = 'Recognizing of'
export const SYNTH_COST = 'price_retro'
export const BINGO_SEARCH = 'bingo_search'
export const RETRO_TREE = 'reactions_tree'
export const SYNTHESIS = 'apply_transformer'
export const GENERATOR = 'synmap'
export const SPECTRA_MS_CFM_ID = 'cfm-id'
export const STRUCTURES_COMPARE_CALC = 'Calculation of compared structures'

export const AUTO_REMOVED_SUCCESS_TASKS = ['generator.genStarted']
export const TABULAR_ANALYZE = 'tabular analyze'
