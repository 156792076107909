import {
  MethodInfo,
  MethodYield,
} from 'components/SearchReactionsCard/index.style'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Caption } from 'components/common/text/index.style'
import Icon from 'components/Icon'

const ReactionMethodInfo = ({ yield_percents, idx, backgroundColor }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <MethodInfo>
      <MethodYield backgroundColor={backgroundColor}>
        <Caption
          lineHeight={theme.text[14]}
          fontWeight={theme.fontWeight.medium}
        >{`${t('filter.reaction.yield')}: ${
          yield_percents ? `${yield_percents}%` : `${t('common.unknown')}`
        }`}</Caption>
      </MethodYield>
      <MethodYield
        data-tooltip-id={`card-method-sources-tip-${idx}`}
        pointer
        backgroundColor={backgroundColor}
      >
        <Caption
          lineHeight={theme.text[14]}
          fontWeight={theme.fontWeight.medium}
        >
          {t('filter.reaction.sources')}
        </Caption>
        <Icon iconType="info" size="1rem" color={theme.colors.text.tertiary} />
      </MethodYield>
      <ReactTooltip
        id={`card-method-sources-tip-${idx}`}
        className="c-tooltip c-tooltip-base"
        place="top"
        positionStrategy="fixed"
      >
        {`${t('filter.reaction.material_taken_from_open_sources')}`}
      </ReactTooltip>
    </MethodInfo>
  )
}

export default ReactionMethodInfo
