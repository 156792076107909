import i18n from 'i18n'
import { t } from 'i18next'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { Label } from 'components/common/text/index.style'
import { Source } from 'components/SearchReactionsCard/index.style'
import { formatDateWithLocale } from 'utils/dateTime/formatDateAndTime'

import { Line } from '../index.style'
import { DATASET_LOGS_PREFIX } from '../config/config'

export const getLogConfig = (
  log,
  theme,
  toggleStructures,
  expandedStructures
) => {
  const {
    id,
    title: initTitle,
    message: initMessage,
    date: initDate,
    request_type,
    molecules,
  } = log

  const { failed, loaded, removed } = molecules || {}

  const isExpanded = expandedStructures.includes(id)
  let title = t(`${DATASET_LOGS_PREFIX}dataset_changed`)
  let title2 = ''
  let date_title = t(`${DATASET_LOGS_PREFIX}modification_date`)

  const isDatasetCreated = request_type === 'create'
  const isDatasetCreatedByCopy = request_type === 'copy'
  const isDatasetCreatedByJoin = request_type === 'join'
  const isMoleculesAdded = request_type === 'molecules_added'
  const isMoleculesMoved = request_type === 'molecules_moved'
  const isMoleculesCopied = request_type === 'molecules_copied'
  const isMoleculesUploadedFromFile = request_type === 'upload_file'
  const isMoleculesRemoved = request_type === 'molecules_delete'
  const isDatasetUpdated = request_type === 'update'
  const isDatasetTransferred = request_type === 'transfer'

  let isNameChanged = false
  let isDescriptionChanged = false
  let oldName = ''
  let oldDescription = ''
  let fileName = ''
  let originalDatasetId = null

  const isMoleculesRejected =
    (isMoleculesAdded ||
      isMoleculesMoved ||
      isMoleculesCopied ||
      isMoleculesUploadedFromFile) &&
    loaded.count === 0 &&
    failed.count !== 0

  const isStructureChanged =
    request_type === 'chang' || request_type === 'change'

  const { date, time } = formatDateWithLocale(initDate || '', i18n.language)

  // обычное создание датасета
  if (isDatasetCreated) {
    title = t(`${DATASET_LOGS_PREFIX}dataset_creation`)
    date_title = t(`${DATASET_LOGS_PREFIX}creation_date`)
  }

  // создание датасета путем копирования датасета
  if (isDatasetCreatedByCopy) {
    let dataset_name = ''
    let match
    match = initMessage.match(/ID:\s*(\d+),\s*name:\s*'([^']*)'/)
    dataset_name = match?.[2] ? `«${match?.[2]}»` : ''
    originalDatasetId = match?.[1]

    // старый вариант лога
    if (!match) {
      match = initMessage.match(/'([^']+)'/)
      dataset_name = match?.[1] ? `«${match?.[1]}»` : ''
    }

    title = t(`${DATASET_LOGS_PREFIX}dataset_creation_by_copying`, {
      dataset_name,
    })
    date_title = t(`${DATASET_LOGS_PREFIX}copy_date`)
  }

  // создание датасета путем объединения датасетов
  if (isDatasetCreatedByJoin) {
    const datasets = initMessage
      .match(/'([^']+)'/g)
      ?.map((name) => `«${name.slice(1, -1)}»`)
      .join(', ')

    title = t(`${DATASET_LOGS_PREFIX}dataset_creation_by_merge`, {
      datasets,
    })
    date_title = t(`${DATASET_LOGS_PREFIX}merger_date`)
  }

  // молекулы отклонены
  if (isMoleculesRejected) {
    if (isMoleculesUploadedFromFile) {
      const match = initTitle.match(/Reject messages from\s+(.*)/)
      fileName = match?.[1] || ''

      title = t(`${DATASET_LOGS_PREFIX}structures_rejected_when_uploaded`, {
        file_name: fileName ? `«${fileName}»` : '',
      })
    } else if (isMoleculesMoved) {
      title = t(`${DATASET_LOGS_PREFIX}structures_rejected_when_moved`)
    } else if (isMoleculesCopied) {
      title = t(`${DATASET_LOGS_PREFIX}structures_rejected_when_copied`)
    } else title = t(`${DATASET_LOGS_PREFIX}structures_rejected_when_added`)
  }

  // добавление или перемещение молекул
  else if (isMoleculesAdded || isMoleculesMoved || isMoleculesCopied) {
    title = isMoleculesCopied
      ? t(`${DATASET_LOGS_PREFIX}structures_copied`)
      : isMoleculesMoved
      ? t(`${DATASET_LOGS_PREFIX}structures_moved`)
      : t(`${DATASET_LOGS_PREFIX}structures_added`)
  }

  // загрузка молекул из файла
  else if (isMoleculesUploadedFromFile) {
    const match = initTitle.match(/Import results from\s+(.*)/)
    fileName = match?.[1]?.slice(0, -1) || ''

    title = t(`${DATASET_LOGS_PREFIX}structures_uploaded`, {
      file_name: fileName ? `«${fileName}»` : '',
    })
  }

  // удаление молекул
  if (isMoleculesRemoved) {
    title = t(`${DATASET_LOGS_PREFIX}structures_removed`)
  }

  // изменение названия/описания/названия и описания датасета
  if (isDatasetUpdated) {
    const regex = /(\b\w+\b) was changed from '(.*?)' to '(.*?)'/g
    let changes = []

    let match

    while ((match = regex.exec(initMessage)) !== null) {
      changes.push({
        name: match?.[1],
        oldValue: match?.[2],
        newValue: match?.[3],
      })
    }

    // означает, что старый лог формата "The title was changed to test1"
    if (!changes.length) {
      const regex = /(\b\w+\b) was changed to (\w+)/g

      let match

      while ((match = regex.exec(initMessage)) !== null) {
        changes.push({
          name: match?.[1],
          newValue: match?.[2],
        })
      }
    }

    let titleObj = changes.find((c) => c.name === 'title')
    let infoObj = changes.find(
      (c) => c.name === 'info' || c.name === 'description'
    )

    //изменение названия
    if (titleObj) {
      isNameChanged = true
      oldName = titleObj.oldValue ? `«${titleObj.oldValue}»` : ''
      title = t(`${DATASET_LOGS_PREFIX}dataset_name_changed`, {
        new_name: `«${titleObj.newValue}»`,
      })
      // если также изменено описание
      if (infoObj) {
        isDescriptionChanged = true
        oldDescription = infoObj.oldValue ? `«${infoObj.oldValue}»` : ''
        title2 = t(`${DATASET_LOGS_PREFIX}dataset_description_changed`, {
          new_description: `«${infoObj.newValue}»`,
        })
      }
      // изменено только описание
    } else if (infoObj) {
      isDescriptionChanged = true
      oldDescription = infoObj.oldValue ? `«${infoObj.oldValue}»` : ''
      title = t(`${DATASET_LOGS_PREFIX}dataset_description_changed`, {
        new_description: `«${infoObj.newValue}»`,
      })
    } else {
      title = t(`${DATASET_LOGS_PREFIX}dataset_name_changed_short`)
    }
  }

  // датасет рассчитан
  // лог присутствует только в логах компании
  if (request_type === 'calculate') {
    title = t(`${DATASET_LOGS_PREFIX}dataset_is_calculated`)
  }

  // изменена структура в датасете (редактирование структуры через контекстное меню)
  if (isStructureChanged) {
    title = t(`${DATASET_LOGS_PREFIX}structure_changed`, {
      new_structure: `«${loaded.items[0]}»`,
    })
  }

  // датасет перенесен
  if (isDatasetTransferred) {
    title = t(`${DATASET_LOGS_PREFIX}dataset_transferred`)
  }

  const config = [
    {
      name: t(`${DATASET_LOGS_PREFIX}document_type`),
      value: (
        <Line withCustomValue>
          <Label>{t(`${DATASET_LOGS_PREFIX}copy`)}</Label>
          {!!originalDatasetId && (
            <Source
              onClick={() => {
                window.open(`/molecules/${originalDatasetId}`)
              }}
            >
              <Label
                pointer
                ellipsis
                minWidth={'0'}
                color={theme.colors.statuses.link}
              >
                {t(`${DATASET_LOGS_PREFIX}original_link`)}
              </Label>
              <Icon
                iconType="link"
                size="0.75rem"
                color={theme.colors.statuses.link}
              />
            </Source>
          )}
        </Line>
      ),

      show: isDatasetCreatedByCopy,
    },

    {
      name: isMoleculesMoved
        ? t(`${DATASET_LOGS_PREFIX}moved_structures_count`)
        : isMoleculesCopied
        ? t(`${DATASET_LOGS_PREFIX}copied_structures_count`)
        : t(`${DATASET_LOGS_PREFIX}added_structures_count`),

      value: <Label>{loaded.count}</Label>,
      show:
        (isMoleculesAdded ||
          isMoleculesMoved ||
          isMoleculesUploadedFromFile ||
          isMoleculesCopied) &&
        loaded.count !== 0,
    },
    {
      name: t(`${DATASET_LOGS_PREFIX}rejected_structures_count`),
      value: (
        <Line gap="0.5rem" withCustomValue>
          <Label>{failed.count}</Label>

          {!isMoleculesUploadedFromFile && (
            <CustomButton
              type="text_accent"
              onClick={() => toggleStructures(id)}
            >
              {isExpanded
                ? t(`${DATASET_LOGS_PREFIX}hide`)
                : t(`${DATASET_LOGS_PREFIX}show`)}
              <Icon
                iconType={isExpanded ? 'arrowTop' : 'arrowDown'}
                size="1rem"
              />
            </CustomButton>
          )}
        </Line>
      ),
      show:
        isMoleculesRejected ||
        ((isMoleculesAdded ||
          isMoleculesMoved ||
          isMoleculesCopied ||
          isMoleculesUploadedFromFile) &&
          failed.count !== 0),
    },
    {
      name: t(`${DATASET_LOGS_PREFIX}removed_structures_count`),
      value: <Label>{removed.count}</Label>,
      show: isMoleculesRemoved,
    },
    {
      name: t(`${DATASET_LOGS_PREFIX}dataset_old_name`),
      value: <Label ellipsis>{oldName || ''}</Label>,
      show: isNameChanged && !!oldName,
    },
    {
      name: t(`${DATASET_LOGS_PREFIX}dataset_old_description`),
      value: <Label ellipsis>{oldDescription || ''}</Label>,
      show: isDescriptionChanged && !!oldDescription,
    },
    {
      name: t(`${DATASET_LOGS_PREFIX}old_structure`),
      value: <Label>{removed.items[0] || ''}</Label>,
      show: isStructureChanged,
    },
    {
      name: date_title,
      value: <Label>{`${date} ${time}`}</Label>,
      show: true,
    },
  ]

  return { title, title2, config, failed_molecules: failed.items }
}

export const getFilteredByUsersLogsList = (logList, type) => {
  if (type === 'all') return logList
  if (type === 'admin')
    return logList.filter((el) => el?.user?.role === 'admin')
  if (type === 'employees')
    return logList.filter((el) => el?.user?.role !== 'admin')

  return logList
}

export const getFilteredLogsList = (logList, filterByType) => {
  if (filterByType === 'all') return logList

  if (filterByType === 'added')
    return logList.filter(
      ({ request_type, molecules }) =>
        (request_type === 'molecules_added' ||
          request_type === 'molecules_moved' ||
          request_type === 'molecules_copied' ||
          request_type === 'upload_file') &&
        molecules?.loaded?.count > 0
    )

  if (filterByType === 'removed')
    return logList.filter(
      ({ request_type }) => request_type === 'molecules_delete'
    )
  if (filterByType === 'rejected')
    return logList.filter(
      ({ request_type, molecules }) =>
        (request_type === 'molecules_added' ||
          request_type === 'molecules_moved' ||
          request_type === 'molecules_copied' ||
          request_type === 'upload_file') &&
        molecules?.loaded?.count === 0 &&
        molecules?.failed?.count > 0
    )
  if (filterByType === 'changed')
    return logList.filter(
      ({ request_type }) =>
        request_type === 'chang' ||
        request_type === 'change' ||
        request_type === 'update'
    )
  if (filterByType === 'calculated')
    return logList.filter(({ request_type }) => request_type === 'calculate')

  return logList
}
