import styled from 'styled-components'

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ isNeedToAddMolecule }) =>
    isNeedToAddMolecule ? 0 : '0.5rem 0.75rem 1.375rem 0'};
  & h2 {
    margin-bottom: 0.5rem;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.192px;
  }

  &:empty {
    display: none;
  }
`

export const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 0.5rem;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #f5f7f7;
  justify-content: center;
  padding: ${({ showHistory }) =>
    showHistory ? '1.25rem 0 0 0rem' : '0 0 0 0rem'};

  @media (max-width: 768px) {
    max-width: 100vw;
  }

  @media print {
    display: none;
  }
`

export const ScrollTopBtn = styled.button`
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: ${({ isStructuresComparePage, isNotificationsButtonShow }) => {
    if (isStructuresComparePage)
      return isNotificationsButtonShow ? '8.5rem' : '5rem'
    return isNotificationsButtonShow ? '12rem' : '8.5rem'
  }};
  right: 1.5rem;
  border: none;
  outline: none;
  background: #ffffff;
  border-radius: 12px;
  padding: 0.75rem;
  z-index: 5;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.3s;
  box-shadow: ${({ theme }) => theme.shadow.default};
  cursor: pointer;

  & > svg {
    color: #2d3745;
  }

  &:hover {
    & > svg {
      color: #42c1bf;
      transition: color 0.3s;
    }
  }
`

export const MoveBtn = styled.button`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  align-self: center;
  display: flex;
  border-radius: 0.75rem;
  background: #42c1bf;
  border: none;
  outline: none;
  color: #fff;
  column-gap: 0.5rem;
  cursor: pointer;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  height: ${({ visible }) => (visible ? 'unset' : '0')};
  padding: ${({ visible }) => (visible ? '0.75rem 1rem' : '0')};
  box-shadow: ${({ theme }) => theme.shadow.default};

  transition: opacity 0.3s;

  & > span {
    align-self: center;
  }

  & > svg {
    color: #fff;
  }

  &:hover {
    background: #3aa9a7;
    transition: background 0.3s;
  }

  &:active {
    background: #42c1bf;
    transition: background 0.3s;
  }
`

export const SelectedAmount = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  padding: 2px;
  background: #fff;
  box-shadow: ${({ theme }) => theme.shadow.default};
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;

  & > span {
    align-self: center;
    color: #42c1bf;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.144px;
  }
`

export const BackButtonContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  @media print {
    display: none;
  }
`
