import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { Caption } from 'components/common/text/index.style'
import Icon from 'components/Icon'
import {
  ARRAY_REACTION_CONDITIONS,
  REACTION_CONDITIONS_1,
  REACTION_CONDITIONS_2,
} from 'components/SearchReactionsCard/config/config'
import {
  Condition,
  ConditionsBlockContainer,
  ConditionsList,
  Divider,
  More,
  MoreTooltipList,
  Source,
} from 'components/SearchReactionsCard/index.style'

export const ConditionsBlock = ({
  condition,
  id: conditionId,
  isSearchCard = false,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <ConditionsBlockContainer isSearchCard={isSearchCard}>
      {[REACTION_CONDITIONS_1, REACTION_CONDITIONS_2].map((list, i) => (
        <ConditionsList key={i} order={i && 2} isSearchCard={isSearchCard}>
          {list.map((cond) => {
            const isEmpty =
              !condition?.[cond] || condition?.[cond]?.length === 0
            const isArray = ARRAY_REACTION_CONDITIONS.includes(cond) && !isEmpty
            const visibleList = isArray
              ? isSearchCard
                ? condition[cond].slice(0, 1)
                : condition[cond]
              : []
            return (
              <Condition key={cond} isSearchCard={isSearchCard}>
                <Caption
                  lineHeight={theme.text[14]}
                  fontWeight={theme.fontWeight.medium}
                  color={theme.colors.text.secondary}
                >
                  {t(`filter.reaction.conditions.${cond}`)}:
                </Caption>
                {isArray && (
                  <>
                    {visibleList.map(({ id, name }, i) => (
                      <Source
                        key={i}
                        onClick={() => {
                          window.open(`/molecule/${id}`)
                        }}
                      >
                        <Caption
                          lineHeight={theme.text[14]}
                          fontWeight={theme.fontWeight.medium}
                          color={theme.colors.statuses.link}
                          pointer
                          ellipsis
                          minWidth={'0'}
                        >
                          {name}
                        </Caption>
                        <Icon
                          iconType="link"
                          size="0.75rem"
                          color={theme.colors.statuses.link}
                        />
                      </Source>
                    ))}
                    {isSearchCard && condition[cond].length > 1 && (
                      <>
                        <More data-tooltip-id={`${conditionId}-${cond}`}>
                          <Caption
                            lineHeight={theme.text[14]}
                            fontWeight={theme.fontWeight.medium}
                            pointer
                          >
                            {`${t('filter.reaction.more')} ${
                              condition[cond].length - 1
                            }`}
                          </Caption>
                          <Icon iconType="arrowRight" size="0.75rem" />
                        </More>
                        <ReactTooltip
                          id={`${conditionId}-${cond}`}
                          className="c-tooltip c-tooltip-base c-tooltip-reaction-condition"
                          classNameArrow="c-tooltip-arrow c-tooltip-arrow-white"
                          place={'bottom-start'}
                          offset={18}
                          clickable
                          positionStrategy="fixed"
                        >
                          <MoreTooltipList>
                            {condition[cond].map(({ name, id }, i) => (
                              <Source
                                onClick={() => {
                                  window.open(`/molecule/${id}`)
                                }}
                                key={i}
                              >
                                <Caption
                                  lineHeight={theme.text[14]}
                                  fontWeight={theme.fontWeight.medium}
                                  color={theme.colors.statuses.link}
                                  pointer={'pointer'}
                                  ellipsis
                                  minWidth={'0'}
                                >
                                  {name}
                                </Caption>
                                <Icon
                                  iconType="link"
                                  size="0.75rem"
                                  color={theme.colors.statuses.link}
                                />
                              </Source>
                            ))}
                          </MoreTooltipList>
                        </ReactTooltip>
                      </>
                    )}
                  </>
                )}
                {!isArray && (
                  <Caption
                    lineHeight={theme.text[14]}
                    fontWeight={theme.fontWeight.medium}
                    color={
                      isEmpty ? theme.colors.statuses.attention : undefined
                    }
                  >
                    {isEmpty
                      ? t('filter.reaction.conditions.no_information')
                      : condition?.[cond]}
                  </Caption>
                )}
              </Condition>
            )
          })}
        </ConditionsList>
      ))}
      <Divider />
    </ConditionsBlockContainer>
  )
}
