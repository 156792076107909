import React, { memo, useEffect, useState, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import LoadingOverlay from 'components/LoadingOverlay'
import { addNotification } from 'store/actions/notifications'
import {
  repeatSearch,
  getSearchHistory,
  openSearchHistory,
  resetPastResult,
} from 'store/actions/searchHistory'
import History from './History'
import { HISTORY_LIMIT } from './config/config'
import { useTranslation } from 'react-i18next'
import { getGroupedHistory } from './HistoryList/utils'
import HistoryPreview from '../HistoryPreview'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'
import { settingsSidebarToggle } from 'store/actions/settings'
import { setOpenFilter } from 'store/actions/filter'

const SearchHistory = memo(
  ({
    repeatSearch,
    searchHistory,
    addNotification,
    getSearchHistory,
    openSearchHistory,
    filterOpen,
    isSidebarCollapsed,
    setOpenFilter,
    settingsSidebarToggle,
    resetPastResult,
  }) => {
    const { history, loading, error, showHistory, historyFilter } =
      searchHistory || {}

    const { i18n, t } = useTranslation()

    const [groupedHistory, setGroupedHistory] = useState([])

    const historyFilteredByType = useMemo(() => {
      if (historyFilter === 'all') return history
      if (historyFilter === 'literature')
        return history?.filter(
          (item) =>
            item?.query?.type === 'full_text_search' ||
            item?.query?.type === 'advanced_text_search'
        )
      if (historyFilter === 'structures' || historyFilter === 'molecules')
        return history?.filter(
          (item) =>
            item?.query?.type === 'molecules_search' ||
            item?.query?.type === 'bingo_search'
        )
      if (historyFilter === 'reactions')
        return history?.filter(
          (item) => item?.query?.type === 'reaction_search'
        )
    }, [historyFilter, history])

    useEffect(() => {
      getSearchHistory({ offset: 0, limit: HISTORY_LIMIT })

      if (searchHistory?.error) {
        const id = uuidv4()

        const notify = {
          id,
          name: 'Error',
          text: searchHistory.error,
          notification_type: 'error',
          autoRemove: true,
          timeout: 5000,
          needTranslateText: false,
        }

        addNotification(notify)
      }
    }, [error])

    useEffect(() => {
      setGroupedHistory(getGroupedHistory(historyFilteredByType, i18n.language))
    }, [i18n.language, historyFilteredByType])
    if (loading) return <LoadingOverlay />

    return showHistory ? (
      <>
        <CustomButton
          onClick={() => {
            !filterOpen && setOpenFilter(true)
            !isSidebarCollapsed && settingsSidebarToggle()
            openSearchHistory(false)
          }}
          type="text_accent"
          gap="0.25rem"
        >
          <Icon iconType={'arrowLeft'} size="1rem" />
          {t('search.search_history.back_to_search')}
        </CustomButton>
        {historyFilteredByType?.length ? (
          <History
            history={groupedHistory}
            onRepeatSearch={(...args) => {
              resetPastResult()
              repeatSearch(...args)
            }}
          />
        ) : (
          <></>
        )}
      </>
    ) : (
      <HistoryPreview />
    )
  }
)

const mapStateToProps = (state) => {
  return {
    filters: state.filter.config,
    pagination: state.search.pagination,
    searchHistory: state.searchHistory,
    filterOpen: state.filter.open,
    isSidebarCollapsed: state.settings.isSidebarCollapsed,
  }
}

const mapDispatchToProps = {
  repeatSearch,
  addNotification,
  getSearchHistory,
  openSearchHistory,
  settingsSidebarToggle,
  setOpenFilter,
  resetPastResult,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchHistory)

SearchHistory.displayName = 'SearchHistory'
