import styled, { css } from 'styled-components'

export const Container = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ withCustomLink }) =>
    withCustomLink &&
    css`
      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.statuses.link};
      }
    `}
`
