import styled from 'styled-components'

export const LogsListContainer = styled.div`
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  margin-top: ${({ isCompanyDatasetsLogging }) =>
    isCompanyDatasetsLogging ? '0rem' : '-0.5rem'};
`

export const Log = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
`

export const Tags = styled.div`
  display: flex;
  gap: 0.5rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`
export const Divider = styled.div`
  width: 100%;
  height: 0.0625rem;
  border-radius: 0.75rem;
  background: ${({ type, theme }) =>
    type === 'white'
      ? theme.colors.backgrounds.primary
      : theme.colors.backgrounds.separator};
`
