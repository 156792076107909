import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import PagePreview from 'components/PagePreview'
import LoaderRestyling from 'components/LoaderRestyling'
import StructuresCompare from 'components/StructuresCompare'
import { getStructuresCalculation } from 'store/actions/structuresCompare.js'

import { LoaderContainer, Wrapper } from './index.style.js'

export const StructuresComparePage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isStructuresLoading, isCalculationLoading, structures } = useSelector(
    (store) => store.structuresCompare
  )

  useEffect(() => {
    if (!isCalculationLoading) {
      dispatch(getStructuresCalculation())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isStructuresLoading)
    return (
      <LoaderContainer>
        <LoaderRestyling />
      </LoaderContainer>
    )

  return (
    <Wrapper isEmpty={!structures.length}>
      {structures.length ? (
        <StructuresCompare />
      ) : (
        <PagePreview
          withButton={false}
          title={t('structures_compare.empty_title')}
          description={t('structures_compare.empty_description')}
        />
      )}
    </Wrapper>
  )
}

export default StructuresComparePage
