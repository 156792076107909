import { useCallback, useEffect, useState } from 'react'
import {
  SpectraWrapper,
  HeaderDescription,
  InformationCard,
  SpectralLabel,
  ButtonsBlock,
  LeftBlock,
  Tabs,
  Tab,
  MoleculeWrapper,
  SvgWrapper,
  ChartCard,
  Loader,
  StyledImg,
  SwitcherWrapper,
  ChartHeader,
} from './index.style'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import Ketcher from '../../components/Ketcher/index'
import MoleculeViewer from '../../components/MoleculeViewer/index'
import BarChart from './components/barChart'
import { useDispatch, connect } from 'react-redux'
import {
  getMsData,
  resetSpectra,
  setSpectraType,
  setCalculatedMSType,
  getNmrData,
  getInfraredData,
  clearSpectraData,
} from 'store/actions/spectra'
import {
  ENERGY_TYPES,
  SPECTRA_INFRARED_CHART,
  SPECTRA_STR_CANVAS_ID,
  SPECTRA_TYPES_SWITCH,
} from './config'
import NMRBlock from './components/NMRBlock'
import MSBlock from './components/MSBlock'
import InfraredBlock from './components/InfraredBlock'
import { addNotification } from '../../store/actions/notifications'
import InfraredChart from './components/infraredChart'
import NmrChartsBlock from './components/nmrCharts/index'
import Icon from 'components/Icon'
import { readStorage, removeFromStorage } from 'utils/storage/storage'
import {
  REDIRECT_SMILES,
  REDIRECT_SPECTRA_TYPE,
} from 'components/MoleculeViewer/config/config'
import CustomScrollbar from 'components/CustomScrollbar'
import {
  Caption,
  Headline,
  Label,
  TextButtonMedium,
  TextLarge,
  TitleSecondary,
} from 'components/common/text/index.style'
import { useTheme } from 'styled-components'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import CustomSwitch from 'components/common/customSwitch'
import { generatePdfForInfrared, generatePdfForMS } from './helpers'
import { validateNotStarSymbol } from 'utils/common/common'

const SpectraPredictionPage = ({ spectra, isSidebarCollapsed, nmrMethod }) => {
  const [showKetcher, setShowKetcher] = useState(false)
  const [smiles, setSmiles] = useState('')

  const {
    data,
    loading,
    error,
    error_text,
    calculatedMSType,
    spectraType,
    adduct_type: adductType,
    ion_mode: ionMode,
    roundingRI,
    roundingMZ,
    nmr,
    infrared,
  } = spectra

  const nmrDataKeys = Object.keys(nmr.data)
  const isDataLoaded =
    !!Object.keys(data).length || !!nmrDataKeys.length || !!infrared.data.length

  const isDataLoading = loading || infrared.loading || nmr.loading

  const isNmrLoadedAndEmpty =
    nmrDataKeys.length && !nmr.data?.intensities?.length

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()

  const handleReset = useCallback(() => {
    dispatch(resetSpectra())
    setSmiles('')
  }, [dispatch])

  useEffect(() => {
    if (isNmrLoadedAndEmpty) {
      const id = uuidv4()

      const notify = {
        id,
        name: 'spectra.error.no_spectra',
        text: 'spectra.error.no_spectra',
        notification_type: 'warning',
        autoRemove: true,
        timeout: 5000,
      }

      dispatch(addNotification(notify))
    }
  }, [dispatch, isNmrLoadedAndEmpty, t])

  useEffect(() => {
    if (error || nmr.error || infrared.error) {
      const currentErrorText =
        spectraType === 'nmr'
          ? nmr.error_text
          : spectraType === 'ms'
          ? error_text
          : infrared.error_text

      if (currentErrorText === 'error_ms') return

      const id = uuidv4()
      const notify = {
        id,
        name: 'spectra.error.error',
        text: `spectra.error.${
          currentErrorText?.endsWith('is not a smiles')
            ? 'not_valid_smiles'
            : 'error_occured'
        }`,

        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }

      dispatch(addNotification(notify))
    }
  }, [dispatch, error, error_text, nmr.error, infrared.error])

  useEffect(() => {
    const redirectSmiles = readStorage(REDIRECT_SMILES)
    if (!redirectSmiles) return

    const redirectSpectraType = readStorage(REDIRECT_SPECTRA_TYPE)
    redirectSpectraType && dispatch(setSpectraType(redirectSpectraType))
    setSmiles(redirectSmiles)
    ;[(REDIRECT_SMILES, REDIRECT_SMILES)].map((key) => removeFromStorage(key))
  }, [dispatch])

  const handlePredict = () => {
    if (spectraType === 'ms') {
      dispatch(
        getMsData({
          smiles: smiles,
          adduct_type: adductType.value,
          m_z_round: roundingMZ.value,
          percent_round: roundingRI.value,
        })
      )
    }

    if (spectraType === 'nmr') {
      dispatch(getNmrData({ smiles, method: nmrMethod.value }))
    }

    if (spectraType === 'infraredSpectrometry') {
      dispatch(getInfraredData(smiles))
    }

    const id = uuidv4()

    const notify = {
      id,
      name: 'notification.spectra_start',
      notification_type: 'success',
      autoRemove: true,
      timeout: 5000,
    }

    dispatch(addNotification(notify))
  }

  const handleSetSpectraType = (category) => {
    if (category === spectraType) return
    dispatch(setSpectraType(category))
    dispatch(resetSpectra())
  }

  const handleSmilesInput = (value) => {
    const validateEnglishInput = (inputText) => {
      const regexp = /^[a-zA-Z0-9\s!@#$%^&*()\\_+{}[\]:;"='<>,.?/~`|-]+$/
      return regexp.test(inputText)
    }
    if (validateEnglishInput(value) || !value) changeSmiles(value)
  }

  const handleOpenKetcher = () => {
    if (!isDataLoading) setShowKetcher(true)
  }

  const isNmrImageLoaded =
    spectraType === 'nmr' &&
    Object.keys(spectra.nmr.data).length &&
    spectra.nmr.data.bytes_img_mol

  const changeSmiles = (sm) => {
    const isDataLoaded =
      !!Object.keys(data).length ||
      !!Object.keys(nmr.data).length ||
      !!infrared.data.length
    if (isDataLoaded) {
      dispatch(clearSpectraData())
    }
    setSmiles(sm)
  }

  const notifyDownloadStarted = () => {
    const id = uuidv4()
    const notify = {
      id,
      name: 'notification.downloading_started',
      notification_type: 'success',
      autoRemove: true,
      timeout: 5000,
    }
    dispatch(addNotification(notify))
  }

  return (
    <CustomScrollbar className="margin-4-right">
      <SpectraWrapper>
        <TitleSecondary>{t('spectra.title')}</TitleSecondary>
        <HeaderDescription>
          <TextLarge fontWeight={theme.fontWeight.medium} color="inherit">
            {t(`spectra.header.${spectra.spectraType}`)}
          </TextLarge>
        </HeaderDescription>
        <InformationCard
          calculated={isDataLoaded}
          isSidebarCollapsed={isSidebarCollapsed}
        >
          <LeftBlock>
            <div>
              <Headline
                color={theme.colors.text.primary}
                fontWeight={theme.fontWeight.semibold}
              >
                {t('spectra.input_params')}
              </Headline>
              <SwitcherWrapper>
                <CustomSwitch
                  items={SPECTRA_TYPES_SWITCH}
                  active={spectraType}
                  disabled={isDataLoading}
                  wrapperWidth="100%"
                  keyTemplate={'spectra-type-switch'}
                  onClick={handleSetSpectraType}
                />
              </SwitcherWrapper>
            </div>
            <div>
              <div>
                <SpectralLabel>
                  <Caption
                    color={theme.colors.text.secondary}
                    fontWeight={theme.fontWeight.medium}
                  >
                    {t('spectra.labels.input')}
                  </Caption>
                </SpectralLabel>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CustomInput
                    value={smiles}
                    disabled={isDataLoading}
                    onChange={(e) => {
                      !isDataLoading && validateNotStarSymbol(e) && handleSmilesInput(e)
                    }}
                    withClearButton={true}
                    placeholder={t('spectra.labels.input_placeholder')}
                  />
                </div>
              </div>
              {spectraType === 'nmr' && <NMRBlock />}
              {spectraType === 'ms' && <MSBlock />}
              {spectraType === 'infraredSpectrometry' && <InfraredBlock />}
            </div>
            <ButtonsBlock>
              <CustomButton
                onClick={() => handlePredict()}
                width="124px"
                gap="0.25rem"
                type="accent"
                disabled={
                  !smiles ||
                  isDataLoaded ||
                  isDataLoading ||
                  loading ||
                  (spectraType === 'nmr' && !nmrMethod.value)
                }
              >
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <Icon iconType={'predictSpectra'} size="1rem" />
                    <TextButtonMedium color="inherit">
                      {t('spectra.buttons.predict')}
                    </TextButtonMedium>
                  </>
                )}
              </CustomButton>
              <CustomButton
                gap="0.25rem"
                onClick={() => handleReset()}
                width="104px"
                disabled={isDataLoading}
                type="secondary"
              >
                <Icon iconType={'refresh'} size="1rem" />
                <TextButtonMedium color="inherit">
                  {t('spectra.buttons.reset')}
                </TextButtonMedium>
              </CustomButton>
            </ButtonsBlock>
          </LeftBlock>

          <MoleculeWrapper
            onClick={handleOpenKetcher}
            drawed={smiles}
            disabled={isDataLoading}
          >
            {smiles ? (
              <>
                <div style={{ position: 'absolute', left: 20, top: 20 }}>
                  {isNmrImageLoaded ? (
                    <StyledImg
                      width={300}
                      height={300}
                      src={`data:image/png;base64,${spectra.nmr.data.bytes_img_mol}`}
                      alt="atoms"
                    />
                  ) : (
                    <MoleculeViewer
                      smiles={smiles}
                      onShowProps={() => {}}
                      noDelete={true}
                      noEdit={true}
                      noMenu={true}
                      noContainerHover={true}
                      width={344}
                      height={206}
                      noPadding={true}
                      cursor="default"
                      showId={false}
                      canvasId={SPECTRA_STR_CANVAS_ID}
                    />
                  )}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    left: '50%',
                    bottom: '1.25rem',
                    transform: 'translateX(-50%)',
                  }}
                >
                  <CustomButton
                    disabled={isDataLoading}
                    type="primary"
                    gap="0.25rem"
                  >
                    <Icon iconType={'molecularEditor'} size="1rem" />
                    <TextButtonMedium color="inherit">
                      {t('spectra.buttons.edit')}
                    </TextButtonMedium>
                  </CustomButton>
                </div>
              </>
            ) : (
              <>
                <SvgWrapper>
                  <Icon iconType={'molecularEditor'} size={'24px'} />
                </SvgWrapper>
                <TextButtonMedium
                  fontWeight={theme.fontWeight.medium}
                  color="inherit"
                >
                  {t('spectra.labels.draw_molecule')}
                </TextButtonMedium>
              </>
            )}
          </MoleculeWrapper>
        </InformationCard>
        {spectraType === 'ms' && !!Object.keys(data).length && (
          <ChartCard>
            <ChartHeader>
              <Headline color={theme.colors.text.primary}>
                {t('spectra.calculated_title')}
              </Headline>
              <CustomButton
                type="text"
                gap="0.25rem"
                onClick={() => {
                  notifyDownloadStarted()
                  generatePdfForMS({
                    smiles,
                    canvasId: SPECTRA_STR_CANVAS_ID,
                    ionMode: ionMode.label,
                    adductType: adductType.label,
                    roundingRI: roundingRI.label,
                    roundingMZ: roundingMZ.label,
                  })
                }}
              >
                {t('spectra.buttons.download')}
                <Icon iconType="upload" size="1rem" />
              </CustomButton>
            </ChartHeader>

            <Tabs style={{ marginTop: '12px' }}>
              {ENERGY_TYPES.map((type, i) => (
                <Tab
                  key={`${type}-${i}`}
                  active={type === calculatedMSType}
                  onClick={() => dispatch(setCalculatedMSType(type))}
                >
                  <Label color="inherit">
                    {t(`spectra.labels.${type}`)}
                    {type !== 'isotopic_distribution' &&
                      `, ${adductType.value}`}
                  </Label>
                </Tab>
              ))}
            </Tabs>
            {ENERGY_TYPES.map((type) => (
              <BarChart key={type} data={data[type]} msType={type} />
            ))}
          </ChartCard>
        )}
        {spectraType === 'infraredSpectrometry' && !!infrared.data.length && (
          <ChartCard>
            <ChartHeader>
              <Headline color={theme.colors.text.primary}>
                {t('spectra.calculated_title')}
              </Headline>
              <CustomButton
                type="text"
                gap="0.25rem"
                onClick={() => {
                  notifyDownloadStarted()
                  generatePdfForInfrared({
                    chartId: SPECTRA_INFRARED_CHART,
                    smiles,
                    method: infrared?.shootingMethod?.label,
                    canvasId: SPECTRA_STR_CANVAS_ID,
                  })
                }}
              >
                {t('spectra.buttons.download')}
                <Icon iconType="upload" size="1rem" />
              </CustomButton>
            </ChartHeader>

            <InfraredChart />
          </ChartCard>
        )}
        {spectraType === 'nmr' &&
          !!nmrDataKeys.length &&
          !isNmrLoadedAndEmpty && (
            <NmrChartsBlock
              smiles={smiles}
              image={`data:image/png;base64,${spectra.nmr.data.bytes_img_mol}`}
              notifyDownloadStarted={notifyDownloadStarted}
            />
          )}

        {showKetcher && (
          <Ketcher
            handleData={changeSmiles}
            closeKetcher={() => setShowKetcher(false)}
            smiles={smiles}
          />
        )}
      </SpectraWrapper>
    </CustomScrollbar>
  )
}

const mapStateToProps = (state) => {
  return {
    spectra: state.spectra,
    isSidebarCollapsed: state.settings.isSidebarCollapsed,
    nmrMethod: state.spectra.nmr.method,
  }
}

export default connect(mapStateToProps)(SpectraPredictionPage)
