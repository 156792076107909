import { v4 as uuidv4 } from 'uuid'
import { fetch, storeV2 } from 'services/rest'
import { delay, put, select, takeEvery } from 'redux-saga/effects'

import {
  UNCOMPARE_STRUCTURE_INIT,
  UNCOMPARE_STRUCTURE_SUCCESS,
  ADD_STRUCTURE_TO_COMPARE_INIT,
  GET_STRUCTURES_CALCULATION_INIT,
  ADD_STRUCTURE_TO_COMPARE_SUCCESS,
  GET_STRUCTURES_CALCULATION_SUCCESS,
  GET_STRUCTURES_COMPARISON_LIST_INIT,
  CLEAR_STRUCTURES_COMPARISON_LIST_INIT,
  GET_STRUCTURES_COMPARISON_LIST_SUCCESS,
  CLEAR_STRUCTURES_COMPARISON_LIST_SUCCESS,
} from 'store/constants/structuresCompare'
import {
  ADD_NOTIFICATION,
  ADD_SIMPLE_NOTIFICATION,
} from 'store/constants/notifications'
import { MAX_STRUCTURES_COUNT } from 'components/StructuresCompare/config'

import { convertResponseToCalculationArr } from './utils/utils'

function* getStructuresComparisonList() {
  try {
    const { data } = yield storeV2('molecule/compare_structures/get')

    yield put({
      type: GET_STRUCTURES_COMPARISON_LIST_SUCCESS,
      structures: data.result,
    })
  } catch (e) {
    console.log(e)
  }
}

function* addStructureToCompare({ id, smiles }) {
  const structures = yield select((state) => state.structuresCompare.structures)
  if (structures.length >= MAX_STRUCTURES_COUNT) {
    const notify = {
      id: uuidv4(),
      name: 'structures_compare.failed_to_add_to_compare',
      text: 'structures_compare.maximum_number_exceeded',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  } else {
    try {
      const { data } = yield storeV2('molecule/compare_structures/add', {
        params: {
          baseID: Number(id),
        },
      })

      yield put({
        type: ADD_STRUCTURE_TO_COMPARE_SUCCESS,
        id: data.result.id,
        baseID: Number(id),
        smiles,
      })
      yield put({
        type: ADD_SIMPLE_NOTIFICATION,
        text: 'structures_compare.add_to_compare_success',
        withOpenBtn: true,
        route: '/molecules-compare',
      })
    } catch (e) {
      console.log(e)
      const notify = {
        id: uuidv4(),
        name: 'structures_compare.failed_to_add_to_compare',
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }
      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
    }
  }
}

function* uncompareStructure({ baseID, needNotification }) {
  const structures = yield select((state) => state.structuresCompare.structures)
  const id = structures.find((el) => el.baseID === Number(baseID)).id
  try {
    yield storeV2('molecule/compare_structures/delete_by_id', {
      params: {
        id,
      },
    })

    yield put({
      type: UNCOMPARE_STRUCTURE_SUCCESS,
      id,
      baseID,
    })
    if (needNotification) {
      yield put({
        type: ADD_SIMPLE_NOTIFICATION,
        text: 'structures_compare.uncompare_success',
        withOpenBtn: true,
        route: '/molecules-compare',
      })
    }
  } catch (e) {
    console.log(e)
    const notify = {
      id: uuidv4(),
      name: 'structures_compare.failed_to_uncompare',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* clearStructuresComparisonList() {
  try {
    yield storeV2('molecule/compare_structures/clear')

    yield put({
      type: CLEAR_STRUCTURES_COMPARISON_LIST_SUCCESS,
    })
  } catch (e) {
    console.log(e)
    const notify = {
      id: uuidv4(),
      name: 'structures_compare.failed_to_clear',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* getStructuresCalculation() {
  let currentTaskId
  try {
    const response = yield storeV2('/molecule/compare_structures/calculation')
    const { structures } = yield select((store) => store.structuresCompare)

    if (response?.status === 200) {
      let status = 'running'
      while (status === 'running') {
        const id = response?.data?.task_uuid

        const tasks = yield fetch(`/userTasksStatus`)

        const task = tasks?.data?.find((task) => id === task.uuid)

        if (task) {
          currentTaskId = task
          if (task?.status === 'ok') {
            status = 'ok'
            let calculationResponse = yield storeV2(
              '/molecule/compare_structures/result'
            )

            if (!calculationResponse?.data?.result) throw new Error()

            const calculation = calculationResponse.data.result

            yield put({
              type: GET_STRUCTURES_CALCULATION_SUCCESS,
              calculation: convertResponseToCalculationArr(
                calculation,
                structures
              ),
            })
          }
        }
        if (currentTaskId && !task) {
          currentTaskId = 'canceled'
          status = 'canceled'
          yield put({
            type: GET_STRUCTURES_CALCULATION_SUCCESS,
            calculation: [],
          })
        }

        yield delay(2000)
      }
    }
  } catch (error) {
    if (error && currentTaskId === 'canceled') {
      yield put({
        type: GET_STRUCTURES_CALCULATION_SUCCESS,
        calculation: [],
      })
    } else if (error) {
      console.log('error', error)
    }
  }
}

function* getStructuresComparisonListWatcher() {
  yield takeEvery(
    GET_STRUCTURES_COMPARISON_LIST_INIT,
    getStructuresComparisonList
  )
}

function* addStructureToCompareWatcher() {
  yield takeEvery(ADD_STRUCTURE_TO_COMPARE_INIT, addStructureToCompare)
}

function* uncompareStructureWatcher() {
  yield takeEvery(UNCOMPARE_STRUCTURE_INIT, uncompareStructure)
}

function* clearStructuresComparisonListWatcher() {
  yield takeEvery(
    CLEAR_STRUCTURES_COMPARISON_LIST_INIT,
    clearStructuresComparisonList
  )
}

function* getStructuresCalculationWatcher() {
  yield takeEvery(GET_STRUCTURES_CALCULATION_INIT, getStructuresCalculation)
}

const watchers = [
  addStructureToCompareWatcher(),
  getStructuresComparisonListWatcher(),
  uncompareStructureWatcher(),
  clearStructuresComparisonListWatcher(),
  getStructuresCalculationWatcher(),
]

export default watchers
