import CustomButton from 'components/common/customButton'
import styled from 'styled-components'

export const StructuresListContainer = styled.div`
  height: ${({ height }) => height};
  width: 100%;
  position: relative;
`

export const Structures = styled.div`
  width: 100%;
  gap: ${({ isSticky }) => (isSticky ? '1.5rem' : '0.75rem')};
  display: flex;
`
export const CustomScrollBtn = styled(CustomButton)`
  position: absolute;
  right: ${({ right }) => right && right};
  left: ${({ left }) => left && left};
  top: ${({ isSticky }) => (isSticky ? '2.5rem' : '6.375rem')};
  z-index: 10;
  box-shadow: 0px 0px 4px 0px #2d374505, 0px -4px 12px 0px #2d37450a,
    0px 4px 12px 0px #2d37450a;
`
