import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  padding: ${({ isCompanyDatasetsLogging }) =>
    isCompanyDatasetsLogging ? '1rem' : '0rem 1.5rem 1rem 1.5rem'};
  background-color: ${({ theme, isCompanyDatasetsLogging }) =>
    isCompanyDatasetsLogging && theme.colors.backgrounds.secondary};
  border-radius: 0.75rem;
`

export const Line = styled.div`
  display: flex;
  gap: ${({ gap }) => gap || '0.25rem'};
  align-items: center;
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? 'space-between' : 'flex-start'};
  & > div:first-child {
    white-space: nowrap;
    color: ${({ withCustomValue, theme }) =>
      withCustomValue
        ? theme.colors.text.primary
        : theme.colors.text.secondary};
  }
`
