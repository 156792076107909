import styled from 'styled-components'
import CustomButton from 'components/common/customButton'
import Icon from 'components/Icon'

export const FilterContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  background: ${({ isOpen }) =>
    isOpen ? 'rgba(97, 110, 124, 0.08)' : 'transparent'};
  backdrop-filter: ${({ isOpen }) => (isOpen ? 'blur(6px)' : 'none')};

  z-index: 1000;
  transition: all 0.5s;
  overflow: hidden;
  color: #2d3745;
`

export const FilterBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 680px;
  height: calc(100% - 24px);
  background: white;

  position: absolute;
  right: 12px;
  top: 12px;
  bottom: 12px;

  border-radius: 12px;
  padding: 24px;

  transform: ${({ isOpen }) => (isOpen ? 'none' : 'translateX(100%)')};
  opacity: 1;
  transition: all 0.5s;
`
export const ExitButton = styled(Icon)`
  position: absolute;
  top: 16px;
  right: 16px;

  color: #cbd2d9;
  cursor: pointer;
  transition: all 200ms;
  &:hover {
    color: #42c1bf;
  }

  &:active {
    outline: none;
  }
`

export const Header = styled.div`
  color: #2d3745;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`

export const QueriesBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 24px;
  height: 100%;
`

export const Footer = styled.div`
  display: flex;
  gap: 8px;

  width: 100%;
  margin-top: 24px;
`

export const PlusIcon = styled(Icon)`
  margin-left: ${({ marginleft }) => marginleft ?? '0'};
  min-width: 16px;
`

export const AddButton = styled(CustomButton)`
  width: fit-content;
`

export const ConditionRelationsBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  border-left: 1px solid
    ${({ theme, borderColor }) =>
      borderColor ? borderColor : theme.colors.backgrounds.tertiary};
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  height: ${({ height }) => height && height};
`
export const ConditionRelationsInner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0.75rem;
  gap: 0.5rem;
`
