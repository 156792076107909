import {
  Subheadline,
  TextLarge,
  TitleTertiary,
} from 'components/common/text/index.style'
import { useRef } from 'react'

import {
  CardWrapper,
  CardMethodsWrapper,
  CardMethod,
  CardContent,
  ReactionCardHeader,
  ReactionCardTitle,
  Conditions,
  CardMethodsContainer,
  ReactionCardTools,
} from './index.style'
import Icon from '../Icon'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addSimpleNotification } from '../../store/actions/notifications'
import { useTheme } from 'styled-components'
import {
  setOpenReactionsDialog,
  setReactionsDialogConfig,
} from 'store/actions/reactionSearch'
import CustomButton from 'components/common/customButton'
import CustomScrollbar from 'components/CustomScrollbar'
import { REACTIONS_PAGINATION_LIMIT } from 'store/sagas/utils/config'
import { Row } from 'components/Applicability/index.style'
import CustomTag from 'components/common/customTag'
import { getCountNoun } from 'pages/Pdf2Smiles/config/config'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import { copyToClipboard } from 'utils/common/common'
import ReactionMethodInfo from './components/ReactionMethodInfo'
import LiteratureSource from './components/LiteratureSource'
import { ReactionSchema } from './components/ReactionSchema'
import { ConditionsBlock } from './components/ConditionsBlock'

const SearchReactionsCard = ({ data, idx }) => {
  const { id, reactants, products, conditions } = data || {}

  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const paginationState = useSelector((state) => state.search.pagination)
  const { activePage } = paginationState || {}
  const reactionRef = useRef(null)
  const conditionsQty = conditions?.length || 0

  const handleOpenDialog = () => {
    dispatch(setOpenReactionsDialog(true))
    dispatch(setReactionsDialogConfig(data))
  }

  const copyReactionPageLink = () => {
    copyToClipboard(id)
    dispatch(addSimpleNotification('notification.link_copied'))
  }

  const handleOpenMenu = (e) => {
    dispatch(
      handleShowContextMenu({
        e,
        menu: 'searchReactionCardMenu',
        item: { handler: copyReactionPageLink },
      })
    )
  }

  const toolsBtns = [
    {
      type: 'eyeOpen',
      onClick: handleOpenDialog,
    },
    // commented until reaction page
    // {
    //   type: 'threeDots',
    //   onClick: handleOpenMenu,
    // },
  ]

  return (
    <>
      <CardWrapper
        isFullMode={false}
        id="reaction-search-wrapper"
        padding="1rem"
        isHighlightTitle={true}
        minHeight={'34.5rem'}
      >
        <CardContent ref={reactionRef}>
          <ReactionCardHeader id="search-reactions-card-header">
            <ReactionCardTitle>
              <TitleTertiary
                className="header-title"
                fontWeight={500}
                style={{ maxWidth: 'calc(100% - 4.5rem)' }}
              >
                {`${t('filter.reaction.reaction')} ${
                  !activePage || activePage === 1
                    ? idx + 1
                    : (activePage - 1) * REACTIONS_PAGINATION_LIMIT + idx + 1
                }`}
              </TitleTertiary>
              <CustomTag type="grey">
                {t(
                  `filter.reaction.method_quantity.${getCountNoun(
                    conditionsQty
                  )}`,
                  {
                    count: conditionsQty,
                  }
                )}
              </CustomTag>
            </ReactionCardTitle>
            <ReactionCardTools>
              {toolsBtns.map(({ type, onClick }, i) => (
                <CustomButton
                  onClick={onClick}
                  key={i}
                  type={'icon_primary'}
                  width={'1.5rem'}
                  height={'1.5rem'}
                >
                  <Icon iconType={type} size={'1.25rem'} />
                </CustomButton>
              ))}
            </ReactionCardTools>
          </ReactionCardHeader>
          <ReactionSchema reactants={reactants} products={products} />
          <CardMethodsContainer>
            <CustomScrollbar
              noScrollY={true}
              noScrollX={false}
              style={{ height: '19.375rem' }}
              disableTrackXWidthCompensation={true}
            >
              <CardMethodsWrapper id="search-reactions-card-conditions">
                {conditions?.map((condition, index) => {
                  const { yield_percents, is_open_reaction_database } =
                    condition || {}

                  return (
                    <CardMethod key={id}>
                      <Row justifyContent="space-between">
                        <TextLarge fontWeight={theme.fontWeight.semibold}>{`${t(
                          'filter.reaction.method'
                        )} ${index + 1}`}</TextLarge>
                        <ReactionMethodInfo
                          yield_percents={yield_percents}
                          idx={idx}
                        />
                      </Row>
                      <Conditions>
                        <Subheadline fontWeight={500}>
                          {t('filter.reaction.reaction_conditions')}:
                        </Subheadline>
                        <ConditionsBlock
                          condition={condition}
                          id={id}
                          isSearchCard={true}
                        />
                      </Conditions>
                      <LiteratureSource source={condition?.source} />
                      <CustomButton
                        gap={'0.25rem'}
                        type={'text_accent'}
                        onClick={handleOpenDialog}
                      >
                        <div>{t('filter.reaction.reaction_protocol')}</div>
                        <Icon iconType={'arrowRight'} size={'1rem'} />
                      </CustomButton>
                    </CardMethod>
                  )
                })}
              </CardMethodsWrapper>
            </CustomScrollbar>
          </CardMethodsContainer>
        </CardContent>
      </CardWrapper>
    </>
  )
}

export default SearchReactionsCard
