import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { memo, useMemo, useRef } from 'react'
import {
  resetFilter,
  setOpenFilter,
  setFilterConfig,
  resetMarkushMoleculeWeight,
  setLeftFilterLitDocType,
} from 'store/actions/filter'
import Icon from 'components/Icon'
import { resetPagination, setSearchText } from 'store/actions/search'
import { setLiteratureSearch, setReactionsSearch } from 'store/actions/crud'
import { resetSearch, setSearchV2, setBingoSearch } from 'store/actions/crud'
import {
  FilterBody,
  StyledButton,
  FilterHeader,
  FilterWrapper,
  FilterButtons,
} from './index.style'
import { filterConfig, filterComponents } from './config'
import CustomButton from 'components/common/customButton'
import { setLitFilterConfig } from 'store/actions/literature'
import CustomBtnsScroller from 'components/common/customBtnsScroller'
import { css } from 'styled-components'

const Filter = memo(
  ({
    text,
    filters,
    filterOpen,
    searchType,
    resetSearch,
    setSearchV2,
    resetFilter,
    setOpenFilter,
    setBingoSearch,
    setFilterConfig,
    resetPagination,
    setLiteratureSearch,
    setLitFilterConfig,
    setSearchText,
    showHistory,
    resetMarkushMoleculeWeight,
    setLeftFilterLitDocType,
    setReactionsSearch,
  }) => {
    const { t } = useTranslation()

    const filterBodyRef = useRef(null)

    const handleClose = () => setOpenFilter(false)

    const handleSetFilters = (state) =>
      !filters.disabled && setFilterConfig({ ...filters, ...state })

    const isStructureSearch = searchType === 'structure'
    const isLiteratureSearch = searchType === 'literature'
    const isReactionsSearch = searchType === 'reaction'

    const handleMatchTypeSelect = (value) => {
      handleSetFilters({ match_type: value })
      //deprecate this
      if (
        value === 'markush structures' ||
        filters.match_type === 'markush structures'
      ) {
        setSearchText('')
        resetSearch()
      }
      if (value !== 'markush structures' && !filters.molecular_weight_disabled)
        resetMarkushMoleculeWeight()
    }

    const handleSearch = () => {
      resetSearch()
      resetPagination()
      if (isStructureSearch) {
        filters.match_type === 'exact match'
          ? setSearchV2({
              text,
              label: text,
            })
          : setBingoSearch({
              text,
              label: text,
            })
      } else if (isLiteratureSearch) {
        setLiteratureSearch({})
      } else if (isReactionsSearch) {
        setReactionsSearch({ text })
      }
    }

    const handleReset = () => {
      if (!filters.disabled) {
        resetFilter()
        setLitFilterConfig({})
        if (filters.match_type === 'markush structures') {
          setSearchText('')
          resetSearch()
        }

        if (searchType === 'literature')
          setLeftFilterLitDocType(['article', 'patent'])
      }
    }

    const filterOptions = useMemo(() => filterConfig[searchType], [searchType])

    return (
      <FilterWrapper opened={filterOpen}>
        <FilterHeader opened={filterOpen}>
          <span>{t('filter.title')}</span>
          <CustomButton gap={'0.25rem'} type={'text'} onClick={handleClose}>
            <Icon iconType={'arrowLeft'} size="1rem" />
            <span>{t('filter.collapse')}</span>
          </CustomButton>
        </FilterHeader>

        <CustomBtnsScroller
          innerRef={filterBodyRef}
          extraTopStyles={css`
            top: 3rem;
          `}
          extraBottomStyles={css`
            bottom: 4.75rem;
          `}
        >
          <FilterBody
            isLiteratureSearch={searchType === 'literature'}
            opened={filterOpen}
            ref={filterBodyRef}
            isDisabled={filters.disabled || showHistory}
          >
            {' '}
            {filterOptions?.map((el, key) => {
              const { type, config } = el
              const isHidden =
                (config.key === 'similarity' &&
                  filters.match_type !== 'similar structures') ||
                (config.key === 'molecular_weight' &&
                  filters.match_type !== 'markush structures')

              return (
                !isHidden && (
                  <div key={key}>
                    {filterComponents[type]({
                      setState: handleSetFilters,
                      state: filters,
                      searchType,
                      config,
                      ...(type === 'filterRadioList'
                        ? {
                            handleSelect: handleMatchTypeSelect,
                            disabled: filters.disabled,
                            id: 'filter-radio-match-type',
                          }
                        : {}),
                    })}
                  </div>
                )
              )
            })}
          </FilterBody>
        </CustomBtnsScroller>
        <FilterButtons opened={filterOpen}>
          <StyledButton
            id="left-filter-del-btn"
            type="secondary"
            onClick={handleReset}
            disabled={filters.disabled}
            gap="0.25rem"
          >
            {t('filter.reset')}
          </StyledButton>
          <StyledButton
            id="left-filter-apply-btn"
            type="secondary"
            onClick={handleSearch}
            disabled={filters.disabled || showHistory}
            gap="0.25rem"
          >
            {t('filter.applyFilters')}
          </StyledButton>
        </FilterButtons>
      </FilterWrapper>
    )
  }
)

const mapStateToProps = (state) => {
  return {
    text: state.search.searchText,
    filterOpen: state.filter.open,
    filters: state.filter.config,
    searchType: state.search.searchType,
    showHistory: state.searchHistory.showHistory,
  }
}

const mapDispatchToProps = {
  resetFilter,
  resetSearch,
  setOpenFilter,
  setFilterConfig,
  setSearchV2,
  setBingoSearch,
  setLiteratureSearch,
  resetPagination,
  setLitFilterConfig,
  setSearchText,
  resetMarkushMoleculeWeight,
  setLeftFilterLitDocType,
  setReactionsSearch,
}

Filter.displayName = 'Filter'
export default connect(mapStateToProps, mapDispatchToProps)(Filter)
