import { ru } from 'date-fns/locale'

import { format } from 'date-fns'

import { INNER_LANG_OPERATOR } from 'store/sagas/utils/config'
import { formatUtcDateWithTimeZone } from 'utils/dateTime/formatUtcDate'

export const getGroupedHistory = (history, userLang) => {
  const data = history?.map((el) => {
    const modifiedDate = formatUtcDateWithTimeZone(el.created_at)

    const date =
      userLang === 'ru'
        ? format(modifiedDate, 'dd MMMM yyyy года', {
            locale: ru,
          })
        : format(modifiedDate, 'MMMM d, yyyy', {})

    const time =
      userLang === 'ru'
        ? format(modifiedDate, 'HH:mm')
        : format(modifiedDate, 'h:mm a')
    return { ...el, date, time }
  })

  const groupedData = data.reduce((acc, curr) => {
    const { date, ...rest } = curr
    if (!acc[date]) {
      acc[date] = { date, values: [rest] }
    } else {
      acc[date].values.push(rest)
    }
    return acc
  }, {})
  return Object.values(groupedData)
}

export const getSearchText = (query) => {
  if (query.type === 'full_text_search') {
    if (query?.params?.title?.values) return query?.params?.title?.values[0]
    if (query?.params?.name) return query.params.name
  }

  const findValue = (conditions, field) => {
    if (Array.isArray(conditions)) {
      for (const condition of conditions) {
        const value = findValue(condition, field)
        if (value) return value
      }
    } else if (conditions.and_operator || conditions.or_operator) {
      const operator = conditions.and_operator || conditions.or_operator
      return findValue(operator, field)
    } else if (conditions.field === field) {
      return conditions.value
    }
    return null
  }

  if (query.type === 'advanced_text_search') {
    const conditions = query.params.query.and_operator
    const titleValue =
      findValue(conditions, 'title.ru') || findValue(conditions, 'title.en')
    const nameValue = findValue(conditions, 'name')

    if (titleValue) return titleValue
    if (nameValue) return nameValue

    return ''
  }

  if (query.type === 'molecules_search') {
    if (query?.params?.searchV2?.text) return query.params.searchV2.text
  }

  return ''
}

export const getSearchSmiles = (query) => {
  if (query.type === 'advanced_text_search')
    return query?.params?.structures_ids?.smiles_list?.[0] || ''

  return (
    query?.params?.smiles ||
    query?.params?.structures_ids?.smiles_list?.[0] ||
    query?.params?.searchV2?.smiles
  )
}
