import { memo } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import CustomTag from 'components/common/customTag'

import { getTitle, getValue } from './utils/utils'
import { Block, DataElement, DataLabel } from './index.style'

const TlightParams = memo(({ num, params, withColors, tagType }) => {
  const ParamsForRender = ({ withValue }) => (
    <Block {...{ withValue }}>
      {Object.entries(JSON.parse(params)).map(([key, value], idx) => {
        return (
          <DataElement key={idx} title={getTitle(key, value)}>
            <div>{getValue(value, withColors)}</div>
            {withValue && <DataLabel>{key}</DataLabel>}
          </DataElement>
        )
      })}
    </Block>
  )

  if (!(params && num && Object.entries(JSON.parse(params))?.length))
    return <></>

  return (
    <>
      <CustomTag
        width="fit-content"
        height="fit-content"
        data-tooltip-id={`${num}-params`}
        type={tagType}
      >
        <ParamsForRender />
      </CustomTag>
      <ReactTooltip
        id={`${num}-params`}
        className="c-tooltip c-tooltip-base"
        classNameArrow="c-tooltip-arrow"
        place={'top-start'}
        offset={14}
      >
        <ParamsForRender withValue={true} />
      </ReactTooltip>
    </>
  )
})

TlightParams.displayName = 'TlightParams'
export default TlightParams
