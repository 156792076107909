import { useTranslation } from 'react-i18next'

import PropertyKey from 'components/PropertyKey'
import { HEADERS_EXCEPTIONS } from 'config/molecule/molecule'

import { KeyValueProperty, ColoredSubheadline } from './index.style'

const HeadersProperty = ({ text, type, color, value, units }) => {
  const { t } = useTranslation()

  return (
    <KeyValueProperty className="molecule-key-value-property">
      <PropertyKey
        {...{ text, type, color }}
        textForRender={t(`molviewer.${text}`)}
      />
      {value !== undefined && (
        <ColoredSubheadline color={'#1F2933'} status={color}>
          {value === 'not included' ? t(`molviewer.not_included`) : value}
          {units &&
            !HEADERS_EXCEPTIONS.units.includes(units) &&
            ` ${t(`molviewer.${units}`)}`}
        </ColoredSubheadline>
      )}
    </KeyValueProperty>
  )
}

export default HeadersProperty
