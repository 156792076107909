import styled from 'styled-components'

export const PagePreviewWrapper = styled.div`
  position: absolute;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PagePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 24.5rem;
  height: 19rem;
  row-gap: 1.25rem;
  align-items: center;
`

export const IconWrapper = styled.div`
  display: flex;
  width: 10rem;
  & > svg {
    width: 10rem;
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
`

export const PagePreviewTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  div {
    white-space: pre-line;
  }
`
