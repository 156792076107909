import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import { formatUtcDateWithTimeZone } from './formatUtcDate'

export const formatDateWithLocale = (initDate, userLang) => {
  if (!initDate) return { date: '', time: '' }
  const modifiedDate = formatUtcDateWithTimeZone(initDate)
  const date =
    userLang === 'ru'
      ? format(modifiedDate, 'dd MMMM yyyy года', {
          locale: ru,
        })
      : format(modifiedDate, 'MMMM d, yyyy', {})

  const time =
    userLang === 'ru'
      ? format(modifiedDate, 'HH:mm')
      : format(modifiedDate, 'h:mm a')

  return { date, time }
}
