import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  CATEGORIES_WITH_DESC,
  getCustomHeadersDescription,
} from 'config/molecule/molecule'
import Icon from 'components/Icon'
import { Subheadline } from 'components/common/text/index.style'

import { DescriptedPropertyKey } from './index.style'

const PropertyName = ({
  id,
  name,
  color,
  className,
  categoryKey,
  tooltipPlace = 'bottom',
}) => {
  return CATEGORIES_WITH_DESC.includes(name) ? (
    <div>
      <DescriptedPropertyKey data-tooltip-id={id} className={className}>
        <Subheadline color={color}>{categoryKey}</Subheadline>
        <Icon iconType={'description'} />
      </DescriptedPropertyKey>

      <ReactTooltip
        id={id}
        clickable={true}
        delayHide={100}
        className="c-tooltip c-tooltip-base c-tooltip-z-max"
        classNameArrow="c-tooltip-arrow"
        place={tooltipPlace}
        positionStrategy={'fixed'}
      >
        {getCustomHeadersDescription(name)}
      </ReactTooltip>
    </div>
  ) : (
    <Subheadline color={color} fontSize="0.75rem" className={'property-name'}>
      {categoryKey}
    </Subheadline>
  )
}

export default PropertyName
