import i18n from 'i18n'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, Fragment, useMemo } from 'react'

import {
  selectBasket as selectBasketDispatch,
  setSelected,
} from 'store/actions/basket'
import {
  handleShowContextMenu,
  updateDisabledActions,
} from 'store/actions/contextMenu'
import Icon from 'components/Icon'
import { formatDate } from 'utils/dateTime/formatDate'
import { Caption, TextMedium } from 'components/common/text/index.style'

import {
  BasketBody,
  BasketSize,
  BasketName,
  ProgressBar,
  DateWithBtn,
  BasketWrapper,
  BasketTopArea,
  OpenBasketButton,
  BasketBottomArea,
  BasketToolsButton,
  BasketDescription,
  BasketNameWithDescription,
} from './index.style'
import { getTitle } from './utils'
import { readStorage } from 'utils/storage/storage'

const Basket = ({
  bask,
  tasksStatus,
  push,
  isSelectingNow,
  selectBaskets,
  basketType,
}) => {
  const theme = useTheme()
  const role = readStorage('role')

  const [progress, setProgress] = useState(0)
  const selected = useSelector((store) => store.basket.selected)
  const contextMenuState = useSelector(
    (store) => store.contextMenu.contextMenuState
  )

  const dispatch = useDispatch()
  const hasRunningTask =
    tasksStatus?.length > 0 &&
    tasksStatus?.some(
      (task) => task?.basket_id === bask?.id && task?.progress !== 100
    )
  const userLang = i18n.language

  const disabledActions = {
    deselect_dataset: hasRunningTask,
    select_dataset: hasRunningTask,
    edit_dataset: hasRunningTask,
    view_log:
      hasRunningTask ||
      (basketType === 'companies' &&
        !(role === 'customer_admin' || role === 'syn_admin')),

    delete_dataset: hasRunningTask,
  }

  useEffect(() => {
    const { task_id } = bask
    if (task_id) {
      const taskStatus = tasksStatus.filter(
        (taskStatus) => task_id === taskStatus.id.toString()
      )

      if (taskStatus.length !== 0) {
        setProgress(taskStatus[0].progress)
      }
    }
  }, [tasksStatus, bask])

  useEffect(() => {
    if (contextMenuState?.menu === 'dataSetMenu') {
      dispatch(updateDisabledActions(disabledActions))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRunningTask])

  const handleBasketClick = (e, bask) => {
    if (isBasketLoading && tasksStatus.length) return
    if (!e) return

    e.stopPropagation()

    // no selection for public and pending baskets
    if (e.ctrlKey || e.metaKey) {
      !bask.public && !isBasketLoading && selectBasket(bask)
      return
    }

    if (e.shiftKey) {
      !bask.public && selectBaskets()
      return
    }

    dispatch(selectBasketDispatch(bask))
    push(`/molecules/${bask.id}`)
  }

  const selectBasket = (bask) => {
    let data
    if (!selected.includes(bask.id)) {
      data = [...selected, bask.id]
    } else {
      data = selected.filter((el) => el !== bask.id)
    }
    dispatch(setSelected(data))
  }

  const loadingStatuses = ['initiated', 'pending', 'running']
  const basketActualStatus = tasksStatus.find(
    (taskStatus) => taskStatus?.basket_id === bask.id
  )?.status

  const isBasketLoading = loadingStatuses?.some(
    (status) => status === basketActualStatus
  )
  const name = useMemo(
    () => (userLang === 'ru' && bask.name_ru ? bask.name_ru : bask.name),
    [bask.name, bask.name_ru, userLang]
  )

  const info = useMemo(
    () => (userLang === 'ru' && bask.info_ru ? bask.info_ru : bask.info),
    [bask.info, bask.info_ru, userLang]
  )

  return (
    <>
      <BasketWrapper
        key={bask.id}
        data-test="basket"
        onClick={(e) => handleBasketClick(e, bask)}
        isSelected={selected.includes(bask.id)}
        isSelectingNow={isSelectingNow}
      >
        <BasketBody>
          <BasketTopArea>
            <BasketSize>
              <Caption
                lineHeight={theme.text[14]}
                fontWeight={theme.fontWeight.medium}
              >
                {bask.count
                  .toString()
                  .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}{' '}
                {getTitle(bask.count)}
              </Caption>
            </BasketSize>
            {!bask.public && (
              <BasketToolsButton
                isActive={
                  contextMenuState?.menu === 'dataSetMenu' &&
                  contextMenuState?.item?.bask?.id === bask?.id
                }
              >
                <Icon
                  dataTest="basket-context-menu"
                  iconType="threeDots"
                  onClick={(e) => {
                    e.stopPropagation()
                    dispatch(
                      handleShowContextMenu({
                        e,
                        menu: 'dataSetMenu',
                        item: {
                          bask,
                          disabledActions,
                        },
                      })
                    )
                  }}
                />
              </BasketToolsButton>
            )}
          </BasketTopArea>
          <BasketNameWithDescription>
            <BasketName data-test="basket-title">{name}</BasketName>
            <BasketDescription data-test="basket-desc" title={info}>
              <TextMedium
                fontWeight={theme.fontWeight.light}
                color={theme.colors.text.secondary}
              >
                {info}
              </TextMedium>
            </BasketDescription>
          </BasketNameWithDescription>
        </BasketBody>

        <BasketBottomArea>
          {isBasketLoading && tasksStatus.length ? (
            <ProgressBar>
              <span style={{ width: `${Math.round(progress || 0)}%` }}></span>
            </ProgressBar>
          ) : (
            <DateWithBtn>
              <Caption
                lineHeight={theme.text[14]}
                color={theme.colors.text.tertiary}
                fontWeight={theme.fontWeight.medium}
              >
                {formatDate(new Date(bask.created))}
              </Caption>
              <OpenBasketButton>
                <Icon iconType="arrowRight" size="1.25rem" />
              </OpenBasketButton>
            </DateWithBtn>
          )}
        </BasketBottomArea>
      </BasketWrapper>
    </>
  )
}

export default Basket
