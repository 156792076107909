import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import Icon from 'components/Icon'
import { Source } from 'components/SearchReactionsCard/index.style'
import { Label } from 'components/common/text/index.style'
import { useDispatch } from 'react-redux'
import { addSimpleNotification } from 'store/actions/notifications'

const LiteratureSource = ({ source }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <Source>
      <Label>{t('filter.reaction.lit_link')}:</Label>
      <Label
        color={source ? undefined : theme.colors.statuses.attention}
        ellipsis
        minWidth={'0'}
      >
        {source ? source : t('filter.reaction.conditions.no_information')}
      </Label>
      {source && (
        <Icon
          className="copy-name-icon"
          iconType="copy"
          size="1rem"
          dataTest="reaction-card-copy-btn"
          onClick={(e) => {
            e.stopPropagation()
            navigator.clipboard.writeText(source)
            dispatch(addSimpleNotification(`notification.successfully_copied`))
          }}
        />
      )}
    </Source>
  )
}

export default LiteratureSource
