import { useState } from 'react'
import { useTheme } from 'styled-components'

import Icon from 'components/Icon'
import CompareSvg from 'assets/compareSvg'
import NotificationSvg from 'assets/notificationSvg'
import { Label } from 'components/common/text/index.style'

import { ButtonWrapper, Count } from './index.style'

const SidebarButtonWithCount = ({
  count,
  bottom,
  onClick,
  visible,
  iconType,
}) => {
  const theme = useTheme()
  const [isHovered, setIsHovered] = useState(false)

  const mainColor = isHovered
    ? theme.colors.icons.accentSecondary
    : theme.colors.icons.tertiary

  const additionalColor = isHovered
    ? theme.colors.icons.accentPrimary
    : theme.colors.icons.secondary

  const IconComponent = () => {
    if (iconType === 'notification')
      return <NotificationSvg {...{ mainColor, additionalColor }} />
    if (iconType === 'compare')
      return <CompareSvg {...{ mainColor, additionalColor }} />
    return <Icon iconType={iconType} size="1.75rem" />
  }

  return (
    <ButtonWrapper
      type="icon_primary"
      onClick={onClick}
      visible={visible}
      width="2.75rem"
      height="2.75rem"
      bottom={bottom}
      isNotificationsBtn={iconType === 'notification'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="sidebar-button-with-count"
    >
      <IconComponent />
      {count > 0 && (
        <Count>
          <Label
            color={theme.colors.text.white}
            fontWeight={theme.fontWeight.semibold}
          >
            {count}
          </Label>
        </Count>
      )}
    </ButtonWrapper>
  )
}

export default SidebarButtonWithCount
