import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  closeAddMoleculeDialog,
  openAddMoleculeDialog,
} from 'store/actions/molecule'
import { addMolecule } from 'store/actions/crud'
import TlightParams from 'components/TlightParams'
import BasketsListModal from 'components/BasketsListModal'
import MoleculeStructure from 'components/MoleculeStructure'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import MoleculeIdWithButtons from 'components/MoleculeIdWithButtons'
import { uncompareStructure } from 'store/actions/structuresCompare'
import { CANVAS_SECONDARY_COLOR } from 'components/MoleculeViewer/config/config'

import {
  Info,
  InfoRow,
  Molecule,
  Container,
  InfoColumn,
  TlightWrapper,
  SmilesViewContainer,
} from './index.style'
import {
  DEFAULT_MOLECULE_HEIGHT,
  DEFAULT_MOLECULE_WIDTH,
  STICKY_MOLECULE_HEIGHT,
  STICKY_MOLECULE_WIDTH,
} from '../StructuresList/config/config'

const StructureViewer = ({ num, smiles, params, isSticky }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const moleculeDialogId = useSelector(
    (state) => state.molecule.isAddMoleculeDialogOpen
  )

  const isAddMoleculeDialogOpen = (num || smiles) === moleculeDialogId

  const handleAddMolecule = (e) => {
    e?.stopPropagation()
    dispatch(openAddMoleculeDialog(num))
  }

  const handleAddMoleculeToBasket = (ids, newBasketName) => {
    const smilesArray = [
      {
        smiles,
      },
    ]

    if (ids?.length) {
      ids.forEach((el) =>
        dispatch(addMolecule(el?.id, smilesArray, false, el?.name, t))
      )
    } else {
      dispatch(addMolecule(-1, smilesArray, false, newBasketName, t))
    }
  }

  const handleDelete = () => {
    dispatch(uncompareStructure(num, false))
  }

  const toggleMenu = (e) => {
    e.stopPropagation()
    dispatch(
      handleShowContextMenu({
        e,
        menu: 'moleculeMenu',
        item: {
          num,
          smiles,
          isNotShowAddToCompareItem: true,
        },
      })
    )
  }

  const iconButtonsConfig = [
    {
      icon: 'add',
      onClick: handleAddMolecule,
      tooltipText: t('molecule_viewer.add_to_dataset'),
      dataTest: 'molecule-card-btn-add-to-dataset-div',
    },
    {
      icon: 'deleted',
      onClick: handleDelete,
      tooltipText: t('molecule_viewer.delete_from_compare'),
      dataTest: 'molecule-card-btn-delete-div',
    },
    {
      icon: 'threeDots',
      onClick: toggleMenu,
      tooltipText: '',
      dataTest: 'molecule-card-btn-context-menu-div',
    },
  ]

  return (
    <>
      <Container isSticky={isSticky}>
        {isSticky ? (
          <Molecule isSticky={isSticky}>
            <InfoRow>
              <SmilesViewContainer className="canvas-container" isSticky={true}>
                <MoleculeStructure
                  structure={smiles}
                  width={
                    isSticky ? STICKY_MOLECULE_WIDTH : DEFAULT_MOLECULE_WIDTH
                  }
                  height={
                    isSticky ? STICKY_MOLECULE_HEIGHT : DEFAULT_MOLECULE_HEIGHT
                  }
                  fixedCanvas={true}
                  extraDetails={{ backgroundColour: CANVAS_SECONDARY_COLOR }}
                />
              </SmilesViewContainer>

              <InfoColumn>
                <MoleculeIdWithButtons
                  {...{
                    num,
                    toggleMenu,
                    iconButtonsConfig,
                  }}
                  noTagPadding={true}
                  showMenuWithoutId={true}
                />
                <InfoColumn gap="0.5rem">
                  <MoleculeIdWithButtons
                    {...{
                      num,
                      toggleMenu,
                      iconButtonsConfig,
                    }}
                    noTagPadding={true}
                    showIdWithoutMenu={true}
                    tagType="white"
                  />

                  <TlightParams
                    {...{ num, params }}
                    withColors={true}
                    tagType="white"
                  />
                </InfoColumn>
              </InfoColumn>
            </InfoRow>
          </Molecule>
        ) : (
          <Molecule>
            <Info>
              <MoleculeIdWithButtons
                {...{
                  num,
                  toggleMenu,
                  iconButtonsConfig,
                }}
              />
              <SmilesViewContainer
                className="canvas-container"
                isSticky={false}
              >
                <MoleculeStructure
                  structure={smiles}
                  width={
                    isSticky ? STICKY_MOLECULE_WIDTH : DEFAULT_MOLECULE_WIDTH
                  }
                  height={
                    isSticky ? STICKY_MOLECULE_HEIGHT : DEFAULT_MOLECULE_HEIGHT
                  }
                  fixedCanvas={true}
                />
              </SmilesViewContainer>
              <TlightWrapper>
                <TlightParams {...{ num, params }} withColors={true} />
              </TlightWrapper>
            </Info>
          </Molecule>
        )}
      </Container>

      {isAddMoleculeDialogOpen && (
        <BasketsListModal
          onAgree={handleAddMoleculeToBasket}
          onClose={() => dispatch(closeAddMoleculeDialog())}
          withPublic={false}
          withNew={true}
          actionText={t('molecule_viewer.add_to_dataset')}
          onlyOne
          currentBasketId={null}
        />
      )}
    </>
  )
}

export default StructureViewer
