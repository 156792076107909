export const apiPath = '/api/v1/'
export const apiV2Path = '/api/v2/'

export const DEBUG = process.env.REACT_APP_DEBUG === 'true'
export const ANALYTICS_DISABLED = process.env.REACT_APP_ANALYTICS_DISABLED === 'true'

export const WITH_LOGGER = false

// eslint-disable-next-line no-undef
export const HOST = DEBUG
  ? `${window.location.hostname}`
  : // eslint-disable-next-line no-undef
    REACT_APP_BACKEND

export const BASE_URL = DEBUG ? `http://${HOST}` : `https://${HOST}`

export const API_URL = `${BASE_URL}${apiPath}`
export const API_V2_URL = `${BASE_URL}${apiV2Path}`

export const HEADERS_CONFIG = {
  lipinski: [
    'Hydrogen bond donors <= 5',
    'Hydrogen bond acceptors <= 10',
    'Molecular weight < 500',
    'LogP octanol-water <= 5',
  ],

  ghose: [
    'LogP octanol-water [-0.4, 5.6]',
    'Molecular weight [180, 480]',
    'Number of atoms [20, 70]',
    'Molar refractivity [40, 130]',
  ],

  oprea: [
    'Hydrogen bond donors <= 2',
    'Hydrogen bond acceptors [2, 9]',
    'Number of rotatable bonds [2, 8]',
    'Number of rings [1, 4]',
  ],
  veber: [
    'Number of rotatable bonds <= 10',
    // 'Topological polar surface area (including sulphur and phosphorus)',
    'TPSA <= 140 Å²',
  ],
  pains: [],
  qed: [],
}
