import styled from 'styled-components'

export const Panel = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  padding-top: 0.5rem;
  padding-bottom: 0.75rem;

  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
`
