const CompareSvg = ({ mainColor, additionalColor }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.832 6.70834H22.7487M16.332 11.375H22.7487M12.832 21.2917H22.7487M16.332 16.625H22.7487"
        stroke={mainColor}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
      <path
        d="M9.33107 9.91666V14M9.33107 14V18.0833M9.33107 14H5.25M9.33107 14H13.4167"
        stroke={additionalColor}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default CompareSvg
