import { useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Rect } from '../../index.style'
import { scaleLinear } from '@visx/scale'
import { AxisLeft, AxisBottom } from '@visx/axis'
import { GridRows, GridColumns } from '@visx/grid'
import { withResizeDetector } from 'react-resize-detector'
import { useTranslation } from 'react-i18next'
import { setNmrChartActiveAtom } from 'store/actions/spectra'
import { SPECTRA_NMR_CHART, TABLE_WIDTH } from '../../config'
import { Zoom } from '@visx/zoom'
import { getScrollScale } from 'pages/SpectraPrediction/helpers'
import { useTheme } from 'styled-components'

const initialTransform = {
  scaleX: 1,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  skewX: 0,
  skewY: 0,
}

const NmrChart = ({
  nmrData,
  activeAtoms,
  width = TABLE_WIDTH,
  duplicates,
}) => {
  const chartWidth = width - TABLE_WIDTH
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useTheme()

  const ICONS_SEC = theme.colors.icons.secondary
  const TEXT_PRIMARY = theme.colors.text.primary
  const TEXT_ACCENT = theme.colors.text.accentPrimary
  const BACK_SEC = theme.colors.backgrounds.secondary

  const height = 464
  const margin = { top: 0, right: 16, bottom: 40, left: 40 }

  const shiftValues = nmrData.shifts
  const intensities = nmrData.intensities
  const atom_index = nmrData.atom_index

  const minShift = Math.min(...shiftValues) - 3
  const maxShift = Math.max(...shiftValues) + 3

  // find the top offset multiplier for the Y axis to display the column numbers
  const topOffsetCount = useMemo(() => {
    const maxIntensity = Math.max(...intensities)
    if (maxIntensity < 6) return 0.5
    else return maxIntensity * 0.1
  }, [intensities, shiftValues])

  const handleMouseOver = (shift) => {
    dispatch(setNmrChartActiveAtom(duplicates[shift]))
  }

  const handleMouseOut = () => {
    dispatch(setNmrChartActiveAtom([]))
  }

  return (
    <Zoom
      width={width}
      height={height}
      scaleXMin={1}
      scaleXMax={50}
      scaleYMin={1}
      scaleYMax={1}
      transformMatrix={initialTransform}
    >
      {(zoom) => {
        const rescaleXAxis = (scale, zoom) => {
          let newDomain = scale.range().map((r) => {
            return scale.invert(
              (r - zoom.transformMatrix.translateX) /
                zoom.transformMatrix.scaleX
            )
          })
          return scale.copy().domain(newDomain)
        }

        const xScale = scaleLinear({
          range: [chartWidth - margin.right, margin.left],
          domain: [minShift, maxShift],
        })

        const zoomedScaleX = rescaleXAxis(xScale, zoom)

        const getTranslate = (index) => {
          const x = getTranslateX(index)
          const y = getTranslateY(index)
          return `translate(${x}, -${y})`
        }

        const sortedShiftValues = shiftValues
          .map((el, index) => {
            return { value: el, index }
          })
          .sort((a, b) => {
            return a.value - b.value
          })

        const getTranslateX = (index) => {
          if (duplicates[shiftValues[index].toString()].length > 1) {
            const duplicatesArray = duplicates[shiftValues[index]]
            const index2 = duplicatesArray.findIndex((el) => el === index)

            if (duplicatesArray.length % 2) {
              return (index2 - Math.floor(duplicatesArray.length / 2)) * 14
            } else {
              const centeredTranslateX = -6
              return (
                (index2 - (duplicatesArray.length / 2 - 1)) * 14 +
                centeredTranslateX
              )
            }
          } else {
            return 0
          }
        }

        const getTranslateY = (index) => {
          const defaultTranslate = 0
          const sortedIndex = sortedShiftValues.findIndex((el) => {
            return el.index === index
          })
          const sortedCurrentEl = sortedShiftValues[sortedIndex]
          const sortedNextEl =
            sortedShiftValues.length === sortedIndex + 1
              ? null
              : sortedShiftValues[sortedIndex + 1]

          if (duplicates[shiftValues[index].toString()].length > 1) {
            return defaultTranslate
          }

          if (!sortedNextEl) {
            return defaultTranslate
          }

          if (
            zoomedScaleX(sortedCurrentEl.value) -
              zoomedScaleX(sortedNextEl.value) <
              14 &&
            yScale(intensities[sortedCurrentEl.index]) ===
              yScale(intensities[sortedNextEl.index])
          ) {
            return 24 + getTranslateY(sortedNextEl.index)
          }

          return defaultTranslate
        }

        const yScale = scaleLinear({
          domain: [0, Math.max(...intensities) + topOffsetCount],
          range: [height - margin.bottom, margin.top],
        })

        return (
          <svg
            width={chartWidth}
            height={height}
            ref={zoom.containerRef}
            style={{ userSelect: 'none' }}
            onWheel={(e) => {
              getScrollScale(e, zoom)
            }}
            onTouchStart={zoom.dragStart}
            onTouchMove={zoom.dragMove}
            onTouchEnd={zoom.dragEnd}
            onMouseDown={(e) => {
              zoom.dragStart(e)
            }}
            onMouseMove={zoom.dragMove}
            onMouseUp={zoom.dragEnd}
            onMouseLeave={() => {
              if (zoom.isDragging) zoom.dragEnd()
            }}
            id={SPECTRA_NMR_CHART}
          >
            <GridRows
              scale={yScale}
              left={margin.left}
              width={chartWidth - margin.left - margin.right}
              numTicks={10}
              stroke={BACK_SEC}
              strokeWidth={1}
              strokeOpacity={1}
            />
            <GridColumns
              scale={zoomedScaleX}
              top={height - margin.bottom}
              height={-height + margin.top + margin.bottom}
              width={chartWidth - margin.left - margin.right}
              numTicks={5}
              stroke={BACK_SEC}
              strokeWidth={1}
              strokeOpacity={1}
              tickValues={zoomedScaleX.ticks()}
            />
            <rect
              x={chartWidth - margin.right}
              y={margin.top}
              width={1}
              height={height - margin.top - margin.bottom}
              fill={BACK_SEC}
            />

            {sortedShiftValues.map((shift, index) => {
              const translate = getTranslate(shift.index)
              const translateX = getTranslateX(shift.index)
              const zoomedX = zoomedScaleX(shift.value)
              const calculatedY = yScale(intensities[shift.index])

              const clipPathXF = () => {
                // calc x offset for duplicates by shifts || returns number
                if (duplicates[shiftValues[index]].length > 1) {
                  if (zoomedX > margin.left - translateX + 14) {
                    return zoomedX - 14
                  } else {
                    return zoomedX - (zoomedX + translateX - margin.left)
                  }
                }
                // calc x offset for single shift || returns number
                return (
                  zoomedX -
                  (zoomedX > margin.left + 14 ? 14 : zoomedX - margin.left)
                )
              }
              const clipPathX = clipPathXF()

              const currentDomain = zoomedScaleX.domain()

              // limit the display of columns outside the borders

              if (
                shift.value < currentDomain[1] &&
                shift.value > currentDomain[0]
              ) {
                return (
                  <g
                    key={`${index}-${shift.value}-g`}
                    onMouseOver={() => handleMouseOver(shift.value)}
                    onMouseOut={() => handleMouseOut(shift.value)}
                  >
                    <Rect
                      id={`${index}-${shift.value}`}
                      x={zoomedX}
                      y={calculatedY}
                      width={1}
                      height={height - margin.bottom - calculatedY}
                      fill={
                        activeAtoms.includes(shift.index)
                          ? TEXT_ACCENT
                          : ICONS_SEC
                      }
                    />
                    <Rect
                      id={`${index}-${shift.value}_1`}
                      x={zoomedX}
                      y={calculatedY - 4}
                      width={8}
                      height={height - margin.bottom - margin.top}
                      fill="transparent"
                    />
                    {
                      <defs>
                        <clipPath id={`${index}-${shift.value}-clip`}>
                          <rect
                            x={clipPathX}
                            y={calculatedY - 36}
                            width="36"
                            height="36"
                          />
                        </clipPath>
                      </defs>
                    }

                    <g
                      x={zoomedX}
                      y={yScale(intensities[index] + 0.3)}
                      clipPath={`url(#${index}-${shift.value}-clip)`}
                      transform={translate}
                    >
                      <filter
                        id="filter"
                        x="-20%"
                        y="-20%"
                        width="140%"
                        height="140%"
                        filterUnits="objectBoundingBox"
                        primitiveUnits="userSpaceOnUse"
                        colorInterpolationFilters="linearRGB"
                      >
                        <feDropShadow
                          stdDeviation="4 4"
                          in="SourceGraphic"
                          dx="0"
                          dy="0"
                          floodColor={TEXT_PRIMARY}
                          floodOpacity="0.06"
                          x="0%"
                          y="0%"
                          width="100%"
                          height="100%"
                          result="dropShadow1"
                        />
                        <feDropShadow
                          stdDeviation="8 8"
                          in="dropShadow1"
                          dx="0"
                          dy="-4"
                          floodColor={TEXT_PRIMARY}
                          floodOpacity="0.02"
                          x="0%"
                          y="0%"
                          width="100%"
                          height="100%"
                          result="dropShadow"
                        />
                        <feDropShadow
                          stdDeviation="8 8"
                          in="dropShadow1"
                          dx="0"
                          dy="-4"
                          floodColor="#1f2933"
                          floodOpacity="0.02"
                          x="0%"
                          y="0%"
                          width="100%"
                          height="100%"
                          result="dropShadow3"
                        />
                      </filter>

                      <Rect
                        filter="url(#filter)"
                        x={zoomedX}
                        y={calculatedY - 24}
                        rx={8}
                        width="20"
                        height="20"
                        fill="#ffffff"
                        transform={'translate(-10)'}
                      />

                      <text
                        x={zoomedX}
                        y={calculatedY - 12}
                        fontSize="11"
                        fill={
                          activeAtoms.includes(shift.index)
                            ? TEXT_ACCENT
                            : ICONS_SEC
                        }
                        textAnchor="middle"
                        alignmentBaseline="middle"
                      >
                        {atom_index[shift.index]}
                      </text>
                    </g>
                  </g>
                )
              }
              return null
            })}

            <AxisBottom
              scale={zoomedScaleX}
              top={height - margin.bottom}
              tickFormat={(value) => value}
              strok={ICONS_SEC}
              hideTicks={true}
              tickLabelProps={() => ({
                fill: ICONS_SEC,
                fontSize: 11,
                textAnchor: 'end',
                fontWeight: 400,
                fontFamily: 'Geologica Cursive',
                lineHeight: 14,
              })}
              label={t('spectra.chart.shift')}
              labelProps={{
                fontSize: 11,
                lineHeight: 14,
                fontWeight: 400,
                fill: TEXT_PRIMARY,
                fontFamily: 'Geologica Cursive',
                textAnchor: 'middle',
                x: (width - 250) / 2,
              }}
            />

            <AxisLeft
              scale={yScale}
              left={margin.left}
              numTicks={Math.max(...intensities)}
              hideZero={false}
              tickFormat={(value) => value}
              stroke={ICONS_SEC}
              hideTicks={true}
              tickLabelProps={() => ({
                fill: ICONS_SEC,
                fontSize: 11,
                textAnchor: 'end',
                fontWeight: 400,
                fontFamily: 'Geologica Cursive',
                lineHeight: 14,
                angle: -90,
              })}
              label={t('spectra.chart.relative_intensity')}
              labelOffset={20}
              labelProps={{
                fontSize: 12,
                lineHeight: 16,
                fontWeight: 500,
                fill: TEXT_PRIMARY,
                textAnchor: 'middle',
                fontFamily: 'Montserrat',
                x: -height / 2,
                letterSpacing: -0.144,
              }}
            />
          </svg>
        )
      }}
    </Zoom>
  )
}

const mapStateToProps = (state) => {
  return {
    nmrData: state.spectra.nmr.data,
    activeAtoms: state.spectra.nmr.chartActiveAtoms,
  }
}

export default connect(mapStateToProps)(withResizeDetector(NmrChart))
