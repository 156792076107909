import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'

import { store } from 'services/rest'
import CustomScrollbar from 'components/CustomScrollbar'
import DatasetInfo from 'components/DatasetLogs/DatasetInfo'
import LogsContent from 'components/DatasetLogs/LogsContent'
import { addNotification } from 'store/actions/notifications'

import { Content } from './index.style'
import FooterButtons from './FooterButtons'
import { DATASET_LOGS_PREFIX } from './config/config'

const DatasetLogs = ({
  datasetData,
  datasetId,
  basketType,
  onTransfer,
  onCalculate,
  isCompanyLogsLoading,
  hasRunningTask,
  isDatasetCalculating,
  isCompanyDatasetsLogging,
}) => {
  const dispatch = useDispatch()
  const [isExpanded, setIsExpanded] = useState(
    isCompanyDatasetsLogging ? false : true
  )
  const [datasetInfo, setDatasetInfo] = useState()

  const [logs, setLogs] = useState()
  const [logsLoadingStatus, setLogsLoadingStatus] = useState(
    isCompanyDatasetsLogging ? '' : 'loading'
  )

  useEffect(() => {
    if (datasetData && !datasetInfo) setDatasetInfo(datasetData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetData])

  const { name, info, created, molecules_count, is_basket_calculated } =
    datasetInfo || {}

  const loadLogs = async () => {
    try {
      const { data } = await store(`/baskets_log/${datasetId}`, {
        params: {
          basket_id: datasetId,
        },
      })
      const { items, ...rest } = data.result
      setDatasetInfo(rest)
      setLogs(items)
      setLogsLoadingStatus('success')
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: `${DATASET_LOGS_PREFIX}failed_to_load_logs`,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }
      dispatch(addNotification(notify))
    }
  }

  useEffect(() => {
    if (datasetId && isExpanded && !isDatasetCalculating) {
      if (isCompanyDatasetsLogging && logs) return
      setLogsLoadingStatus('loading')
      loadLogs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasetId, isDatasetCalculating, isExpanded])

  if (isCompanyDatasetsLogging)
    return (
      <Content isCompanyDatasetsLogging={isCompanyDatasetsLogging}>
        <DatasetInfo
          {...{
            name,
            info,
            created,
            logsLoadingStatus,
            isCompanyLogsLoading,
            isCompanyDatasetsLogging,
          }}
          isExpanded={isExpanded}
          moleculesCount={molecules_count}
          isDatasetCalculated={is_basket_calculated}
          onExpand={() => setIsExpanded((prev) => !prev)}
          isCopy={
            isCompanyDatasetsLogging
              ? false
              : logs?.some((el) => el?.request_type === 'copy')
          }
        />

        {isExpanded && (
          <LogsContent
            {...{
              basketType,
              logsLoadingStatus,
              isCompanyDatasetsLogging,
            }}
            logList={logs || []}
          />
        )}
      </Content>
    )

  return (
    <>
      <CustomScrollbar
        className="no-inset margin-12-right"
        style={{ height: '32rem' }}
      >
        <Content isCompanyDatasetsLogging={isCompanyDatasetsLogging}>
          <DatasetInfo
            {...{
              name,
              info,
              created,
              logsLoadingStatus,
              isCompanyLogsLoading,
              isCompanyDatasetsLogging,
            }}
            isExpanded={isExpanded}
            moleculesCount={molecules_count}
            isDatasetCalculated={is_basket_calculated}
            onExpand={() => setIsExpanded((prev) => !prev)}
            isCopy={
              isCompanyDatasetsLogging
                ? false
                : logs?.some((el) => el?.request_type === 'copy')
            }
          />

          {isExpanded && (
            <LogsContent
              {...{
                basketType,
                logsLoadingStatus,
              }}
              logList={logs || []}
            />
          )}
        </Content>
      </CustomScrollbar>

      <FooterButtons
        {...{
          basketType,
          onTransfer,
          onCalculate,
          hasRunningTask,
          logsLoadingStatus,
          is_basket_calculated,
          isDatasetCalculating,
          molecules_count,
        }}
      />
    </>
  )
}

export default DatasetLogs
