import styled from 'styled-components'

export const AdminPanelWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  margin-left: 0.75rem;
  padding-right: 0.75rem;
  display: ${({ flexCol }) => flexCol && 'flex'};
  flex-direction: ${({ flexCol }) => flexCol && 'column'};
`

export const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SwitchWrapper = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
`

export const ScrollTopBtn = styled.button`
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: ${({ isNotificationsButtonShow }) =>
    isNotificationsButtonShow ? '12rem' : '8.5rem'};
  right: 1.5rem;
  border: none;
  outline: none;
  background: #ffffff;
  border-radius: 12px;
  padding: 0.75rem;
  z-index: 5;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 0.3s;
  box-shadow: ${({ theme }) => theme.shadow.default};
  cursor: pointer;

  & > svg {
    color: #2d3745;
  }

  &:hover {
    & > svg {
      color: #42c1bf;
      transition: color 0.3s;
    }
  }
`
