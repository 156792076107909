import { useTheme } from 'styled-components'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  HEADERS_EXCEPTIONS,
  getCustomHeadersDescription,
} from 'config/molecule/molecule'
import Icon from 'components/Icon'
import { ColoredSubheadline } from 'components/HeadersProperty/index.style'

import { DescriptedPropertyKey } from './index.style'

const PropertyKey = ({
  text,
  textForRender,
  type,
  color,
  textColor,
  id,
  className = '',
}) => {
  const theme = useTheme()
  return HEADERS_EXCEPTIONS.descriptions.includes(text) ? (
    <div>
      <DescriptedPropertyKey
        data-tooltip-id={id ? `${text}-${id}` : text}
        className={'property-name molecule-descripted-key'}
        status={type === 'header' ? color : undefined}
      >
        <ColoredSubheadline
          color={textColor || theme.colors.text.secondary}
          status={type === 'header' ? color : undefined}
          fontWeight={
            type === 'header'
              ? theme.fontWeight.semibold
              : theme.fontWeight.medium
          }
        >
          {textForRender}
        </ColoredSubheadline>
        <Icon iconType={'description'} />
      </DescriptedPropertyKey>
      <ReactTooltip
        id={id ? `${text}-${id}` : text}
        clickable={true}
        delayHide={100}
        className="c-tooltip c-tooltip-base c-tooltip-z-max"
        classNameArrow="c-tooltip-arrow"
        place={id ? 'top-start' : 'bottom'}
        positionStrategy={'fixed'}
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {getCustomHeadersDescription(text)}
      </ReactTooltip>
    </div>
  ) : (
    <ColoredSubheadline
      color={textColor || '#616E7C'}
      status={type === 'header' ? color : undefined}
      className={className}
      fontWeight={
        type === 'header' ? theme.fontWeight.semibold : theme.fontWeight.medium
      }
    >
      {textForRender}
    </ColoredSubheadline>
  )
}

export default PropertyKey
