import styled from 'styled-components'

export const Info = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;

  & > svg {
    color: ${({ theme }) => theme.colors.icons.secondary};
  }
`
export const HeaderWithButton = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
