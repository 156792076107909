export const DEFAULT_MOLECULE_WIDTH = 254
export const DEFAULT_MOLECULE_HEIGHT = 152

export const STICKY_MOLECULE_WIDTH = 109
export const STICKY_MOLECULE_HEIGHT = 92

export const COMPARE_CATEGORIES_EXCEPTIONS = ['synonyms', 'external']
export const COMPARE_PROPERTIES_EXCEPTIONS = ['iupac', 'cas_number']

export const STICKY_CONTENT_HEIGHT = '10rem'
export const STICKY_CONTENT_HEIGHT_NO_SCROLL = '7.5rem'

export const STICKY_SCROLL_HEIGHT = '9rem'

export const DEFAULT_CONTENT_HEIGHT = '16.5rem'
export const DEFAULT_CONTENT_HEIGHT_NO_SCROLL = '15.125rem'

export const DEFAULT_SCROLL_STEP = 260
export const STICKY_SCROLL_STEP = 250
