import { connect, useDispatch } from 'react-redux'
import { TableWrapper, StyledTable, StyledTr } from '../../index.style'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid'
import { MULTIPLETS, SPECTRA_NMR_TABLE } from '../../config'
import { setNmrChartActiveAtom } from 'store/actions/spectra'
import { useTranslation } from 'react-i18next'
import CustomScrollbar from 'components/CustomScrollbar'
import { Caption, Label } from 'components/common/text/index.style'
import { useTheme } from 'styled-components'

const HEADER_ITEMS = [
  { label: 'spectra.table.atom' },
  { label: 'spectra.table.multiplet' },
  { label: 'spectra.table.meas_shift' },
]

const NmrTable = ({ data, activeAtoms, duplicates }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useTheme()
  const { intensities, shifts, atom_index } = data

  const tableHash = uuidv4()

  const handleRowEnter = (index) => {
    dispatch(setNmrChartActiveAtom(duplicates[shifts[index]]))
  }

  const handleRowOut = () => {
    dispatch(setNmrChartActiveAtom([]))
  }

  return (
    <CustomScrollbar className="nmr-table-scroll">
      <TableWrapper id={SPECTRA_NMR_TABLE}>
        <StyledTable>
          <thead>
            <tr>
              {HEADER_ITEMS.map(({ label }) => (
                <td key={label}>
                  <Caption
                    color={theme.colors.text.secondary}
                    fontWeight={theme.fontWeight.medium}
                  >
                    {t(label)}
                  </Caption>
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {intensities.map((row, index) => (
              <StyledTr
                key={uuidv4()}
                active={activeAtoms.includes(index)}
                onMouseEnter={() => handleRowEnter(index)}
                onMouseLeave={() => handleRowOut()}
              >
                <td>
                  <Label color="inherit">{atom_index[index]}</Label>
                </td>
                <td data-tooltip-id={`${tableHash}-${index}`}>
                  <Label color="inherit">
                    {!Array.isArray(row) ? 'S' : MULTIPLETS[row.length] ?? 'M'}
                    <ReactTooltip
                      id={`${tableHash}-${index}`}
                      className="c-tooltip c-tooltip-base c-tooltip-nmrtable"
                      classNameArrow="c-tooltip-arrow"
                      place={'bottom'}
                      offset={6}
                      positionStrategy={'fixed'}
                    >
                      <div style={{ minWidth: '20px' }}>
                        {!Array.isArray(row) ? row : row.join(', ')}
                      </div>
                    </ReactTooltip>
                  </Label>
                </td>

                <td>
                  <Label color="inherit">
                    {Array.isArray(shifts[index])
                      ? (
                          shifts[index].reduce(
                            (partialSum, a) => partialSum + a,
                            0
                          ) / shifts[index].length
                        ).toFixed(2)
                      : shifts[index].toFixed(2)}
                  </Label>
                </td>
              </StyledTr>
            ))}
          </tbody>
        </StyledTable>
      </TableWrapper>
    </CustomScrollbar>
  )
}

const mapStateToProps = (state) => {
  return {
    data: state.spectra.nmr.data,
    activeAtoms: state.spectra.nmr.chartActiveAtoms,
  }
}

export default connect(mapStateToProps)(NmrTable)
