import {
  basketLoadWatcher,
  loadLastChangeWatcher,
  moleculesLoadWatcher,
  moleculeCreateWatcher,
  moleculeDeleteWatcher,
  moleculeUpdateWatcher,
  addBasketWatcher,
  deleteBasketWatcher,
  copyBasketWatcher,
  joinBasketWatcher,
  updateBasketWatcher,
  loadMoreMoleculesWatcher,
  moveMoleculesWatcher,
  createMoleculesPackWatcher,
  calcTLightWatcher,
  moleculeCreateFromFileWatcher,
  searchV2Watcher,
  litSearchWatcher,
  bingoSearchWatcher,
  showSearchV2ResultWatcher,
  removeBasketFromDBWatcher,
  checkCustomerDatasetsAccessWatcher,
  transferToCustomerDatasetWatcher,
  reactionsSearchWatcher,
} from './crud'

import { all } from 'redux-saga/effects'
import authWatchers from './auth'
import {
  checkGeneratorStatusWatcher,
  loadMMapWatcher,
  mmapGeneratorIncomeWatcher,
  startGeneratorWatcher,
  toggleIs3dModeWatcher,
} from './mmap'
import moleculeWatchers from './molecule'
import {
  spectraMsGetWatcher,
  spectraNmrGetWatcher,
  spectraInfraredGetWatcher,
} from './spectra'

import {
  converSmilesToEnIupacWatcher,
  converSmilesToRuIupacWatcher,
} from './smilesIupac'

import { getSearchHistoryWatcher, repeatSearchWatcher } from './searchHistory'
import pdf2SmilesWatchers from './pdf2smiles'
import synthcostWatchers from './synthcost'
import reactionWatchers from './reactions'
import filterWatchers from './filter'
import adminPanelWatchers from './adminPanel'
import { removeCompletedTasksWatcher } from './tasks'
import { applyFeedbackFilterWatcher, getFeedbackWatcher } from './feedback'
import surveysWatchers from './survey'
import statisticsWatchers from './statistics'
import settingsWatchers from './settings'
import structuresCompareWatchers from './structuresCompare'

const sagasList = [
  basketLoadWatcher(),
  loadLastChangeWatcher(),
  moleculesLoadWatcher(),
  moleculeCreateWatcher(),
  moleculeDeleteWatcher(),
  moleculeUpdateWatcher(),
  addBasketWatcher(),
  deleteBasketWatcher(),
  copyBasketWatcher(),
  joinBasketWatcher(),
  updateBasketWatcher(),
  loadMoreMoleculesWatcher(),
  moveMoleculesWatcher(),
  loadMMapWatcher(),
  mmapGeneratorIncomeWatcher(),
  createMoleculesPackWatcher(),
  calcTLightWatcher(),
  moleculeCreateFromFileWatcher(),
  searchV2Watcher(),
  showSearchV2ResultWatcher(),
  spectraMsGetWatcher(),
  spectraNmrGetWatcher(),
  spectraInfraredGetWatcher(),
  bingoSearchWatcher(),
  litSearchWatcher(),
  removeBasketFromDBWatcher(),
  checkCustomerDatasetsAccessWatcher(),
  transferToCustomerDatasetWatcher(),
  toggleIs3dModeWatcher(),
  converSmilesToRuIupacWatcher(),
  converSmilesToEnIupacWatcher(),
  getSearchHistoryWatcher(),
  repeatSearchWatcher(),
  startGeneratorWatcher(),
  reactionsSearchWatcher(),
  getFeedbackWatcher(),
  applyFeedbackFilterWatcher(),

  checkGeneratorStatusWatcher(),
  ...moleculeWatchers,
  ...authWatchers,
  ...pdf2SmilesWatchers,
  ...synthcostWatchers,
  ...reactionWatchers,
  ...filterWatchers,
  ...adminPanelWatchers,
  ...removeCompletedTasksWatcher(),
  ...surveysWatchers,
  ...statisticsWatchers,
  ...settingsWatchers,
  ...structuresCompareWatchers,
]

function* rootSaga() {
  yield all(sagasList)
}

export default rootSaga
