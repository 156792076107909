import styled from 'styled-components'

export const LogsListWithSort = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  .sticky-tabs {
    top: 0;
    position: sticky;
    padding-bottom: 0.5rem;
    background-color: ${({ theme }) => theme.colors.backgrounds.primary};
  }
`
