import { useTheme } from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { withResizeDetector } from 'react-resize-detector'

import { Label } from 'components/common/text/index.style'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { WARNING_PROPERTIES_VALUES } from 'components/Molecule/config/config'

import CasNumbers from '../CasNumbers'

const ValueWithTooltip = ({ name, value, id, onCopy, isGeneratedMol }) => {
  const theme = useTheme()
  const textRef = useRef(null)
  const [isOverflow, setIsOverflow] = useState(false)

  const { width } = useWindowDimensions()

  useEffect(() => {
    setIsOverflow(textRef?.current?.scrollWidth > textRef?.current?.clientWidth)
  }, [width, textRef])

  if (name === 'cas_number') {
    return (
      <CasNumbers
        values={value}
        isGeneratedMol={isGeneratedMol}
        onCopy={(e, value) => onCopy(e, name, value)}
      />
    )
  }

  return (
    <>
      {isOverflow && (
        <ReactTooltip
          id={
            id
              ? `structural-data-tooltip-${name}-${id}`
              : `structural-data-tooltip-${name}`
          }
          className="c-tooltip c-tooltip-base c-tooltip-category-value c-tooltip-z-max"
          classNameArrow="c-tooltip-arrow"
          positionStrategy={'fixed'}
        >
          {value}
        </ReactTooltip>
      )}
      <Label
        ref={textRef}
        data-tooltip-id={
          id
            ? `structural-data-tooltip-${name}-${id}`
            : `structural-data-tooltip-${name}`
        }
        name={name}
        as={'span'}
        color={
          WARNING_PROPERTIES_VALUES.includes(value)
            ? theme.colors.statuses.error
            : undefined
        }
      >
        {value}
      </Label>
    </>
  )
}

export default withResizeDetector(ValueWithTooltip)
