import styled from 'styled-components'

export const DatasetContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 100%;
  padding: ${({ isCompanyDatasetsLogging }) =>
    isCompanyDatasetsLogging ? '0rem' : '1rem'};
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
`
export const DatasetInfoBlock = styled.div`
  overflow: hidden;
  gap: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
`

export const Block = styled.div`
  gap: ${({ gap }) => gap || '0.5rem'};
  display: flex;
  flex-direction: column;
`
