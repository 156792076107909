import { bisector } from 'd3-array'
import { localPoint } from '@visx/event'
import jsPDF from 'jspdf'
import { geologicaCurReg } from 'utils/generatePdf/config'
import { t } from 'i18next'
import * as htmlToImage from 'html-to-image'

import { ENERGY_TYPES, SPECTRA_MS_CHART_PREFIX } from './config'

export const getX = (d) => d.x
export const getY = (d) => d.y
export const bisect = bisector((d) => d.x).left

export const numZeroesAfterPoint = (x) => {
  if (x % 1 === 0) {
    return 0
  } else {
    return -1 - Math.floor(Math.log10(x % 1))
  }
}

export const getAverage = (arr) => {
  return Array.isArray(arr) ? arr.reduce((p, c) => p + c, 0) / arr.length : arr
}

export const getScrollScale = (e, zoom) => {
  const delta = e.deltaY

  if (Math.abs(delta) > 10) {
    zoom.scale({
      scaleX: delta > 0 ? 1.1 : 0.9,
      scaleY: 1,
      point: localPoint(e),
    })
  } else if (Math.abs(delta) > 100) {
    zoom.scale({
      scaleX: delta > 0 ? 1.15 : 0.85,
      scaleY: 1,
      point: localPoint(e),
    })
  } else {
    zoom.scale({
      scaleX: delta > 0 ? 1.03 : 0.97,
      scaleY: 1,
      point: localPoint(e),
    })
  }
}

export const downloadSvgAsImageById = (elementId) => {
  // Step 1: Get the SVG element by its ID
  const svgElement = document.getElementById(elementId)

  // Step 2: Convert the SVG element to an XML string
  const svgData = new XMLSerializer().serializeToString(svgElement)

  // Step 3: Create a Blob from the SVG data
  const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
  const url = URL.createObjectURL(svgBlob)

  // Step 4: Create an Image object
  const img = new Image()
  img.onload = () => {
    // Step 5: Create a canvas element
    const canvas = document.createElement('canvas')
    canvas.width = svgElement.clientWidth // Set canvas dimensions to match the SVG
    canvas.height = svgElement.clientHeight

    const ctx = canvas.getContext('2d')
    ctx.fillStyle = 'white'
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    // Step 6: Draw the image onto the canvas
    ctx.drawImage(img, 0, 0)

    // Step 7: Convert the canvas to a data URL
    const pngFile = canvas.toDataURL('image/png')

    // Step 8: Create a download link and trigger the download
    const downloadLink = document.createElement('a')
    downloadLink.href = pngFile
    downloadLink.download = 'spectra-prediction-image.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(url)
  }

  // Step 9: Set the source of the Image object to the SVG Blob URL
  img.src = url
}

export const getPngImageObjectById = async ({ id, style, dimensions }) => {
  try {
    const page = document.getElementById(id)
    if (!page) return

    const clientDim = dimensions || page

    const dataUrl = await htmlToImage.toCanvas(page, {
      canvasWidth: clientDim.clientWidth * 4,
      canvasHeight: clientDim.clientHeight * 4,
      style,
      width: clientDim.clientWidth,
      height: clientDim.clientHeight,
    })
    const imgData = dataUrl.toDataURL('image/png', 1)
    return {
      data: imgData,
      width: clientDim.clientWidth,
      height: clientDim.clientHeight,
    }
  } catch (error) {
    console.error('Error generating image for PDF: ', error)
  }
}

const leftX = 10
const topY = 10
const maxWidth = 190
const maxChartHeight = 100
const strImgSize = 100
const strImgY = 35
const maxTableWidth = 75
const maxTableHeight = 150
const maxTableRowQty = 22
const maxMultiRowTableWidth = 130
const strCanvasHeight = 65

export const generatePdfForNMR = async ({
  chartId,
  tableId,
  fileName = 'Syntelly spectra prediction NMR',
  smiles = '',
  method = '',
  image,
  tableRowQty = 0,
}) => {
  if (!chartId) return
  try {
    const doc = new jsPDF('p', 'mm', 'a4')
    doc.addFileToVFS('Geologica-Regular.ttf', geologicaCurReg)
    doc.addFont('Geologica-Regular.ttf', 'Geologica Cursive', 'normal')
    doc.setFont('Geologica Cursive')

    doc.setFontSize(10)
    doc.text(t('spectra.buttons.nmr'), leftX, topY)
    doc.setFontSize(8)
    doc.text(`${t('spectra.labels.input')}:`, leftX, 15)
    doc.text(smiles, leftX, 20, {
      maxWidth,
    })
    doc.text(`${t('spectra.labels.method')}: ${method}`, leftX, 30)

    image && doc.addImage(image, 'PNG', 5, strImgY, strImgSize, strImgSize)

    //table addition to the same page
    let isNewPageTable = false
    let maxPrevChartHeight = strImgSize

    if (tableRowQty <= maxTableRowQty) {
      const {
        data: tableData,
        width: tableWidth,
        height: tableHeight,
      } = await getPngImageObjectById({ id: tableId })

      let tableImgHeight = (tableHeight * maxTableWidth) / tableWidth

      let imgTableWidth = maxTableWidth
      if (tableImgHeight > maxTableHeight) {
        imgTableWidth = (tableWidth * maxTableHeight) / tableHeight
        tableImgHeight = maxTableHeight
      }

      const tableSizes = [120, strImgY, imgTableWidth, tableImgHeight]
      doc.addImage(tableData, 'PNG', ...tableSizes, '', 'MEDIUM')
      maxPrevChartHeight = Math.max(tableImgHeight, strImgSize)
    } else {
      isNewPageTable = true
    }

    //chart
    const {
      data: chartData,
      width: chartWidth,
      height: chartHeight,
    } = await getPngImageObjectById({ id: chartId })

    let chartSizes
    const imgHeight = (chartHeight * maxWidth) / chartWidth
    const chartPosTopY = maxPrevChartHeight + 10 + strImgY

    if (imgHeight > maxChartHeight) {
      const imgWidth = (chartWidth * maxChartHeight) / chartHeight
      chartSizes = [leftX, chartPosTopY, imgWidth, maxChartHeight]
    } else {
      chartSizes = [leftX, chartPosTopY, maxWidth, imgHeight]
    }
    doc.addImage(chartData, 'PNG', ...chartSizes, '', 'MEDIUM')

    //table addition to the next page
    if (isNewPageTable) {
      doc.addPage()
      const y = (doc.getNumberOfPages() - 1) * doc.internal.pageSize.height - 20

      const element = document.getElementById(tableId)
      await doc.html(element, {
        margin: [10, 10, 10, 10],
        html2canvas: {
          useCORS: true,
          allowTaint: true,
          logging: false,
        },
        x: 0,
        y,
        width: maxMultiRowTableWidth,
        windowWidth: 600,
        autoPaging: 'text',
      })
    }

    doc.save(`${fileName}.pdf`)
  } catch (error) {
    console.error('Error generating PDF: ', error)
  }
}

export const generatePdfForInfrared = async ({
  chartId,
  fileName = 'Syntelly spectra prediction infrared',
  smiles = '',
  method = '',
  canvasId,
}) => {
  if (!chartId) return
  try {
    const doc = new jsPDF('p', 'mm', 'a4')
    doc.addFileToVFS('Geologica-Regular.ttf', geologicaCurReg)
    doc.addFont('Geologica-Regular.ttf', 'Geologica Cursive', 'normal')
    doc.setFont('Geologica Cursive')

    doc.setFontSize(10)
    doc.text(t('spectra.buttons.infraredSpectrometry'), leftX, topY)
    doc.setFontSize(8)
    doc.text(`${t('spectra.labels.input')}:`, leftX, 15)
    doc.text(smiles, leftX, 20, {
      maxWidth,
    })
    doc.text(`${t('spectra.labels.shooting_method')}: ${method}`, leftX, 30)

    const canvas = document.getElementById(canvasId)
    const canvasWidth = (canvas.width * strCanvasHeight) / canvas.height
    canvas &&
      doc.addImage(
        canvas,
        'PNG',
        leftX,
        strImgY,
        canvasWidth,
        strCanvasHeight,
        '',
        'MEDIUM'
      )

    //chart
    const {
      data: chartData,
      width: chartWidth,
      height: chartHeight,
    } = await getPngImageObjectById({ id: chartId })

    const imgHeight = (chartHeight * maxWidth) / chartWidth
    const chartPosTopY = strCanvasHeight + strImgY
    const chartSizes = [leftX, chartPosTopY, maxWidth, imgHeight]
    doc.addImage(chartData, 'PNG', ...chartSizes, '', 'MEDIUM')

    doc.save(`${fileName}.pdf`)
  } catch (error) {
    console.error('Error generating PDF: ', error)
  }
}

export const generatePdfForMS = async ({
  canvasId,
  fileName = 'Syntelly spectra prediction mass',
  smiles = '',
  ionMode,
  adductType,
  roundingRI,
  roundingMZ,
}) => {
  try {
    const doc = new jsPDF('p', 'mm', 'a4')
    doc.addFileToVFS('Geologica-Regular.ttf', geologicaCurReg)
    doc.addFont('Geologica-Regular.ttf', 'Geologica Cursive', 'normal')
    doc.setFont('Geologica Cursive')

    doc.setFontSize(10)
    doc.text(t('spectra.buttons.ms'), leftX, topY)
    doc.setFontSize(8)
    doc.text(`${t('spectra.labels.input')}:`, leftX, 15)
    doc.text(smiles, leftX, 20, {
      maxWidth,
    })
    doc.text(`${t('spectra.labels.spectral_type')}: ESI`, leftX, 30)
    doc.text(`${t('spectra.labels.ion_mode')}: ${ionMode}`, leftX, 35)
    doc.text(`${t('spectra.labels.adduct_type')}: ${adductType}`, leftX, 40)
    doc.text(`${t('spectra.labels.roundingRI')}: ${roundingRI}`, leftX, 45)
    doc.text(`${t('spectra.labels.roundingMZ')}: ${roundingMZ}`, leftX, 50)

    const canvas = document.getElementById(canvasId)
    const canvasWidth = (canvas.width * strCanvasHeight) / canvas.height
    canvas &&
      doc.addImage(
        canvas,
        'PNG',
        leftX,
        55,
        canvasWidth,
        strCanvasHeight,
        '',
        'MEDIUM'
      )

    //charts
    let dimensions
    for (let t of ENERGY_TYPES) {
      const elem = document.getElementById(`${SPECTRA_MS_CHART_PREFIX}${t}`)
      if (elem?.clientWidth && elem?.clientHeight) {
        dimensions = {
          clientWidth: elem.clientWidth,
          clientHeight: elem?.clientHeight,
        }
        break
      }
    }

    if (dimensions) {
      let topPos = 60 + strCanvasHeight

      for (let type of ENERGY_TYPES) {
        const chartId = `bar-spectra-chart-container-${type}`

        const {
          data: chartData,
          width: chartWidth,
          height: chartHeight,
        } = await getPngImageObjectById({
          id: chartId,
          style: {
            width: 'auto !important',
            height: 'auto !important',
            overflow: 'visible !important',
            marginTop: '0 !important',
          },
          dimensions,
        })

        if (!chartData) continue

        const imgHeight = (chartHeight * maxWidth) / chartWidth
        if (topPos + imgHeight + 10 > doc.internal.pageSize.height - 10) {
          topPos = topY
          doc.addPage()
        }

        doc.text(t(`spectra.labels.${type}`), leftX, topPos)
        topPos += 5

        const chartSizes = [leftX, topPos, maxWidth, imgHeight]
        doc.addImage(chartData, 'PNG', ...chartSizes, '', 'MEDIUM')
        topPos += 5 + imgHeight
      }
    }

    doc.save(`${fileName}.pdf`)
  } catch (error) {
    console.error('Error generating PDF: ', error)
  }
}
