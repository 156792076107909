import { put, select, takeEvery } from 'redux-saga/effects'

import {
  REPEAT_SEARCH,
  GET_HISTORY_INIT,
  GET_HISTORY_ERROR,
  GET_HISTORY_SUCCESS,
} from 'store/constants/searchHistory'

import {
  SET_LIT_FILTER_CONFIG,
  SET_LIT_FILTER_DOC_TYPE,
  SET_SHOULD_BLOCKS_INDEXES,
  UPDATE_LAST_SEARCH_VALUES,
} from 'store/constants/literature'
import { fetch } from 'services/rest'
import {
  SET_PAGINATION_CONFIG,
  SET_SEARCH_TYPE,
  SET_SORTING_CONFIG,
  SET_TEXT,
} from 'store/constants/search'
import {
  SET_BINGO_SEARCH,
  SET_LIT_SEARCH,
  SET_REACTIONS_SEARCH,
  SET_SEARCH_V2,
  SHOW_SEARCH_V2_RESULT,
} from 'store/constants/crud'
import {
  SET_OPEN_FILTER,
  SET_FILTER_CONFIG,
  SET_FILTER_BUTTONS_DISABLED,
} from 'store/constants/filter'
import {
  BINGO_PAGINATION_LIMIT,
  INNER_LANG_OPERATOR,
  LIT_PAGINATION_LIMIT,
  REACTIONS_PAGINATION_LIMIT,
} from './utils/config'
import { convertNewOperatorToOld, extractValues, getField } from './utils/utils'
import { SET_REACTIONS_COMPOUND_ID } from 'store/constants/searchReactions'

function* getSearchHistory({ offset, limit }) {
  try {
    const response = yield fetch(
      `/search_history?offset=${offset}&limit=${limit}`
    )

    if (response.status === 200) {
      yield put({
        type: GET_HISTORY_SUCCESS,
        data: response.data,
      })
    } else {
      yield put({
        type: GET_HISTORY_ERROR,
        data: response.statusText,
      })
    }
  } catch (error) {
    yield put({
      type: GET_HISTORY_ERROR,
      data: error?.message || 'Error',
    })
  }
}

function* repeatSearch({ data, showPastResult }) {
  const { params } = data.query
  const filterConfig = yield select((state) => state.filter.config)
  const isFilterOpen = yield select((state) => state.filter.open)

  if (showPastResult) {
    yield put({ type: SET_FILTER_BUTTONS_DISABLED })

    if (data.query.type === 'molecules_search') {
      yield put({
        type: SET_SEARCH_TYPE,
        value: 'structure',
      })
      let match_type = 'exact match'

      const config = { ...filterConfig, match_type }

      yield put({
        type: SET_FILTER_CONFIG,
        config,
      })

      yield put({
        type: SET_TEXT,
        text: params?.searchV2?.text || params?.searchV2?.smiles || '',
        basket: params?.basket,
      })

      if (!isFilterOpen) {
        yield put({
          type: SET_OPEN_FILTER,
          open: true,
        })
      }

      yield put({
        type: SHOW_SEARCH_V2_RESULT,
        taskUuid: data.id,
      })
    } else {
      const limit =
        data.query.type === 'full_text_search'
          ? LIT_PAGINATION_LIMIT
          : data.query.type === 'reaction_search'
          ? REACTIONS_PAGINATION_LIMIT
          : BINGO_PAGINATION_LIMIT
      const config = {
        total: data.result_count,
        pagesAmount: Math.ceil(data.result_count / limit),
        perPage: limit,
        activePage: 1,
      }
      yield put({
        type: SET_PAGINATION_CONFIG,
        config,
      })
    }
  }

  if (data.query.type === 'molecules_search' && !showPastResult) {
    yield put({
      type: SET_SEARCH_TYPE,
      value: 'structure',
    })
    let match_type = 'exact match'

    const config = { ...filterConfig, match_type }

    yield put({
      type: SET_FILTER_CONFIG,
      config,
    })

    yield put({
      type: SET_TEXT,
      text: params?.searchV2?.text || params?.searchV2?.smiles || '',
    })
    if (!isFilterOpen) {
      yield put({
        type: SET_OPEN_FILTER,
        open: true,
      })
    }
    yield put({
      type: SET_SEARCH_V2,
      ids: params?.searchV2.ids,
      text: params?.searchV2.text,
      smiles: params?.searchV2.smiles,
      basket: params?.basket,
    })
  }

  if (data.query.type === 'bingo_search') {
    yield put({
      type: SET_SEARCH_TYPE,
      value: 'structure',
    })
    let match_type = 'exact match'
    if (params.method === 'sub') match_type = 'substructural search'
    if (params.method === 'sim') match_type = 'similar structures'
    if (params.method === 'markush') match_type = 'markush structures'

    const similarity = {
      0:
        params?.bottom && params.method !== 'markush'
          ? params.bottom.toString()
          : 0,
      1:
        params?.top && params.method !== 'markush'
          ? params.top.toString()
          : 100,
    }
    const molecular_weight = {
      0:
        params?.bottom && params.method === 'markush'
          ? params.bottom.toString()
          : '',
      1:
        params?.top && params.method === 'markush' ? params.top.toString() : '',
    }

    const config = { ...filterConfig, match_type, similarity, molecular_weight }

    yield put({
      type: SET_FILTER_CONFIG,
      config,
    })

    yield put({
      type: SET_TEXT,
      text:
        params?.text ||
        params?.smiles ||
        params?.searchV2?.text ||
        params?.searchV2?.smiles ||
        '',
    })
    if (!isFilterOpen) {
      yield put({
        type: SET_OPEN_FILTER,
        open: true,
      })
    }
    yield put({
      type: SET_BINGO_SEARCH,
      text: params.smiles,
      label: params.smiles,
      task_uuid: showPastResult ? data.id : null,
      showPastResult,
    })
  } else if (data.query.type === 'full_text_search') {
    yield put({
      type: SET_SEARCH_TYPE,
      value: 'literature',
    })
    if (params?.name) {
      const DOIRe = /\b(10[.][0-9]{4,}(?:[.][0-9]+)*\/(?:(?!["&\\'<>])\S)+)\b/g
      // const patentRe = /([a-zA-Z]{2})([\d\\/]+)([a-zA-Z]\d)/gm
      const checkDOI = new RegExp(DOIRe).test(params.name)
      const docType = checkDOI ? 'doi' : 'patent_number'
      yield put({
        type: SET_LIT_FILTER_DOC_TYPE,
        docType,
      })
    }
    if (params?.title) {
      yield put({
        type: SET_TEXT,
        text: params.title.values[0],
      })
    }
    const { type, structures_ids, published_date, language, sort, ...rest } =
      params
    if (structures_ids) {
      try {
        const { data: fetchData } = yield fetch(
          `/molecule/${params.structures_ids.values[0]}`,
          2
        )
        const data = fetchData?.result || {}
        yield put({
          type: SET_TEXT,
          text: data?.smiles || 'O=C(C)Oc1ccccc1C(=O)O',
        })
      } catch (e) {
        console.log(e)
        yield put({
          type: SET_TEXT,
          text: 'O=C(C)Oc1ccccc1C(=O)O',
        })
      }
    }

    if (sort) {
      yield put({
        type: SET_SORTING_CONFIG,
        config: {
          type: sort.key,
          direction: sort.order_type,
        },
      })
    }
    yield put({
      type: SET_LIT_FILTER_CONFIG,
      config: { ...rest },
    })
    const config = {}
    if (type) {
      config.document_type = params.type
    }
    if (params?.authors) {
      if (params.authors.values.length === 1)
        config.author = params.authors.values[0]
    }
    if (params?.published_date) {
      const { from_date, to_date } = params.published_date
      config.published_date = {
        0: from_date ? from_date.split('-')[0] : '',
        1: to_date ? to_date.split('-')[0] : '',
      }
    }
    if (params?.language) {
      config.language = params.language.values
    }
    yield put({
      type: SET_FILTER_CONFIG,
      config: { ...filterConfig, ...config },
    })
    if (!isFilterOpen) {
      yield put({
        type: SET_OPEN_FILTER,
        open: true,
      })
    }
    yield put({
      type: SET_LIT_SEARCH,
      task_uuid: showPastResult ? data.id : null,
      showPastResult,
    })
  } else if (data.query.type === 'advanced_text_search') {
    let config = {}
    let litSearchConfig = {}
    const sort = params?.sort
    const paramsArr = params?.query?.['and_operator'] || []

    let languages = []
    let structures_ids

    // у ключей с '.ru'/'.en' внутри всегда есть INNER_LANG_OPERATOR
    const paramsWithLang = paramsArr.filter((el) =>
      (el?.['and_operator'] || el?.['or_operator'])?.some(
        (el) => el?.[INNER_LANG_OPERATOR]
      )
    )

    const firstParam = (paramsWithLang[0]?.['and_operator'] ||
      paramsWithLang[0]?.['or_operator'])?.[0][INNER_LANG_OPERATOR]
    const { languages: langs } = getField(paramsArr)
    if (langs) {
      languages = langs
    } else if (firstParam.length < 2) {
      // выбран один язык, в languages кладем этот язык
      const lang = firstParam[0]?.field?.split('.')[1]
      languages = [lang]
    } else {
      languages = ['en', 'ru']
    }

    const result = []
    const extractedResults = []
    paramsArr.forEach((obj) => {
      if (Object.prototype.hasOwnProperty.call(obj, 'or_operator')) {
        result.push(obj.or_operator)
      }
    })
    result.forEach((res) => {
      const { fields: values } = getField(res)
      if (!['type', 'published_date', 'language'].includes(values[0])) {
        extractedResults.push(values)
      }
    })
    if (extractedResults?.length > 0) {
      const shouldBlocksData = []
      extractedResults.forEach((res) => {
        if (res.length > 1) {
          const resWithoutLastEl = res?.slice(0, -1)
          shouldBlocksData.push(...resWithoutLastEl)
        }
      })

      yield put({
        type: SET_SHOULD_BLOCKS_INDEXES,
        data: shouldBlocksData,
      })
    }

    paramsArr?.forEach((param) => {
      const key = Object.keys(param)?.[0]
      const operator = convertNewOperatorToOld(key)
      const conditions = param[key]

      const { fields } = getField(conditions)
      const fieldsToUse =
        fields?.[0] === 'type' && conditions?.length > 1
          ? [...fields, 'type']
          : fields
      let extractedValues = []
      fieldsToUse?.forEach((field, index) => {
        if (fieldsToUse.length > 1) {
          extractedValues = extractValues(conditions[index])
        } else {
          conditions?.forEach((condition, idx) => {
            const extracted = extractValues(condition)
            extractedValues.push(...extracted)
          })
        }
        if (field === 'type') {
          config.document_type = [
            ...(config.document_type ?? []),
            ...extractedValues,
          ]
        } else if (field === 'language') {
          languages = extractedValues
        } else if (field === 'structures_ids') {
          structures_ids = {
            operator,
            exact: false,
            values: extractedValues,
          }
        } else if (field === 'published_date') {
          const { gte, lte } = extractedValues[0]
          config.published_date = {
            0: gte ? gte.split('-')[0] : '',
            1: lte ? lte.split('-')[0] : '',
          }
        } else if (field === 'name') {
          litSearchConfig.name = extractedValues[0]
        } else {
          litSearchConfig[field] = {
            operator,
            exact: false,
            values: extractedValues,
          }
        }
      })
    })

    if (languages.length) {
      config.language = languages
    }

    if (structures_ids) {
      try {
        const { data: fetchData } = yield fetch(
          `/molecule/${structures_ids.values[0]}`,
          2
        )
        const data = fetchData?.result || {}
        yield put({
          type: UPDATE_LAST_SEARCH_VALUES,
          prevId: structures_ids?.values?.[0] || null,
          text: data?.smiles || 'O=C(C)Oc1ccccc1C(=O)O',
        })
        yield put({
          type: SET_TEXT,
          text: data?.smiles || 'O=C(C)Oc1ccccc1C(=O)O',
        })
      } catch (e) {
        console.log(e)
        yield put({
          type: SET_TEXT,
          text: 'O=C(C)Oc1ccccc1C(=O)O',
        })
      }
    }

    if (litSearchConfig?.name) {
      const DOIRe = /\b(10[.][0-9]{4,}(?:[.][0-9]+)*\/(?:(?!["&\\'<>])\S)+)\b/g
      // const patentRe = /([a-zA-Z]{2})([\d\\/]+)([a-zA-Z]\d)/gm
      const checkDOI = new RegExp(DOIRe).test(litSearchConfig.name)
      const docType = checkDOI ? 'doi' : 'patent_number'
      yield put({
        type: SET_LIT_FILTER_DOC_TYPE,
        docType,
      })
    }

    if (litSearchConfig?.title && !structures_ids) {
      // добавлено условие !structures_ids чтобы если есть и structures_ids, и title
      // в поисковую строку подставлялся smiles из structures_ids, а не title
      yield put({
        type: UPDATE_LAST_SEARCH_VALUES,
        prevId: null,
        text: litSearchConfig.title.values[0],
      })

      yield put({
        type: SET_TEXT,
        text: litSearchConfig.title.values[0],
      })
    }

    if (sort) {
      yield put({
        type: SET_SORTING_CONFIG,
        config: {
          type: sort.key,
          direction: sort.order_type,
        },
      })
    }

    yield put({
      type: SET_SEARCH_TYPE,
      value: 'literature',
    })

    yield put({
      type: SET_FILTER_CONFIG,
      config: { ...filterConfig, ...config },
    })

    yield put({
      type: SET_LIT_FILTER_CONFIG,
      config: { ...litSearchConfig },
    })

    if (!isFilterOpen) {
      yield put({
        type: SET_OPEN_FILTER,
        open: true,
      })
    }
    yield put({
      type: SET_LIT_SEARCH,
      task_uuid: showPastResult ? data.id : null,
      showPastResult,
    })
  } else if (data.query.type === 'reaction_search') {
    yield put({
      type: SET_SEARCH_TYPE,
      value: 'reaction',
    })
    let match_type = 'exact match'

    const config = {
      ...filterConfig,
      match_type,
    }

    if (params?.yield_range) {
      config.yield = {
        0: params.yield_range.from,
        1: params.yield_range.to,
      }
    }
    if (params?.compound_types) {
      config.compound_types = params.compound_types
    }

    yield put({
      type: SET_FILTER_CONFIG,
      config,
    })

    if (!isFilterOpen) {
      yield put({
        type: SET_OPEN_FILTER,
        open: true,
      })
    }

    yield put({
      type: SET_TEXT,
      text: params?.smiles || '',
    })

    yield put({
      type: SET_REACTIONS_COMPOUND_ID,
      compound_id: params?.compound_id,
    })

    yield put({
      type: SET_REACTIONS_SEARCH,
      text: params?.smiles || '',
      compound_id: params?.compound_id,
      task_uuid: showPastResult ? data?.id : null,
    })
  }
}

export function* getSearchHistoryWatcher() {
  yield takeEvery(GET_HISTORY_INIT, getSearchHistory)
}

export function* repeatSearchWatcher() {
  yield takeEvery(REPEAT_SEARCH, repeatSearch)
}
