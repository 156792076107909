import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  CustomDialog,
  CustomDialogHeader,
} from 'components/common/customDialog'
import DatasetLogs from 'components/DatasetLogs'
import { DATASET_LOGS_PREFIX } from 'components/DatasetLogs/config/config'

const DatasetLogDialog = ({
  id,
  onClose,
  onTransfer,
  basketType,
  onCalculate,
  hasRunningTask,
}) => {
  const { t } = useTranslation()

  const [isDatasetCalculating, setIsDatasetCalculating] = useState(false)

  useEffect(() => {
    if (isDatasetCalculating && !hasRunningTask) setIsDatasetCalculating(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRunningTask])

  return (
    <CustomDialog
      width="39.375rem"
      padding="0rem"
      gap="0rem"
      onClose={onClose}
      style={{
        '.custom-close-icon': {
          top: '1rem',
          right: '1rem',
        },
      }}
    >
      <CustomDialogHeader padding="1.5rem 1.5rem 1rem 1.5rem">
        {t(`${DATASET_LOGS_PREFIX}dataset_history`)}
      </CustomDialogHeader>

      <DatasetLogs
        {...{
          basketType,
          isDatasetCalculating,
        }}
        datasetId={id}
        onTransfer={() => {
          onClose()
          onTransfer()
        }}
        onCalculate={() => {
          setIsDatasetCalculating(true)
          onCalculate()
        }}
      />
    </CustomDialog>
  )
}

export default DatasetLogDialog
