import { useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import Icon from 'components/Icon'
import CustomScrollbar from 'components/CustomScrollbar'

import {
  Structures,
  CustomScrollBtn,
  StructuresListContainer,
} from './index.style'
import StructureViewer from '../StructureViewer'
import { getContentHeight } from './utils/utils'
import { DEFAULT_SCROLL_STEP, STICKY_SCROLL_STEP } from './config/config'

const StructuresList = ({ isSticky, setScrollLeft }) => {
  const innerRef = useRef(null)
  const scrollRef = useRef(null)

  const scrollStep = isSticky ? STICKY_SCROLL_STEP : DEFAULT_SCROLL_STEP

  const { structures, calculation } = useSelector(
    (store) => store.structuresCompare
  )

  const [withScroll, setWithScroll] = useState(false)

  const onScroll = (v) => setScrollLeft(v?.scrollLeft || 0)

  const handleScroll = (direction) => {
    if (innerRef?.current) {
      const { scrollWidth, clientWidth, scrollLeft } = innerRef.current
      const maxScrollLeft = scrollWidth - clientWidth
      let newScrollLeft
      if (direction === 'right') {
        newScrollLeft = Math.min(scrollLeft + scrollStep, maxScrollLeft)

        if (scrollWidth - clientWidth - newScrollLeft < scrollStep)
          newScrollLeft = scrollWidth - clientWidth
      } else {
        newScrollLeft = Math.max(scrollLeft - scrollStep, 0)

        if (newScrollLeft < scrollStep) newScrollLeft = 0
      }
      innerRef.current.scrollTo(newScrollLeft)
      setScrollLeft(newScrollLeft)
    }
  }

  useEffect(() => {
    if (scrollRef.current) {
      const updateHeight = () => {
        setWithScroll(
          scrollRef?.current?.clientWidth < scrollRef?.current?.scrollWidth
        )
      }

      const resizeObserver = new ResizeObserver(() => {
        updateHeight()
      })

      resizeObserver.observe(scrollRef.current)
      updateHeight()

      return () => {
        resizeObserver.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef?.current])

  return (
    <StructuresListContainer
      isSticky={isSticky}
      withScroll={withScroll}
      height={getContentHeight(isSticky, withScroll, true)}
    >
      {withScroll && (
        <CustomScrollBtn
          type="primary"
          left={isSticky ? '-2rem' : '-1.125rem'}
          onClick={() => handleScroll('left')}
          isSticky={isSticky}
          disabled={innerRef?.current?.scrollLeft === 0}
        >
          <Icon iconType="arrowLeft" size="1rem" />
        </CustomScrollBtn>
      )}
      <CustomScrollbar
        className={`scrollbar-structures-compare sticky_${isSticky}`}
        noScrollX={false}
        noScrollY
        style={{
          width: '100%',
          position: 'relative',
          height: getContentHeight(isSticky, withScroll),
        }}
        onScroll={onScroll}
        innerRef={innerRef}
      >
        <Structures isSticky={isSticky} ref={scrollRef}>
          {structures?.map(({ id, smiles, baseID }) => {
            const structureCalculation = calculation.find(
              (el) => el?.data?.baseID === baseID
            )
            const {
              toxy,
              physical,
              bio,
              ecology,
              smiles: calculatedSmiles,
            } = structureCalculation?.data || {}
            return (
              <StructureViewer
                key={id}
                num={baseID}
                {...{ isSticky }}
                params={JSON.stringify({
                  toxy: toxy || 0,
                  physical: physical || 0,
                  bio: bio || 0,
                  ecology: ecology || 0,
                })}
                smiles={calculatedSmiles || smiles}
              />
            )
          })}
        </Structures>
      </CustomScrollbar>{' '}
      {withScroll && (
        <CustomScrollBtn
          right={isSticky ? '-2rem' : '-1.125rem'}
          type="primary"
          onClick={() => handleScroll('right')}
          isSticky={isSticky}
          disabled={
            innerRef?.current?.scrollLeft + innerRef?.current?.clientWidth ===
            innerRef?.current?.scrollWidth
          }
        >
          <Icon iconType="arrowRight" size="1rem" />
        </CustomScrollBtn>
      )}
    </StructuresListContainer>
  )
}

export default StructuresList
