import React, { useState, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import ReactDom from 'react-dom'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { addMolecule, loadMoreMolecules, resetSearch } from 'store/actions/crud'
import BasketsListModal from 'components/BasketsListModal'
import MoleculeIdWithButtons from 'components/MoleculeIdWithButtons'
import { toggleAddMoleculeDialog } from 'store/actions/retrosynthesis'

import './index.css'
import Icon from '../Icon'
import {
  Tags,
  Block,
  AddNew,
  Smiles,
  ToolsBtn,
  Container,
  IconButton,
  ToolButton,
  ToolsBtnNew,
  OneMolecula,
  SmallBackdrop,
  SmallCardHambButton,
  SmilesWithIconContainer,
  SmilesViewContainer,
  EmptySmilesView,
  MoleculeName,
  BestSmilesViewContainer,
  Info,
} from './index.style'
import { setStorage } from 'utils/storage/storage'
import MoleculeFooter from './components/MoleculeFooter'
import { handleShowContextMenu } from 'store/actions/contextMenu'
import {
  closeAddMoleculeDialog,
  editMoleculeNote,
  openAddMoleculeDialog,
  resetMolecule,
  resetRuIupac,
  setEditorIndividualId,
  setMoleculeNoteDialogId,
  updateMoleculeNoteConfig,
} from 'store/actions/molecule'
import IdBlock from 'components/IdBlock'
import { setOpenFilter } from 'store/actions/filter'
import CustomTag from 'components/common/customTag'
import { Caption } from 'components/common/text/index.style'
import { useTheme } from 'styled-components'
import MoleculeNoteDialog from 'components/MoleculeNoteDialog'

import MoleculeStructure from 'components/MoleculeStructure'
import Ketcher from 'components/Ketcher'
import { storeV2 } from 'services/rest'
import { getMoleculeEditErrorText } from './utils/utils'
import { addNotification } from 'store/actions/notifications'
import { setSearchText } from 'store/actions/search'
import { CANVAS_SECONDARY_COLOR } from './config/config'
import BestMatch from './components/BestMatch'

const MoleculeViewer = React.memo((props) => {
  /** INIT - START */
  const {
    params,
    noDelete,
    noEdit,
    onDelete,
    onEdit,
    withData,
    num,
    smiles,
    method,
    clicknaddMode,
    noMenu,
    size,
    noPadding,
    cursor = 'pointer',
    showId,
    type,
    containerWidth,
    width,
    height,
    fixedSize,
    onShowProps,
    coordinates,
    isFastView,
    withColors,
    showIdWithoutMenu,
    isOnlyThreeDotsShow = true,
    noHover,
    noClick = false,
    isRetrosynthesisMolecule,
    openRetrosynthMolMenu,
    isMolMenuOpen,
    isGoToSectionMenu = false,
    handleClick,
    isAddDialogOpen,
    currentBasketId,
    handleClickFromHistory,
    referencesCount,
    tagType = 'grey',
    isBestMatch = false,
    isSimilarResults = false,
    text,
    isShowMolName = false,
    isFullWidth = false,
    noContainerHover = false,
    isDialog = false,
    className = 'mol-viewer-container',
    isShowMoleculeEdit,
    alias,
    note,
    noBaseId = false,
    highlight_smiles = null,
    isToolsDisabled = false,
    structureClassName = '',
    fixedCanvas = false,
    canvasId,
    isFullMode,
  } = props || {}

  const theme = useTheme()
  const dispatch = useDispatch()
  const pathname = useSelector((state) => state.router.location.pathname)
  const searchState = useSelector((store) => store.search)
  const crudState = useSelector((store) => store.crud)
  const filterConfig = useSelector((store) => store.filter.config)
  const isFilterOpen = useSelector((state) => state.filter.open)
  const structuresComparisonList = useSelector(
    (store) => store.structuresCompare.structures
  )

  const pagination = useSelector((state) => state.search.pagination)
  const moleculeDialogId = useSelector(
    (state) => state.molecule.isAddMoleculeDialogOpen
  )

  const noteDialogId = useSelector(
    (state) => state.molecule.moleculeNoteDialogId
  )

  const isAddMoleculeDialogOpen = (num || smiles) === moleculeDialogId
  const contextMenuState = useSelector(
    (state) => state.contextMenu.contextMenuState
  )
  const showBlur = useSelector((store) => store.blur.showBlur)
  const { t } = useTranslation()
  const [state, setState] = useState({
    hashid: uuidv4(),
    isMoleculeHovered: false,
    showKetcher: false,
  })

  const isNotShowSomeLinks = ['/reactions', '/synmap'].includes(pathname)
  const isReactions = ['/reactions'].includes(pathname)
  const isSynMap = ['/synmap'].includes(pathname)
  /** INIT - END */

  /** METHODS - START */
  const getSizeValues = (step, withAlias) => {
    const extraHeight =
      (withAlias && !isSimilarResults) || (text && isSimilarResults) ? 0 : 30
    if (width && height) return { width: width, height: height }
    const defaultSize = { width: 175, height: 132 }
    const widthStep = 50
    const heightStep = 30
    return {
      width: defaultSize.width + step * widthStep,
      height: defaultSize.height + step * heightStep + extraHeight,
    }
  }

  const preparedSmiles = useMemo(() => {
    if (!smiles) return 'O=C(C)Oc1ccccc1C(=O)O'
    if (
      method &&
      (method === 'markush' || method === 'sub') &&
      smiles.includes('|')
    ) {
      return smiles.split('|')[0]?.trim()
    } else {
      return smiles
    }
  }, [smiles, method])

  const handleEdit = (e) => {
    e.stopPropagation()
    onEdit(num)
  }

  const handleDelete = (e) => {
    e.stopPropagation()
    onDelete(num)
  }

  const handleMoleculeClick = (e) => {
    e?.stopPropagation()
    isFilterOpen && dispatch(setOpenFilter(false))
    if (handleClickFromHistory) {
      handleClickFromHistory()
      return
    }
    if (isRetrosynthesisMolecule) return
    if (e.ctrlKey || e.metaKey) {
      handleClick({ id: num, smiles }, 'ctrl')
    } else if (e.shiftKey) {
      handleClick({ id: num, smiles }, 'shift')
    } else if ((!clicknaddMode && showId) || showIdWithoutMenu) {
      if (isSimilarResults) {
        dispatch(resetRuIupac())
        dispatch(resetMolecule())
      }

      dispatch(
        updateMoleculeNoteConfig({
          alias,
          note,
          basketId: isShowMoleculeEdit ? currentBasketId : null,
        })
      )
      dispatch(
        push(`/molecule/${num}`, {
          prev: pathname,
          searchState,
          crudState,
          filterConfig,
        })
      )
    } else onShowProps(num, alias, note)
  }

  const handleShowPreview = (e) => {
    e?.stopPropagation()
    !!num && onShowProps(num, alias, note)
  }
  const handleAddMolecule = (e) => {
    e?.stopPropagation()
    dispatch(openAddMoleculeDialog(num))
  }

  const openIndividual = (e, smiles) => {
    e.stopPropagation()
    if (smiles) {
      isFilterOpen && dispatch(setOpenFilter(false))
      dispatch(push(`/moleditor/${encodeURIComponent(smiles)}`))
      dispatch(setEditorIndividualId(+num))
    }
  }
  const toggleMenu = (e) => {
    e.stopPropagation()
    if (isToolsDisabled) return
    if (showBlur) return
    if (contextMenuState.menu === 'goToSectionMenu') return
    dispatch(
      handleShowContextMenu({
        e,
        menu: isGoToSectionMenu ? 'goToSectionMenu' : 'moleculeMenu',
        item: isGoToSectionMenu
          ? { smiles, num }
          : {
              num,
              note,
              alias,
              smiles,
              onShowProps,
              isNotShowSomeLinks,
              isSynMap,
              noBaseId,
              isReactions,
              isShowMoleculeEdit,
              isShowMoleculeWriteNote: isShowMoleculeEdit && !alias && !note,
              isShowMoleculeEditNote: isShowMoleculeEdit && alias && !note,
              toggleShowNoteDialog,
              currentBasketId,
              toggleShowKetcher,
              isStructureInComparison: structuresComparisonList?.some(
                (el) => el.baseID === Number(num)
              ),
            },
      })
    )
  }

  const handleAddMoleculeToBasket = (ids, newBasketName) => {
    const smilesArray = [
      {
        smiles,
      },
    ]

    if (ids?.length) {
      ids.forEach((el) =>
        dispatch(addMolecule(el?.id, smilesArray, false, el?.name, t))
      )
    } else {
      dispatch(addMolecule(-1, smilesArray, false, newBasketName, t))
    }
  }

  const goToLiterature = () => {
    setStorage('redirect_search_text', smiles)
    window.open('/search')
  }

  const goToReactions = () => {
    // need code
  }

  const toggleShowNoteDialog = () =>
    dispatch(setMoleculeNoteDialogId(noteDialogId ? null : num))

  const handleEditNote = async (params) => {
    dispatch(
      editMoleculeNote({
        molId: num,
        basketId: currentBasketId,
        params,
        isBestMatch,
        isSimilarResults,
        hasNote: alias !== null || note !== null,
      })
    )
  }
  /** METHODS - END */

  // /** EFFECTS - START */
  // /** EFFECTS - END */

  /** CONFIGS - START */

  const iconButtonsConfig = [
    {
      icon: 'molecularEditor',
      onClick: (e) => openIndividual(e, smiles),
      tooltipText: t('molecule_viewer.edit_in_molecular_editor'),
      isHidden: true,
      dataTest: 'molecule-card-btn-molecular-editor-div',
    },
    {
      icon: 'add',
      onClick: handleAddMolecule,
      tooltipText: t('molecule_viewer.add_to_dataset'),
      isHidden: isOnlyThreeDotsShow,
      dataTest: 'molecule-card-btn-add-to-dataset-div',
    },
    {
      icon: 'eyeOpen',
      onClick: handleShowPreview,
      tooltipText: t('molecule_viewer.fast_view'),
      isHidden: isOnlyThreeDotsShow,
      dataTest: 'molecule-card-btn-fast-view-div',
    },
    {
      icon: 'threeDots',
      onClick: toggleMenu,
      tooltipText: '',
      dataTest: 'molecule-card-btn-context-menu-div',
    },
  ]

  const toggleShowKetcher = () =>
    setState((prevState) => ({
      ...prevState,
      showKetcher: !prevState.showKetcher,
    }))

  const handleEditMolInDataset = async (smiles) => {
    try {
      const res = await storeV2(
        `basket/${currentBasketId}/composition/${num}`,
        {
          params: {
            smiles,
          },
        }
      )
      if (res?.status === 200) {
        const { activePage, perPage } = pagination
        const notify = {
          id: uuidv4(),
          name: 'molecule_viewer.molecule_edit.molecule_edit_success',
          notification_type: 'success',
          autoRemove: true,
          timeout: 5000,
        }
        dispatch(addNotification(notify))
        dispatch(setSearchText(''))
        dispatch(resetSearch())

        dispatch(
          loadMoreMolecules({
            basket: currentBasketId,
            limit: perPage,
            page: activePage,
          })
        )
      } else if (res?.status === 204) {
        const notify = {
          id: uuidv4(),
          name: 'notification.error',
          text: 'molecule_viewer.molecule_edit.structure_already_exists',
          notification_type: 'error',
          autoRemove: true,
          timeout: 5000,
        }
        dispatch(addNotification(notify))
      }
    } catch (e) {
      const errorText = getMoleculeEditErrorText(
        e?.response?.data?.result?.error_message
      )
      const id = uuidv4()
      const notify = {
        id,
        name: 'notification.error',
        text: errorText,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }
      dispatch(addNotification(notify))
    }
  }
  /** CONFIGS - END */

  return (
    <>
      <Container
        className={className}
        containerWidth={containerWidth}
        onMouseEnter={() =>
          setState((prevState) => ({
            ...prevState,
            isMoleculeHovered: true,
          }))
        }
        onMouseLeave={(e) =>
          setState((prevState) => ({
            ...prevState,
            isMoleculeHovered: false,
          }))
        }
        isBestMatch={isBestMatch}
        isFullWidth={isFullWidth}
        noContainerHover={noContainerHover}
        isDialog={isDialog}
        noClick={noClick}
      >
        <OneMolecula
          isRetrosynthesisMolecule={isRetrosynthesisMolecule}
          onClick={
            isDialog || isToolsDisabled || isFullMode
              ? () => undefined
              : handleMoleculeClick
          }
          type={(clicknaddMode ? 'add-new' : type) || undefined}
          cursor={cursor}
          noHover={noHover}
          isBestMatch={isBestMatch}
          isFullWidth={isFullWidth}
          isOnlyThreeDotsShow={isOnlyThreeDotsShow}
          isDialog={isDialog}
        >
          {isBestMatch ? (
            <BestMatch
              {...{
                num,
                text,
                alias,
                note,
                params,
                withColors,
                preparedSmiles,
                referencesCount,
                iconButtonsConfig,
              }}
              onLinkActionClick={(value) => {
                value === 'literature' ? goToLiterature() : goToReactions()
              }}
              onOpenNote={toggleShowNoteDialog}
            />
          ) : (
            <Info>
              {((!clicknaddMode && showId) || showIdWithoutMenu) &&
                !isRetrosynthesisMolecule && (
                  <MoleculeIdWithButtons
                    {...{
                      num,
                      noPadding,
                      showIdWithoutMenu,
                      iconButtonsConfig,
                      isGoToSectionMenu,
                      toggleMenu,
                    }}
                    type={tagType}
                    withTooltip={false}
                    isBestMatch={isBestMatch}
                    isOnlyThreeDotsShow={isOnlyThreeDotsShow}
                    isDialog={isDialog}
                  />
                )}

              <SmilesViewContainer
                title={clicknaddMode ? 'Click to add molecule' : smiles}
                width={fixedSize ? width : undefined}
                height={fixedSize ? height : undefined}
                className="canvas-container"
              >
                {clicknaddMode ? (
                  <EmptySmilesView
                    width={getSizeValues(size)?.width}
                    height={getSizeValues(size)?.height}
                    data-canvas="mol-viewer-canvas"
                  />
                ) : (
                  <MoleculeStructure
                    structure={preparedSmiles}
                    width={getSizeValues(size, !!alias)?.width}
                    height={getSizeValues(size, !!alias)?.height}
                    className={structureClassName}
                    highlight_smiles={highlight_smiles}
                    extraDetails={
                      isRetrosynthesisMolecule || isDialog
                        ? { backgroundColour: CANVAS_SECONDARY_COLOR }
                        : {}
                    }
                    fixedCanvas={fixedCanvas}
                    canvasId={canvasId}
                  />
                )}
              </SmilesViewContainer>

              {!!coordinates && (
                <>
                  {isFastView && (
                    <SmilesWithIconContainer>
                      <Smiles>{smiles}</Smiles>
                    </SmilesWithIconContainer>
                  )}
                  <Tags withMargin={!isFastView} withBackground={!isFastView}>
                    <CustomTag>
                      {'X: '}
                      {coordinates.x.toFixed(2)}
                    </CustomTag>
                    <CustomTag>
                      {'Y: '}
                      {coordinates.y.toFixed(2)}
                    </CustomTag>
                  </Tags>
                </>
              )}
              {!clicknaddMode && !noMenu && noEdit && noDelete && !showId && (
                <SmallCardHambButton onClick={toggleMenu} disabled={showBlur}>
                  <Icon iconType="threeDots" />
                </SmallCardHambButton>
              )}
              {clicknaddMode || (noEdit && noDelete) || showId ? null : (
                <ToolsBtn disabled={isToolsDisabled}>
                  <ToolButton size="small" disabled={isToolsDisabled}>
                    {(!!noEdit && !!noDelete) || noMenu ? null : (
                      <Icon
                        iconType="threeDots"
                        onClick={toggleMenu}
                        size="1.25rem"
                      />
                    )}
                    {noEdit ? null : (
                      <Icon
                        iconType="edit"
                        onClick={handleEdit}
                        size="1.25rem"
                        dataTest="molecule-edit-btn"
                      />
                    )}
                    {noDelete || isToolsDisabled ? null : (
                      <Icon
                        iconType="deleted"
                        onClick={handleDelete}
                        size="1.25rem"
                        dataTest="molecule-delete-btn"
                      />
                    )}
                  </ToolButton>
                </ToolsBtn>
              )}
              {isRetrosynthesisMolecule && (
                <SmallBackdrop
                  withBlur={isMolMenuOpen || state.isMoleculeHovered}
                >
                  <Block>
                    <IdBlock
                      num={num}
                      type="white"
                      withTooltip={false}
                      isDialog={isDialog}
                    />
                  </Block>
                  <ToolsBtnNew
                    isMolMenuOpen={isMolMenuOpen}
                    isHovered={state.isMoleculeHovered}
                  >
                    <ToolButton size="small" withDisabledIcon={!num}>
                      <Icon
                        iconType="threeDots"
                        onClick={(e) => {
                          e.stopPropagation()
                          openRetrosynthMolMenu()
                        }}
                        size="1.25rem"
                        dataTest={'retro-molecule-card-btn-context-menu'}
                      />
                      <Icon
                        iconType="eyeOpen"
                        onClick={handleShowPreview}
                        size="1.25rem"
                        dataTest={'retro-molecule-card-btn-fast-view'}
                      />
                      <Icon
                        iconType="add"
                        onClick={(e) => {
                          e.stopPropagation()
                          dispatch(toggleAddMoleculeDialog([smiles]))
                        }}
                        size="1.25rem"
                        dataTest={'retro-molecule-card-btn-add-to-dataset'}
                      />
                    </ToolButton>
                  </ToolsBtnNew>
                </SmallBackdrop>
              )}

              {withData && (
                <MoleculeFooter
                  {...{
                    num,
                    text,
                    alias,
                    note,
                    params,
                    withColors,
                    referencesCount,
                    isShowMolName,
                  }}
                  onLinkActionClick={(value) => {
                    value === 'literature' ? goToLiterature() : goToReactions()
                  }}
                  isBestMatch={isBestMatch}
                  isSimilarResults={isSimilarResults}
                  isMoleculeViewer
                  onOpenNote={toggleShowNoteDialog}
                />
              )}
            </Info>
          )}

          {clicknaddMode && (
            <AddNew
              isAddDialogOpen={isAddDialogOpen}
              disabled={isToolsDisabled}
            >
              <IconButton
                $onhover={false}
                size="small"
                disabled={isToolsDisabled}
              >
                <Icon iconType="add" size="1rem" />
              </IconButton>
              <Caption
                color="inherit"
                fontWeight={400}
                style={{ cursor: isToolsDisabled ? 'not-allowed' : 'pointer' }}
              >
                {' '}
                {t('molecule_viewer.add_text')}
              </Caption>
            </AddNew>
          )}
        </OneMolecula>
      </Container>

      {isAddMoleculeDialogOpen && (
        <BasketsListModal
          onAgree={handleAddMoleculeToBasket}
          onClose={() => dispatch(closeAddMoleculeDialog())}
          withPublic={false}
          withNew={true}
          actionText={t('molecule_viewer.add_to_dataset')}
          onlyOne
          currentBasketId={currentBasketId}
        />
      )}
      {noteDialogId &&
        noteDialogId === num &&
        !isFullWidth &&
        !isDialog &&
        !isBestMatch && (
          <MoleculeNoteDialog
            {...{ alias, note }}
            onClose={toggleShowNoteDialog}
            onSaveNote={(alias, note) => handleEditNote({ alias, note })}
          />
        )}
      {state.showKetcher &&
        ReactDom.createPortal(
          <Ketcher
            handleData={handleEditMolInDataset}
            closeKetcher={toggleShowKetcher}
            smiles={smiles}
          />,
          document.body
        )}
    </>
  )
})

MoleculeViewer.displayName = 'MoleculeViewer'
export default MoleculeViewer
