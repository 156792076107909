import i18n from 'i18n'
import { LinkRenderer } from './LinkRenderer'
import reactStringReplace from 'react-string-replace'

export const COPIED_PROPERTY_NAMES = [
  'smiles',
  'iupac',
  'syntelly_id',
  'inchi',
  'inchiKey',
]

export const DESC_WITH_GOST = [
  'Acute Aquatic Toxicity 57455-2017',
  'Acute Toxicity Swallowed 56957-2016',
  'Selective Target-Organ Or System Toxicity Single Exposure 58479-2019',
]

export const DESC_WITHOUT_ARTICLE = [
  'CYP1A2',
  'CYP2C19',
  'CYP2C9',
  'CYP2D6',
  'CYP3A4',
  'qsar_dmso_solubility',
  'qsar_retention_time',
  'qsar_refractive_index',
]

export const CATEGORIES_WITH_DESC = [
  'scscore_syba',
  'scscore_comp',
  ...DESC_WITH_GOST,
  ...DESC_WITHOUT_ARTICLE,
]

export const HEADERS_EXCEPTIONS = {
  units: ['donors', 'acceptors', 'bonds', 'rings', 'atoms'],
  descriptions: [
    'TPSA <= 140 Å²',
    'PAINS',
    'QED - quantitative estimate of drug-likeness',
  ],
  headers: [
    'Topological polar surface area (including sulphur and phosphorus)',
  ],
}

export const getCategoryKey = (title) =>
  `molviewer.${title.toLowerCase().replace(/\s+/g, '_')}`

export const getArticleLink = (name) => {
  switch (name) {
    case 'qed':
      return 'https://www.nature.com/articles/nchem.1243'
    case 'scscore_syba':
    case 'syba':
      return 'https://jcheminf.biomedcentral.com/articles/10.1186/s13321-020-00439-2'
    case 'scscore_comp':
    case 'complexity':
      return 'https://pubs.acs.org/doi/abs/10.1021/acs.jcim.7b00622'
    case 'Acute Aquatic Toxicity 57455-2017':
      return 'https://www.rst.gov.ru/portal/gost/home/standarts/catalognational?portal:componentId=3503536e-2ac1-4753-8ed1-09a92fee02de&portal:isSecure=false&portal:portletMode=view&navigationalstate=JBPNS_rO0ABXc5AAZhY3Rpb24AAAABABBjb25jcmV0ZURvY3VtZW50AAZkb2NfaWQAAAABAAQ1NjYzAAdfX0VPRl9f'
    case 'Acute Toxicity Swallowed 56957-2016':
      return 'https://www.rst.gov.ru/portal/gost/home/standarts/catalognational?portal:componentId=3503536e-2ac1-4753-8ed1-09a92fee02de&portal:isSecure=false&portal:portletMode=view&navigationalstate=JBPNS_rO0ABXc5AAZhY3Rpb24AAAABABBjb25jcmV0ZURvY3VtZW50AAZkb2NfaWQAAAABAAQ2MTgzAAdfX0VPRl9f'
    case 'Selective Target-Organ Or System Toxicity Single Exposure 58479-2019':
      return 'https://www.rst.gov.ru/portal/gost/home/standarts/catalognational?portal:componentId=3503536e-2ac1-4753-8ed1-09a92fee02de&portal:isSecure=false&portal:portletMode=view&navigationalstate=JBPNS_rO0ABXc6AAZhY3Rpb24AAAABABBjb25jcmV0ZURvY3VtZW50AAZkb2NfaWQAAAABAAU0MzIwMgAHX19FT0ZfXw**'
    default:
      return ''
  }
}

export const getCustomHeadersDescription = (name) => {
  if (name === 'QED - quantitative estimate of drug-likeness') {
    return (
      <div style={{ textAlign: 'center' }}>
        <div>{i18n.t(`molviewer.tooltip.${name}`)}</div>
        <LinkRenderer href={'https://www.nature.com/articles/nchem.1243'}>
          {i18n.t('molviewer.tooltip.article')}
        </LinkRenderer>
      </div>
    )
  } else if (CATEGORIES_WITH_DESC.includes(name)) {
    const link = getArticleLink(name)

    if (DESC_WITH_GOST.includes(name)) {
      return (
        <div>
          {i18n.t('molviewer.tooltip.gost')}
          <LinkRenderer href={link}>{name.split(' ').slice(-1)}</LinkRenderer>
        </div>
      )
    }

    return (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        <div>{i18n.t(`molviewer.tooltip.${name}`)}</div>
        {!DESC_WITHOUT_ARTICLE.includes(name) && (
          <LinkRenderer href={link}>
            {i18n.t('molviewer.tooltip.article')}
          </LinkRenderer>
        )}
      </div>
    )
  }

  return i18n.t(`molviewer.tooltip.${name}`)
}

export const addSubTags = (str) => {
  return reactStringReplace(str, /(\d+)/g, (match, i) => (
    <sub key={i}>{match}</sub>
  )) //todo: think about alternative
}

export const getDataByCategoryName = (name, data, isGeneratedMol) => {
  const d = data?.[name === 'syntelly_id' ? 'baseID' : name]
  if (name === 'cas_number' && isGeneratedMol) return []

  if (!d) return
  if (d && typeof d === 'object' && name !== 'drug' && name !== 'cas_number') {
    const { value, interval, human_value, unit } = d

    return (
      <span>
        {value} {interval && `± ${interval}`}{' '}
        {human_value && `(${human_value})`} {unit}
      </span>
    )
  }

  if (d && name === 'rdkit_count_chemical_formula') {
    return addSubTags(d)
  }

  return d
}
