import { Tooltip as ReactTooltip } from 'react-tooltip'
import { memo, useEffect, useMemo, useRef, useState } from 'react'

import { AliasLabel } from './index.style'

const Alias = memo(({ num, alias }) => {
  const ref = useRef(null)
  const aliasFormatted = useMemo(() => {
    return Array.isArray(alias) ? alias?.join(', ') : alias
  }, [alias])
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    if (ref?.current?.scrollWidth > ref?.current?.clientWidth)
      setShowTooltip(true)
    else setShowTooltip(false)
  }, [ref])

  return (
    <>
      <AliasLabel ref={ref} data-tooltip-id={`${num}-alias`}>
        {aliasFormatted}
      </AliasLabel>
      {showTooltip && (
        <ReactTooltip
          id={`${num}-alias`}
          className="c-tooltip c-tooltip-base c-tooltip-molecule-card-note"
          classNameArrow="c-tooltip-arrow"
          place={'bottom-start'}
          offset={14}
        >
          {aliasFormatted}
        </ReactTooltip>
      )}
    </>
  )
})

Alias.displayName = 'Alias'

export default Alias
