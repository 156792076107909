import styled from 'styled-components'

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: start;
  gap: ${({ gap }) => (gap ? gap : '16px')};
  flex-grow: ${({ flexGrow }) => flexGrow && flexGrow};

  > .mol-viewer-container {
    align-self: center;
  }
`
