import styled, { css } from 'styled-components'

const hoverColorStyles = css`
  &:hover {
    color: #42c1bf;
    & > svg,
    div {
      color: #42c1bf;
    }
  }
`

export const DescriptedPropertyKey = styled.a`
  display: flex;
  gap: 4px;
  cursor: pointer;

  & > svg {
    color: #616e7c;
    transition: 0.2s;
    min-width: 1rem;

    ${({ status }) =>
      status &&
      css`
        color: ${({ status }) => {
          if (status === 'success') return '#34C759'
          if (status === 'warning') return '#FF3B30'
        }};
      `}
  }

  ${hoverColorStyles}

  @media print {
    & > svg {
      display: none;
    }
  }
`
