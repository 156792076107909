import styled, { css } from 'styled-components'

export const Divider = styled.div`
  width: ${({ width, withRightPadding }) =>
    width ? width : withRightPadding ? 'calc(100% - 0.5rem)' : '100vw'};
  height: 0.0625rem;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.separator};
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`

export const StyledTable = styled.table`
  border-spacing: 0rem 0rem;

  tr,
  tbody {
    position: relative;
  }

  td {
    min-width: 16.625rem;
    width: 16.625rem;
    padding: 0;
  }

  td:first-child {
    min-width: 15.625rem;
    width: 15.625rem;
    padding: 0;
  }

  ${({ isSticky }) =>
    isSticky &&
    css`
      td,
      td:first-child {
        min-width: 16.375rem;
        width: 16.375rem;
        padding: 0;
      }
    `};

  td .property-value_blured-true {
    min-width: 2rem;
  }

  .molecule-key-value-property {
    min-width: ${({ isSticky }) => (isSticky ? '16.375rem' : '15.625rem')};
    width: ${({ isSticky }) => (isSticky ? '16.375rem' : '15.625rem')};
  }

  .molecule-descripted-key {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  .property-name {
    white-space: nowrap;
  }

  .sticky-cell {
    position: sticky;
    left: 0;
  }
`
export const MainHeaderKey = styled.td`
  padding-top: ${({ isFirstRow }) => (isFirstRow ? 0 : '1.5rem')} !important;

  padding-bottom: 0.75rem !important;

  ${({ isFirstColumn }) =>
    !isFirstColumn &&
    css`
      .toggle {
        display: none;
      }
    `}

  & > div {
    font-size: ${({ theme }) => theme.text[14]} !important;
    line-height: ${({ theme }) => theme.text[18]} !important;
    font-weight: ${({ theme }) => theme.fontWeight.semibold} !important;
  }
  .main-property {
    font-size: ${({ theme }) => theme.text[14]} !important;
    line-height: ${({ theme }) => theme.text[18]} !important;
    font-weight: ${({ theme }) => theme.fontWeight.semibold} !important;
  }

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      left: 0;
    `}
`
