import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 0.75rem 0.75rem 0.75rem;
  ${({ isEmpty }) =>
    !isEmpty &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
`

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
