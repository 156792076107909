import {
  STICKY_CONTENT_HEIGHT,
  DEFAULT_CONTENT_HEIGHT,
  STICKY_CONTENT_HEIGHT_NO_SCROLL,
  DEFAULT_CONTENT_HEIGHT_NO_SCROLL,
  STICKY_SCROLL_HEIGHT,
} from '../config/config'

export const getContentHeight = (isSticky, withScroll, isContent) => {
  if (isSticky)
    return withScroll
      ? isContent
        ? STICKY_SCROLL_HEIGHT
        : STICKY_CONTENT_HEIGHT
      : STICKY_CONTENT_HEIGHT_NO_SCROLL
  return withScroll ? DEFAULT_CONTENT_HEIGHT : DEFAULT_CONTENT_HEIGHT_NO_SCROLL
}
