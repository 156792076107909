import { memo, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Icon from 'components/Icon'
import { getCategoryKey } from 'config/molecule/molecule'
import CustomButton from 'components/common/customButton'
import { Headline } from 'components/common/text/index.style'
import { loadCategories, loadServices } from 'store/actions/molecule'
import { CategoryHeader } from 'components/Molecule/category/index.style'

import Category from '../Category'
import {
  COMPARE_CATEGORIES_EXCEPTIONS,
  COMPARE_PROPERTIES_EXCEPTIONS,
} from '../StructuresList/config/config'
import { CategoriesWrapper } from './index.style'
import { ParametersContainer } from '../index.style'

const Categories = memo(({ isSticky, scrollLeft, isSelectedParamsTable }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedParams = useSelector(
    (store) => store.compareParams.structuresParams
  )
  const categories = useSelector((store) => store.molecule.categories)
  const services = useSelector((store) => store.molecule.services)
  const blurNonApplicable = useSelector(
    (store) => store.structuresCompare.blurNonApplicable
  )

  const { data: categoriesData } = categories

  const { data: servicesData } = services
  const { calculation } = useSelector((store) => store.structuresCompare)

  const [expanded, setExpanded] = useState([])

  useEffect(() => {
    setExpanded(categoriesData?.map((el) => el.category_id) || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesData?.length])

  useEffect(() => {
    if (!categoriesData || categoriesData.length === 0) {
      dispatch(loadCategories())
    }

    if (!servicesData || servicesData.length === 0) {
      dispatch(loadServices())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <CategoriesWrapper isSelectedParamsTable={isSelectedParamsTable}>
      {categoriesData
        .filter(
          ({ category_name }) =>
            !COMPARE_CATEGORIES_EXCEPTIONS.includes(category_name)
        )
        .filter(({ category_title }) =>
          !isSelectedParamsTable
            ? true
            : selectedParams.some((s) => s.category_title === category_title)
        )
        .map(({ category_name, category_id, category_title, inner }, i) => {
          const isExpanded = expanded.includes(category_id)
          const innerForRender = inner.filter(
            ({ name }) => !COMPARE_PROPERTIES_EXCEPTIONS.includes(name)
          )
          const getFiltered = () => {
            if (!isSelectedParamsTable) return innerForRender
            if (category_title === 'Drug-likeness')
              return selectedParams.some((s) => s?.header) ? innerForRender : []
            return innerForRender.filter((el) =>
              selectedParams.some((s) => s.name === el.name)
            )
          }
          const filteredInner = getFiltered()

          return (
            <ParametersContainer
              isSelectedParamsTable={isSelectedParamsTable}
              key={`${category_name}-selected-${isSelectedParamsTable}`}
            >
              {!isSelectedParamsTable && (
                <CategoryHeader
                  isExpanded={isExpanded}
                  onClick={() =>
                    setExpanded((prev) =>
                      prev.includes(category_id)
                        ? prev.filter((el) => el !== category_id)
                        : [...prev, category_id]
                    )
                  }
                >
                  <Headline fontWeight={theme.fontWeight.semibold}>
                    {t(getCategoryKey(category_name))}
                  </Headline>
                  <CustomButton
                    type="icon_secondary"
                    defaultPadding="0.25rem"
                    defaultColor={
                      isExpanded
                        ? theme.colors.icons.accentPrimary
                        : theme.colors.icons.tertiary
                    }
                    borderRadius="0.375rem"
                  >
                    {' '}
                    <Icon
                      iconType={isExpanded ? 'arrowTop' : 'arrowDown'}
                      size="0.75rem"
                    />
                  </CustomButton>
                </CategoryHeader>
              )}
              {filteredInner.length > 0 &&
                (isExpanded || isSelectedParamsTable) && (
                  <Category
                    {...{
                      isSticky,
                      scrollLeft,
                      isExpanded,
                      calculation,
                      category_id,
                      category_name,
                      selectedParams,
                      category_title,
                      blurNonApplicable,
                      isSelectedParamsTable,
                    }}
                    inner={filteredInner}
                    isFirstCategory={i === 0}
                  />
                )}
            </ParametersContainer>
          )
        })}
    </CategoriesWrapper>
  )
})

Categories.displayName = 'Categories'

export default Categories
