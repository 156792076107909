import { Fragment } from 'react'
import { useTheme } from 'styled-components'

import CustomScrollbar from 'components/CustomScrollbar'
import MoleculeViewer from 'components/MoleculeViewer'
import { ReactionOperatorIcon } from 'components/ReactionViewer/index.style'
import {
  CardReaction,
  Container,
  ReactBlock,
  SvgWrapper,
} from 'components/SearchReactionsCard/index.style'
import { useSelector } from 'react-redux'

export const ReactionSchema = ({ reactants, products }) => {
  const theme = useTheme()
  const highlighted_molecule_id = useSelector(
    (state) => state.reactionsSearch.compound_id
  )

  return (
    <CustomScrollbar
      noScrollY={true}
      noScrollX={false}
      style={{ height: '10.75rem' }}
      disableTrackXWidthCompensation={true}
      className={'reaction-custom-scrollbar'}
    >
      <CardReaction id="search-reactions-card-reaction">
        <Container>
          <ReactBlock>
            {reactants?.map((el, idx) => {
              return (
                <Fragment key={`l-${idx}`}>
                  <MoleculeViewer
                    fixedSize={true}
                    width={230}
                    height={130}
                    smiles={el?.smiles}
                    noDelete
                    noEdit
                    noMenu
                    noHover={true}
                    noContainerHover={true}
                    noClick={true}
                    type={'no-border-carousel-dialog'}
                    highlight_smiles={
                      el?.id === highlighted_molecule_id ? el?.smiles : null
                    }
                  />
                  {idx !== reactants.length - 1 && (
                    <SvgWrapper size="1.75rem">
                      <ReactionOperatorIcon
                        iconType="add"
                        size="1.75rem"
                        color={theme.colors.icons.accentPrimary}
                      />
                    </SvgWrapper>
                  )}
                </Fragment>
              )
            })}

            <SvgWrapper size="1.75rem">
              <ReactionOperatorIcon
                iconType="reactionResultIcon"
                size="1.75rem"
                color={theme.colors.icons.accentPrimary}
              />
            </SvgWrapper>

            {products?.map((el, idx) => {
              return (
                <Fragment key={el.id ?? `r-${idx}`}>
                  <MoleculeViewer
                    fixedSize={true}
                    width={230}
                    height={130}
                    smiles={el?.smiles}
                    noDelete
                    noEdit
                    noMenu
                    noHover
                    noContainerHover={true}
                    noClick={true}
                    type={'no-border-carousel-dialog'}
                    highlight_smiles={
                      el?.id === highlighted_molecule_id ? el?.smiles : null
                    }
                  />
                  {idx !== products.length - 1 && (
                    <SvgWrapper size="1.75rem">
                      <ReactionOperatorIcon
                        iconType="add"
                        size="1.75rem"
                        color={theme.colors.icons.accentPrimary}
                      />
                    </SvgWrapper>
                  )}
                </Fragment>
              )
            })}
          </ReactBlock>
        </Container>
      </CardReaction>
    </CustomScrollbar>
  )
}
