import CustomButton from 'components/common/customButton'
import styled, { css, keyframes } from 'styled-components'

const hideAnimation = keyframes`
from {
	opacity: 1;
	right: 1.5rem;
	scale: 1
}
to {
	right: -1.5rem;
	opacity: 0;
	scale: 0.7
}
`

const openAnimation = keyframes`
from {
	right: -1.5rem;
	opacity: 0;
	scale: 0.7
}
to {
	opacity: 1;
	right: 1.5rem;
	scale: 1;
}
`

export const ButtonWrapper = styled(CustomButton)`
  transition: all 0.5s ease;
  display: flex;
  z-index: 11;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: ${({ bottom }) => bottom || '5rem'};
  right: ${({ visible }) => (visible ? '1.5rem' : '-1.5rem')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  box-shadow: ${({ theme }) => theme.shadow.default};
  background: ${({ theme }) => theme.colors.backgrounds.primary};
  ${({ isNotificationBtn }) =>
    isNotificationBtn &&
    css`
      animation: ${({ visible }) => (visible ? openAnimation : hideAnimation)}
        0.5s forwards;
    `};
`

export const Count = styled.div`
  display: flex;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 1.75rem;
  left: 1.75rem;
  border-radius: 0.5rem;
  padding: 0.25rem;
  background: ${({ theme }) => theme.colors.backgrounds.accent};
  min-width: 1.5rem;
  box-shadow: ${({ theme }) => theme.shadow.default};
`
