const NotificationSvg = ({ mainColor, additionalColor }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.3125 17.6C6.08229 17.6 5.88946 17.5232 5.734 17.3696C5.57854 17.216 5.50054 17.0261 5.5 16.8C5.5 16.5733 5.578 16.3835 5.734 16.2304C5.89 16.0773 6.08283 16.0005 6.3125 16H7.125V10.4C7.125 9.29333 7.46354 8.31013 8.14062 7.4504C8.81771 6.59067 9.69792 6.0272 10.7812 5.76V5.2C10.7812 4.86667 10.8999 4.58347 11.1371 4.3504C11.3744 4.11733 11.662 4.00053 12 4C12.3385 4 12.6264 4.1168 12.8637 4.3504C13.1009 4.584 13.2193 4.8672 13.2187 5.2V5.76C14.3021 6.02667 15.1823 6.59013 15.8594 7.4504C16.5365 8.31067 16.875 9.29387 16.875 10.4V16H17.6875C17.9177 16 18.1108 16.0768 18.2668 16.2304C18.4228 16.384 18.5005 16.5739 18.5 16.8C18.5 17.0267 18.422 17.2168 18.266 17.3704C18.11 17.524 17.9172 17.6005 17.6875 17.6H6.3125Z"
        fill={mainColor}
      />
      <path
        d="M12 20C11.5531 20 11.1707 19.8435 10.8527 19.5304C10.5348 19.2173 10.3755 18.8405 10.375 18.4H13.625C13.625 18.84 13.466 19.2168 13.1481 19.5304C12.8301 19.844 12.4474 20.0005 12 20Z"
        fill={additionalColor}
      />
    </svg>
  )
}

export default NotificationSvg
