import styled, { css, keyframes } from 'styled-components'

import CustomButton from 'components/common/customButton'

const openAnimation = keyframes`
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const hideAnimation = keyframes`
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`

export const leftFilterScrollbarStyles = css`
  scrollbar-color: rgba(203, 206, 209, 0.6) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(203, 206, 209, 0.24);
    border-radius: 12px;
    border: 8px solid transparent;
    background-clip: padding-box;
    transition: all 0.5s;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: 7px solid transparent;
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  position: relative;
  height: 100%;
  margin-left: -0.75rem;
  background: #ffffff;
  border-radius: 0rem 0.75rem 0.75rem 0rem;
  width: ${({ opened }) => (opened ? '17.5rem' : '0.75rem')};

  padding: 0;
  overflow: ${({ opened }) => (opened ? 'unset' : 'hidden')};

  transition: all 0.5s;
  z-index: 10;
  box-shadow: ${({ theme, opened }) => (opened ? theme.shadow.right : 'none')};
`

export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 1.25rem;
  height: 2.375rem;
  padding-left: 2rem;
  padding-right: 1.25rem;

  animation: ${({ opened }) => (opened ? openAnimation : hideAnimation)} 0.5s
    forwards;
  & > span {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #2d3745;
  }
`

export const FilterBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: 1.25rem;
  padding-right: 1.25rem;
  padding-left: 2rem;
  overflow: scroll;
  overflow-x: hidden;
  animation: ${({ opened }) => (opened ? openAnimation : hideAnimation)} 0.5s;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  &::-webkit-scrollbar {
    width: 0;
  }
`

export const StyledButton = styled(CustomButton)`
  transition: color 0.3s;
  width: 100%;
`

export const FilterButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-top: auto;
  padding: 0rem 1.25rem 1.25rem 2rem;
  animation: ${({ opened }) => (opened ? openAnimation : hideAnimation)} 0.5s;
`

export const HideBtn = styled.button`
  margin-left: auto;
  border: none;
  outline: none;
  display: flex;

  width: 1.25rem;
  height: 1.25rem;
  padding: 0.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  background: ${({ theme, bg }) => (bg ? bg : theme.colors.buttons.secondary)};

  & > svg {
    cursor: pointer;
    align-self: center;
    color: ${({ theme, opened }) =>
      opened ? theme.colors.buttons.accent : theme.colors.icons.tertiary};
    transition: color 0.3s;
    transform: ${({ opened }) =>
      opened ? 'rotateX(180deg)' : 'rotateX(0deg)'};

    &:hover {
      color: #616e7c;
    }
  }
`
