import i18n from 'i18n'
import { useEffect, useRef } from 'react'

import Icon from 'components/Icon'
import {
  SubMenuActionsIds,
  TransferToActionId,
} from 'components/common/contextMenu/config'
import { Label } from 'components/common/text/index.style'

import { Menu, MenuItem } from './index.style'

const CustomNodeMenu = ({
  type,
  onCloseMenu,
  setIsSubMenuOpen,
  menuButtonsConfig,
}) => {
  const menuRef = useRef(null)
  const currentLang = i18n?.language

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef?.current && !menuRef.current.contains(e.target)) onCloseMenu()
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const onMouseLeave = (ev) => {
    const targetId = ev?.target?.id
    if (
      !(
        SubMenuActionsIds.some((el) => `context-menu-${el}` === targetId) ||
        `context-menu-${TransferToActionId}` === targetId
      )
    ) {
      setIsSubMenuOpen(false)
    }
  }

  const handleMenuLeave = (ev) => {
    type === 'submenu' &&
      ev?.target?.id !== `context-menu-${TransferToActionId}` &&
      setIsSubMenuOpen(false)
  }

  return (
    <Menu
      type={type}
      ref={menuRef}
      currentLang={currentLang}
      onMouseLeave={handleMenuLeave}
    >
      {menuButtonsConfig?.map(
        ({ id, text, hidden, onClick, disabled, withSubActions }, i) =>
          !hidden && (
            <MenuItem
              id={`context-menu-${id}`}
              key={`context-menu-${id}`}
              onClick={(e) => {
                e.stopPropagation()
                !disabled && onClick(e)
              }}
              disabled={disabled}
              onMouseEnter={(e) => {
                if (type === 'submenu') return
                withSubActions
                  ? setIsSubMenuOpen(true)
                  : setIsSubMenuOpen(false)
              }}
              onMouseLeave={onMouseLeave}
            >
              <Label color="inherit">{text}</Label>
              {withSubActions && <Icon iconType="arrowRight" size="1rem" />}
            </MenuItem>
          )
      )}
    </Menu>
  )
}

export default CustomNodeMenu
