import styled from 'styled-components'

export const SortItems = styled.div`
  display: flex;
  column-gap: 0.5rem;
  transition: all 0.3s;
`

export const SortItem = styled.div`
  font-size: ${({ theme }) => theme.text[12]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[16]};
  padding: 0.375rem 0.625rem;
  border-radius: 0.5rem;

  background: ${({ active, type, disabled, theme }) => {
    if (type === 'white')
      return active && !disabled
        ? theme.colors.backgrounds.accent
        : theme.colors.backgrounds.primary

    return active && !disabled
      ? theme.colors.backgrounds.accent
      : theme.colors.backgrounds.secondary
  }}};

  color: ${({ theme, active, disabled }) => {
    if (disabled) return theme.colors.text.tertiary
    return active ? theme.colors.text.white : theme.colors.text.secondary
  }};

  transition: all 0.3s;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  white-space: nowrap;

  &:hover {
    background: ${({ theme, active, disabled, type }) => {
      if (active && !disabled) return theme.colors.buttons.accentHover
      return type === 'white'
        ? theme.colors.backgrounds.primary
        : theme.colors.buttons.secondary
    }};
    color: ${({ theme, active, disabled }) => {
      if (disabled) return theme.colors.text.tertiary
      return active ? theme.colors.text.white : theme.colors.text.accentPrimary
    }};
  }
`
