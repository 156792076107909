export const DATASET_LOGS_PREFIX = 'baskets.dataset_logs_dialog.'
export const LOGS_SORT_CONFIG = [
  'all',
  'added',
  'removed',
  'rejected',
  'calculated',
  'changed',
]

export const ROLES_SWITCH_CONFIG = [
  { title: `${DATASET_LOGS_PREFIX}all_users`, type: 'all' },
  {
    title: `${DATASET_LOGS_PREFIX}admin`,
    type: 'admin',
  },
  {
    title: `${DATASET_LOGS_PREFIX}employees`,
    type: 'employees',
  },
]
