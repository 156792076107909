import { useTranslation } from 'react-i18next'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Card, CardData, CardDataItem, Separator, Wrapper } from './index.style'
import { useSelector } from 'react-redux'
import { memo } from 'react'
import { useTheme } from 'styled-components'
import Icon from 'components/Icon'
import { getPeriodComparisonData } from '../helpers'
import { Caption } from 'components/common/text/index.style'

const PrevPeriodStatistics = memo(({ data, height }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const selectedChart = useSelector(
    (state) => state.statistics.selectedChartSection
  )
  const selectedSubSection = useSelector(
    (state) => state.statistics.selected_sub_section
  )

  const prepared_data = getPeriodComparisonData({
    data,
    selectedChart,
    selectedSubSection,
  })

  return (
    <Wrapper height={height}>
      {prepared_data?.map((card, index) => {
        const { current_sum, prev_sum, title } = card || {}
        const percent_diff = Math.round(
          ((current_sum - prev_sum) / prev_sum) * 100
        )
        const isSame = percent_diff === 0
        const isGrowth = percent_diff > 0

        return (
          <>
            <Card key={index}>
              <Caption
                fontWeight={theme.fontWeight.medium}
                color={theme.colors.text.primary}
              >
                {t(`admin_statistics.tooltip.${title}`)}
              </Caption>
              {!current_sum || !prev_sum ? (
                <Card key={index}>
                  <CardData>
                    <CardDataItem
                      radius={'0.375rem'}
                      bg={theme.colors.buttons.secondary}
                      color={theme.colors.text.tertiary}
                      data-tooltip-id="no-statistics-data-tooltip"
                    >
                      <Caption
                        color={theme.colors.text.primary}
                        fontWeight={theme.fontWeight.medium}
                      >
                        {t('admin_statistics.no_data')}
                      </Caption>
                      <Icon iconType="info" size="1rem" />
                      <ReactTooltip
                        id="no-statistics-data-tooltip"
                        className={`c-tooltip c-tooltip-base`}
                        classNameArrow="c-tooltip-arrow"
                        place={'right'}
                        positionStrategy={'fixed'}
                      >
                        {t('admin_statistics.no_data_to_compare')}
                      </ReactTooltip>
                    </CardDataItem>
                  </CardData>
                </Card>
              ) : (
                <CardData>
                  <CardDataItem
                    radius={'0.375rem 0 0 0.375rem'}
                    bg={theme.colors.buttons.secondary}
                  >
                    <Caption
                      color={theme.colors.text.primary}
                      fontWeight={theme.fontWeight.medium}
                    >
                      {prev_sum}
                    </Caption>
                  </CardDataItem>
                  <CardDataItem
                    color={
                      isSame
                        ? theme.colors.text.tertiary
                        : isGrowth
                        ? theme.colors.statuses.success
                        : theme.colors.statuses.error
                    }
                    bg={
                      isSame
                        ? theme.colors.buttons.secondary
                        : isGrowth
                        ? theme.colors.backgrounds.success
                        : theme.colors.backgrounds.error
                    }
                    radius={'0 0.375rem 0.375rem 0'}
                    rotate={!isGrowth && !isSame && '90'}
                  >
                    <Icon
                      iconType={isSame ? 'minus' : 'statisticsArrow'}
                      size="1rem"
                    />

                    <Caption
                      fontWeight={theme.fontWeight.medium}
                      color={
                        isSame
                          ? theme.colors.text.tertiary
                          : isGrowth
                          ? theme.colors.statuses.success
                          : theme.colors.statuses.error
                      }
                    >{`${percent_diff}%`}</Caption>
                  </CardDataItem>
                </CardData>
              )}
            </Card>
            {prepared_data?.length > 1 && index < prepared_data?.length - 1 && (
              <Separator />
            )}
          </>
        )
      })}
    </Wrapper>
  )
})
PrevPeriodStatistics.displayName = 'PrevPeriodStatistics'
export default PrevPeriodStatistics
