import { memo, useEffect, useMemo, useState } from 'react'

import SortTabs from 'components/SortTabs'
import CustomSwitch from 'components/common/customSwitch'
import SkeletonComponent from 'components/common/skeleton'

import {
  LOGS_SORT_CONFIG,
  DATASET_LOGS_PREFIX,
  ROLES_SWITCH_CONFIG,
} from '../config/config'
import LogsList from '../LogsList'
import { LogsListWithSort } from './index.style'
import { getFilteredByUsersLogsList, getFilteredLogsList } from '../utils/utils'

const LogsContent = memo(
  ({ logList, logsLoadingStatus, basketType, isCompanyDatasetsLogging }) => {
    const [filterByType, setFilterByType] = useState('all')
    const [filterByUsers, setFilterByUsers] = useState('all')
    const [showUsersFilter, setShowUsersFilter] = useState(false)

    useEffect(() => {
      if (
        basketType === 'companies' &&
        logList.some((el) => el?.user?.role === 'admin') &&
        logList.some((el) => el?.user?.role !== 'admin')
      )
        setShowUsersFilter(true)
      else setShowUsersFilter(false)
    }, [basketType, logList])

    const logListFilteredByUsers = useMemo(() => {
      const filteredLogsList = getFilteredByUsersLogsList(
        logList,
        filterByUsers
      )

      return filteredLogsList
    }, [logList, filterByUsers])

    const logListFiltered = useMemo(() => {
      const filteredLogsList = getFilteredLogsList(
        logListFilteredByUsers,
        filterByType
      )

      if (!filteredLogsList.length) setFilterByType('all')

      return filteredLogsList
    }, [logListFilteredByUsers, filterByType])

    return (
      <LogsListWithSort>
        {showUsersFilter && (
          <CustomSwitch
            items={ROLES_SWITCH_CONFIG}
            keyTemplate="dataset-logs-role"
            active={filterByUsers}
            btnWidth="12rem"
            onClick={setFilterByUsers}
            bg={isCompanyDatasetsLogging ? 'white' : 'grey'}
          />
        )}

        {logsLoadingStatus !== 'success' ? (
          <SkeletonComponent width="100%" height="1.75rem" variant="grey" />
        ) : (
          <SortTabs
            className={isCompanyDatasetsLogging ? '' : 'sticky-tabs'}
            type={isCompanyDatasetsLogging ? 'white' : 'grey'}
            sortType={filterByType}
            onChangeSortType={setFilterByType}
            sortConfig={LOGS_SORT_CONFIG.map((el) => ({
              value: el,
              name: `${DATASET_LOGS_PREFIX}${el}`,
              disabled: !getFilteredLogsList(logListFilteredByUsers, el)
                ?.length,
            }))}
          />
        )}
        <LogsList
          logs={logListFiltered}
          status={logsLoadingStatus}
          {...{ basketType, isCompanyDatasetsLogging }}
        />
      </LogsListWithSort>
    )
  }
)

LogsContent.displayName = 'LogsContent'

export default LogsContent
