import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { SortItem, SortItems } from './index.style'

const SortTabs = memo(
  ({ sortType, sortConfig, type = 'white', onChangeSortType, className }) => {
    const { t } = useTranslation()

    return (
      <SortItems className={className || ''}>
        {sortConfig.map(({ name, value, disabled }, key) => (
          <SortItem
            type={type}
            active={sortType === value}
            key={key}
            onClick={() => !disabled && onChangeSortType(value)}
            disabled={disabled}
          >
            {t(name)}
          </SortItem>
        ))}
      </SortItems>
    )
  }
)

SortTabs.displayName = 'SortTabs'

export default SortTabs
