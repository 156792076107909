import { useTheme } from 'styled-components'

import { TextMedium, TitleTertiary } from 'components/common/text/index.style'

import {
  ProgressItem,
  DescriptionBlock,
  ProgressContainer,
  TitleWithDescriptions,
  InfoBlockWithProgress,
} from './index.style'
import { cleanHtml } from 'utils/common/common'

const InfoWithProgress = ({
  title,
  progress,
  description,
  additionalDescription,
  showProgress = true,
}) => {
  const theme = useTheme()
  return (
    <InfoBlockWithProgress>
      <TitleWithDescriptions gap={showProgress ? '0.25rem' : '0.5rem'}>
        <TitleTertiary>{title}</TitleTertiary>
        <DescriptionBlock>
          <TextMedium
            dangerouslySetInnerHTML={{
              __html: cleanHtml(description),
            }}
            color={theme.colors.text.secondary}
            fontWeight={theme.fontWeight.light}
          />
          {!!additionalDescription && (
            <TextMedium
              color={theme.colors.text.secondary}
              fontWeight={theme.fontWeight.light}
            >
              {additionalDescription}
            </TextMedium>
          )}
        </DescriptionBlock>
      </TitleWithDescriptions>
      {showProgress && (
        <ProgressContainer>
          <ProgressItem>
            <span style={{ width: `${progress * 2}%` }} />
          </ProgressItem>
          <ProgressItem>
            <span
              style={{ width: `${(progress > 50 ? progress - 50 : 0) * 2}%` }}
            />
          </ProgressItem>
          <ProgressItem>
            <span
              style={{
                width: `${(progress > 50 * 2 ? progress - 50 * 2 : 0) * 2}%`,
              }}
            />
          </ProgressItem>
        </ProgressContainer>
      )}
    </InfoBlockWithProgress>
  )
}
export default InfoWithProgress
