import {
  SET_LIT_DIALOG_CONFIG,
  SET_LIT_FILTER_CONFIG,
  SET_OPEN_LIT_DIALOG,
  SET_OPEN_LIT_FILTER,
  SET_LIT_FILTER_DOC_TYPE,
  SET_PREV_SEARCH_DATA_ID,
  SET_LITERATURE_LIST_VIEW,
  SET_LIT_PAGE_OPEN,
  SET_LIT_SYNTELLY_ID,
  SET_LAST_SEARCHED_TEXT,
  SET_SHOULD_BLOCKS_INDEXES,
  UPDATE_LAST_SEARCH_VALUES,
} from 'store/constants/literature'

const initialState = {
  filter: { open: false, config: {}, docType: '', shouldBlocksIndexes: [] },
  dialog: { open: false, config: {} },
  prevSearchDataId: null,
  listView: 'line',
  litPage: { open: false, config: {} },
  syntellyId: null,
  lastSearchedText: null, //по этому слову осуществлялся поиск в лит-ре в предыдущий раз,
  // если оно отлично от нового введенного, то при поиске обновятся
  //уточняющие фильтры abstract и title. Cделано это чтобы можно было
  //доуточнять abstract/title в фильтре при неизменном тексте поискового инпута
  //и при этом при явной попытке пользователя начать новый поиск, дать ему произойти
  //т.к. не обновленные abstract/title выдадут старый результат, не смотря на новый введенный текст в поисковик
}

const literatureReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SET_OPEN_LIT_FILTER:
      return {
        ...state,
        filter: { ...state.filter, open: payload.isOpen },
      }
    case SET_LIT_FILTER_CONFIG:
      return {
        ...state,
        filter: { ...state.filter, config: { ...payload.config } },
      }
    case SET_LIT_FILTER_DOC_TYPE:
      return {
        ...state,
        filter: { ...state.filter, docType: payload.docType },
      }
    case SET_OPEN_LIT_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          open: payload.isOpen,
        },
      }
    case SET_LIT_DIALOG_CONFIG:
      return {
        ...state,
        dialog: { ...state.dialog, config: { ...payload.config } },
      }
    case SET_PREV_SEARCH_DATA_ID:
      return {
        ...state,
        prevSearchDataId: payload.prevSearchDataId,
      }
    case SET_LITERATURE_LIST_VIEW:
      return {
        ...state,
        listView: payload.value,
      }

    case SET_LIT_PAGE_OPEN:
      return {
        ...state,
        litPage: { open: payload.open, config: { ...payload.config } },
      }
    case SET_LIT_SYNTELLY_ID:
      return {
        ...state,
        syntellyId: payload.syntellyId,
      }
    case SET_LAST_SEARCHED_TEXT:
      return {
        ...state,
        lastSearchedText: payload.text,
      }
    case SET_SHOULD_BLOCKS_INDEXES: //индексы блоков лит поиска, у которых внешняя связь с соседним блоком or/should
      return {
        ...state,
        filter: { ...state.filter, shouldBlocksIndexes: payload.data },
      }
    case UPDATE_LAST_SEARCH_VALUES:
      return {
        ...state,
        prevSearchDataId: payload.prevId,
        syntellyId: null,
        lastSearchedText: payload.text,
      }
    default:
      return state
  }
}

export default literatureReducer
