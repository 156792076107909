import { memo } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Property from 'components/Property'
import PropertyName from 'components/PropertyName'
import { getCategoryKey } from 'config/molecule/molecule'
import CheckboxWithTitle from 'components/CheckboxWithTitle'

import EmptyCells from '../EmptyCells'
import { MAX_STRUCTURES_COUNT } from '../config'
import { Divider, StyledTable } from '../Category/index.style'

const PropertiesTable = memo(
  ({
    name,
    isSticky,
    onToggle,
    isNotLast,
    isSelected,
    category_id,
    calculation,
    category_title,
    selectedParams,
    blurNonApplicable,
    inner_category_title,
    isSelectedParamsTable,
  }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    if (isSelectedParamsTable && selectedParams?.every((s) => s?.header))
      return null
    return (
      <>
        <StyledTable isSticky={isSticky}>
          <thead></thead>
          <tbody>
            <tr>
              <td
                style={{ paddingBottom: '0.75rem' }}
                className="sticky-cell"
                colSpan={MAX_STRUCTURES_COUNT}
              >
                <CheckboxWithTitle
                  id={`${name}-toggle-${isSelectedParamsTable}`}
                  value={isSelected}
                  withCustomTitle={true}
                  withCustomLink={false}
                  onChange={() => onToggle(name, category_title)}
                >
                  <PropertyName
                    name={name}
                    id={name}
                    className={'property-name molecule-descripted-key'}
                    categoryKey={t(getCategoryKey(name))}
                    color={
                      isSelected
                        ? theme.colors.text.primary
                        : theme.colors.text.secondary
                    }
                    tooltipPlace="top-start"
                  />
                </CheckboxWithTitle>
              </td>
              <EmptyCells name={name} isSelected={isSelectedParamsTable} />
            </tr>
            <tr>
              {calculation?.map(
                ({ data, sources, applicability: applicabilityData }, i) => (
                  <td key={`${name}-${data.baseID}-${isSelectedParamsTable}`}>
                    <Property
                      {...{
                        name,
                        data,
                        sources,
                        category_id,
                        applicabilityData,
                        blurNonApplicable,
                      }}
                      category_title={inner_category_title}
                      isApplicabilityShow={true}
                      showName={false}
                      id={`${data.baseID}-${isSelectedParamsTable}`}
                    />
                  </td>
                )
              )}
            </tr>
          </tbody>
        </StyledTable>
        {isNotLast && (
          <StyledTable isSticky={isSticky}>
            <tbody>
              <tr>
                <td>
                  <Divider width={`${MAX_STRUCTURES_COUNT * 100}%`} />
                </td>
              </tr>
            </tbody>
          </StyledTable>
        )}
      </>
    )
  }
)

PropertiesTable.displayName = 'PropertiesTable'
export default PropertiesTable
