import { useTranslation } from 'react-i18next'

import Icon from 'components/Icon'
import MoleculeViewer from 'components/MoleculeViewer'
import { Caption, Subheadline } from 'components/common/text/index.style'

import {
  Row,
  Card,
  CardFooter,
  SearchText,
  StyledButton,
  CardImageWrapper,
} from './index.style'
import { Column } from '../index.style'
import { getSearchTypeName } from './utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { openSearchHistory } from 'store/actions/searchHistory'
import CustomTag from 'components/common/customTag'
import { COMPOUNDS_TYPES_LIST, imageByTypeConfig } from './config'
import { useTheme } from 'styled-components'
import { setShouldBlocksIndexes } from 'store/actions/literature'

const HistoryCard = ({
  time,
  type,
  smiles,
  method,
  searchText,
  onRepeatSearch,
  compoundTypes,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const showHistory = useSelector((store) => store.searchHistory.showHistory)
  const shouldBlockIndexes = useSelector(
    (store) => store.literature.filter.shouldBlocksIndexes
  )
  const theme = useTheme()
  const isReactionType = type === 'reaction_search'
  const compoundTypesList =
    compoundTypes?.length === 2 ? COMPOUNDS_TYPES_LIST : compoundTypes

  return (
    <Card
      data-test="history-card"
      onClick={() => {
        shouldBlockIndexes?.length > 0 && dispatch(setShouldBlocksIndexes([]))
        showHistory && dispatch(openSearchHistory(false))
        onRepeatSearch(true)
      }}
    >
      <Column gap={isReactionType ? '0.25rem' : searchText ? '1rem' : '0.5rem'}>
        <Column gap="0.5rem">
          <Row>
            <CustomTag>
              <Caption color="inherit">{getSearchTypeName(type, t)}</Caption>
            </CustomTag>
            <CustomTag>
              <Caption>{time}</Caption>
            </CustomTag>
          </Row>
          {isReactionType && (
            <Row gap={'0.25rem'}>
              <Caption
                lineHeight={theme.text[16]}
                color={theme.colors.text.secondary}
                fontWeight={theme.fontWeight.medium}
              >{`${t('filter.reaction.substance_role')}:`}</Caption>
              {compoundTypes?.length > 0 &&
                compoundTypesList.map((ct) => (
                  <Caption
                    key={ct}
                    lineHeight={theme.text[16]}
                    fontWeight={theme.fontWeight.medium}
                  >
                    {t(`search.search_history.${ct}`)}
                  </Caption>
                ))}
            </Row>
          )}
        </Column>
        {searchText ? (
          <SearchText>{searchText}</SearchText>
        ) : (
          <MoleculeViewer
            smiles={smiles}
            handleClick={() => {}}
            onShowProps={() => {}}
            withText={false}
            noMenu
            noDelete
            noEdit
            fixedSize
            width={206}
            height={isReactionType ? 112 : 132}
            noHover
            noContainerHover={true}
            noContextMenu
            method={method}
            handleClickFromHistory={() => {
              showHistory && dispatch(openSearchHistory(false))
              onRepeatSearch(true)
            }}
          />
        )}
      </Column>
      {searchText && (
        <CardImageWrapper>
          <Icon
            iconType={imageByTypeConfig?.[type] ?? 'structureImage'}
            size="100%"
          />
        </CardImageWrapper>
      )}
      <CardFooter>
        <StyledButton
          type="text"
          onClick={(e) => {
            e.stopPropagation()
            showHistory && dispatch(openSearchHistory(false))
            onRepeatSearch()
          }}
        >
          <Icon iconType="refresh" size="1rem" />
          <Subheadline data-test="history-repeat-search-text">
            {t('search.search_history.repeat')}
          </Subheadline>
        </StyledButton>
      </CardFooter>
    </Card>
  )
}

export default HistoryCard
