import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import PagePreview from 'components/PagePreview'
import { setOpenFilter } from 'store/actions/filter'
import { settingsSidebarToggle } from 'store/actions/settings'
import { openSearchHistory } from 'store/actions/searchHistory'

export const HistoryPreview = memo(() => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const filterOpen = useSelector((store) => store.filter.open)
  const history = useSelector((store) => store.searchHistory.history)
  const isSidebarCollapsed = useSelector(
    (store) => store.settings.isSidebarCollapsed
  )

  return (
    <PagePreview
      title={
        history?.length
          ? t('search.search_history.history')
          : t('search.search_history.history_empty_title')
      }
      description={
        history?.length
          ? t('search.search_history.history_preview')
          : t('search.search_history.history_empty')
      }
      withButton={!!history?.length}
      buttonText={t('search.search_history.view')}
      onClick={() => {
        filterOpen && dispatch(setOpenFilter(false))
        isSidebarCollapsed && dispatch(settingsSidebarToggle())
        dispatch(openSearchHistory(true))
      }}
    />
  )
})

HistoryPreview.displayName = 'HistoryPreview'

export default HistoryPreview
