import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  CustomDialog,
  CustomDialogHeader,
} from 'components/common/customDialog'
import { storeV2 } from 'services/rest'
import DatasetLogs from 'components/DatasetLogs'
import { useDebounce } from 'hooks/useDebounce'
import CustomScrollbar from 'components/CustomScrollbar'
import SkeletonComponent from 'components/common/skeleton'
import { addNotification } from 'store/actions/notifications'

import { Container } from './index.style'
import LogsSortPanel from './LogsSortPanel'
import { COMPANY_DATASETS_LOGS_PREFIX, LOGS_SORT_CONFIG } from './config/config'

const CompanyDatasetsLogsDialog = ({ onClose }) => {
  const [existingDatasets, setExistingDatasets] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [sortType, setSortType] = useState('created')
  const [sortDirection, setSortDirection] = useState('desc')
  const [isLoading, setIsLoading] = useState(true)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleChangeSorting = (type, value) => {
    if (type === 'direction' && value === sortDirection) return
    if (type === 'direction') setSortDirection(value)
    else setSortType(value)
  }

  const debouncedSearchValue = useDebounce(searchValue, 300)

  const loadCompanyDatasetsLogs = async () => {
    const url = searchValue
      ? '/customer/customer_search_basket_logs'
      : '/customer/customer_baskets_log'

    try {
      const { data } = await storeV2(`${url}`, {
        params: {
          order_by: sortType,
          descending: sortDirection === 'desc',
          ...(searchValue ? { basket_name: searchValue } : {}),
        },
      })
      setExistingDatasets(searchValue ? data : data.result.existing_baskets)

      setIsLoading(false)
    } catch (e) {
      const id = uuidv4()
      const notify = {
        id,
        name: `${COMPANY_DATASETS_LOGS_PREFIX}failed_to_load_logs`,
        notification_type: 'error',
        autoRemove: true,
        timeout: 5000,
      }
      dispatch(addNotification(notify))
    }
  }

  useEffect(() => {
    loadCompanyDatasetsLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue, sortType, sortDirection])

  return (
    <CustomDialog
      width="42.75rem"
      padding="0rem 0rem 1.5rem 0rem"
      gap="0rem"
      onClose={onClose}
      style={{
        '.custom-close-icon': {
          top: '1rem',
          right: '1rem',
        },
      }}
    >
      <CustomDialogHeader padding="1.5rem 1.5rem 0.5rem 1.5rem">
        {t(`${COMPANY_DATASETS_LOGS_PREFIX}company_datasets_history`)}
      </CustomDialogHeader>

      <CustomScrollbar
        className="no-inset margin-12-right "
        style={{
          height: '37.125rem',
        }}
      >
        {isLoading ? (
          <Container>
            <SkeletonComponent
              width="100%"
              height="2.25rem"
              variant="grey"
              margin="0.5rem 0rem 0rem 0rem"
            />
            {[...new Array(4)].map((_, index) => (
              <SkeletonComponent
                width="100%"
                height="7.75rem"
                key={`company-datasets-skeleton-${index}`}
              />
            ))}
          </Container>
        ) : (
          <Container noGap>
            <LogsSortPanel
              switcherID="logsSwitcher"
              {...{
                sortType,
                searchValue,
              }}
              sortOptions={LOGS_SORT_CONFIG}
              onChangeSorting={handleChangeSorting}
              withWhiteBackground={false}
              onChangeSearchValue={setSearchValue}
            />
            <Container noPadding>
              {existingDatasets.map((el) => (
                <DatasetLogs
                  key={el.id}
                  noTags
                  datasetData={{ ...el, molecules_count: el.count }}
                  isCompanyDatasetsLogging
                  datasetId={el.id}
                  isCompanyLogsLoading={isLoading}
                  basketType="companies"
                />
              ))}
            </Container>
          </Container>
        )}
      </CustomScrollbar>
    </CustomDialog>
  )
}

export default CompanyDatasetsLogsDialog
