import { memo } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Icon from 'components/Icon'
import CustomButton from 'components/common/customButton'
import { Headline, Label } from 'components/common/text/index.style'
import { clearStructureSelectedParams } from 'store/actions/compareParams'

import Categories from '../Categories'
import { ParametersContainer } from '../index.style'
import { HeaderWithButton, Info } from './index.style'

const SelectedParameters = memo(({ isSticky, scrollLeft }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const selectedParams = useSelector(
    (store) => store.compareParams.structuresParams
  )

  return (
    <ParametersContainer>
      <HeaderWithButton>
        <Headline fontWeight={theme.fontWeight.semibold}>
          {t('structures_compare.selected_characteristics')}
        </Headline>
        {selectedParams?.length > 0 && (
          <CustomButton
            gap="0.25rem"
            type="text_delete"
            onClick={() => dispatch(clearStructureSelectedParams())}
          >
            <Icon iconType="deleted" size="1rem" gap="0.25rem" />
            {t('structures_compare.clear_the_list')}
          </CustomButton>
        )}
      </HeaderWithButton>
      {!selectedParams?.length ? (
        <Info>
          <Icon iconType="info" size="1.25rem" />
          <Label color={theme.colors.text.secondary}>
            {t('structures_compare.select_characteristics')}
          </Label>
        </Info>
      ) : (
        <Categories
          {...{ isSticky, scrollLeft }}
          isSelectedParamsTable={true}
        />
      )}
    </ParametersContainer>
  )
})

SelectedParameters.displayName = 'SelectedParameters'

export default SelectedParameters
