import { memo } from 'react'

import { MAX_STRUCTURES_COUNT } from '../config'

const EmptyCells = memo(({ name, ind, isSelected }) => {
  const emptyCells = Array(MAX_STRUCTURES_COUNT).fill(1)

  return emptyCells.map((_, i) => (
    <td key={`${name}-${ind}-${isSelected}-${i}`} className="sticky-cell" />
  ))
})

EmptyCells.displayName = 'EmptyCells'

export default EmptyCells
